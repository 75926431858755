MessageComponent = require 'components/chat/conversation_items/MessageComponent/MessageComponent'
LogMessageComponent = require 'components/chat/conversation_items/LogMessageComponent/LogMessageComponent'
ReminderComponent = require 'components/chat/conversation_items/ReminderComponent/ReminderComponent'
PromptsComponent = require 'components/chat/conversation_items/MessageComponent/PromptsComponent/PromptsComponent.cjsx'
ScheduledMessageComponent = require 'components/chat/conversation_items/ScheduledMessageComponent'
ImagePreviewActionCreator = require 'actions/ImagePreviewActionCreator'
OnlineStore = require 'stores/OnlineStore'
RankingStore = require 'stores/RankingStore'
RankingMessageStore = require 'stores/RankingMessageStore'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AuthStore = require 'stores/AuthStore'
{ ROLES } = require 'constants/Agents'
{ menus } = require 'root/config'
ConversationsActionCreator = new ConversationsActionCreator()

styles = require './conversation-items-list-view.styl'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'ranking'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

propTypes = require 'prop-types'

itemTypes =
  message: (item, rankingOptions, isSelected, onlineAgents) ->
    # ignoring 'queued' and 'duplicate' statuses
    if !item.channel?.service_name || !(item.channel?.service_name == 'Facebook' && (item.status in [1, 10]))
      React.createElement(MessageComponent, { \
        "onlineAgents": (onlineAgents),  \
        "key": (item.id),  \
        "ref": (item.id),  \
        "message": (item),  \
        "selected": (isSelected),  \
        "conversation": (@props.conversation),  \
        "rankingOptions": (rankingOptions)
      })
  log_message: (item) ->
    React.createElement(LogMessageComponent, {"logMessage": (item), "key": (item.id), "ref": (item.id)})
  reminder: (item, rankingOptions, isSelected, onlineAgents) ->
    React.createElement(ReminderComponent, { \
      "reminder": (item),  \
      "key": (item.id),  \
      "ref": (item.id),  \
      "selected": (isSelected),  \
      "rankingOptions": (rankingOptions)
    })
  prompt: (item) ->
    React.createElement(PromptsComponent, { \
      "key": (item.id),  \
      "prompts": ([item]),  \
      "conversation": (@props.conversation)
    })
  scheduled_message: (item, rankingOptions, isSelected) ->
    # ignoring 'queued' and 'duplicate' statuses
    if !item.channel?.service_name || !(item.channel?.service_name == 'Facebook' && (item.status in [1, 10]))
      React.createElement(ScheduledMessageComponent, { \
        "key": (item.id),  \
        "ref": (item.id),  \
        "message": (item),  \
        "selected": (isSelected),  \
        "rankingOptions": (rankingOptions)
      })

MAX_MESSAGES_INDEX = 299

class ConversationItemsListView extends React.Component
  dependsOnStores: [OnlineStore, RankingStore, AuthStore, RankingMessageStore]

  constructor: (props) ->
    super(props)
    @selectedItemId = null
    @itemsCount =
      convId: props.conversation.id
      lastMessageId: null
      count: 0
    @state =
      newMessagesCount: 0

  componentDidMount: =>
    @processOnItemSelectCallback() if !@alreadyBeenScrolled && @selectedItemId?
    setTimeout(
      =>
        ConversationsActionCreator.getFileUrls @props?.conversation.id
      10
    )

  componentDidUpdate: (prevProps) ->
    if @itemsCount.convId != @props.conversation.id
      setTimeout(
        -> ImagePreviewActionCreator.imageCheck({})
        0
      )

    hasNewMessagesForNotFullList = @props.items.length < 300 && @itemsCount.count != @props.items.length
    hasNewMessagesForFullList = @props.items.length == 300 && @props.items[@props.items.length - 1].id != @itemsCount.lastMessageId
    if @props.onUpdateConversation && (hasNewMessagesForNotFullList || hasNewMessagesForFullList)
      if !@props.alreadyScrolled()
        @props.onSwitchDisallowScroll(false)
        timeoutNumber = 500
        timeoutNumber = 800 if @props.items[0]?.channel?.service_name == 'Facebook'

        { onUpdateConversation } = @props
        setTimeout onUpdateConversation, timeoutNumber
        @itemsCount =
          count: @props.items.length
          lastMessageId: @props.items[@props.items.length - 1]?.id
          convId: @props.conversation.id

        @setState newMessagesCount: 0 if @state.newMessagesCount > 0 || @itemsCount.convId != @props.conversation.id
      else
        @props.onSwitchDisallowScroll(true)
        lastMsgIndex = null
        if hasNewMessagesForFullList
          lastMsgIndex = @props.items.findIndex((item) => item.id == @itemsCount.lastMessageId)
        newMessagesCount = 0
        if hasNewMessagesForFullList
          newMessagesCount = @props.items.length - lastMsgIndex - 1 # last index = length - 1
        else
          newMessagesCount = @props.items.length - @itemsCount.count
        @setState newMessagesCount: newMessagesCount if @state.newMessagesCount != newMessagesCount && @itemsCount.convId == @props.conversation.id
        @setState newMessagesCount: 0 if @state.newMessagesCount > 0 && @itemsCount.convId != @props.conversation.id

    if !@alreadyBeenScrolled && @selectedItemId?
      @processOnItemSelectCallback()

  processOnItemSelectCallback: ->
    el = ReactDOM.findDOMNode @refs[@selectedItemId]
    @alreadyBeenScrolled = @selectedItemId
    @selectedItemId = null
    @props.onItemSelect? el

  onScrollToNewMessages: =>
    @props.onUpdateConversation() if @props.onUpdateConversation
    @setState newMessagesCount: 0
    @itemsCount =
      count: @props.items.length
      lastMessageId: @props.items[@props.items.length - 1]?.id
      convId: @props.conversation.id

  renderScrollToNewMessage: =>
    return null if !@state.newMessagesCount
    React.createElement("div", {"className": (styles.scroll_to_bottom)},
      React.createElement("span", null,
        ("You have #{if @state.newMessagesCount > MAX_MESSAGES_INDEX then '300+' else @state.newMessagesCount} new messages. ")
      ),
      React.createElement("a", {"onClick": (@onScrollToNewMessages)}, "View")
    )

  render: ->
    onlineAgents = OnlineStore.agents
    anySelected = false
    @selectedItemId = null
    result = React.createElement("div", null,
      React.createElement("div", null,
        (@props.items.map (item, index) =>
          if @props.selectedItem?
            objectId = @props.selectedItem.type + @props.selectedItem.id
            isSelected = objectId == item.id || objectId == item.item_type + item.id
            anySelected = true if isSelected
            @alreadyBeenScrolled = null if isSelected && @alreadyBeenScrolled != item.id
            @selectedItemId = item.id if isSelected

          rankingOptions = {}
          if RankingMessageStore.conversation && item.id
            rankingOptions.isMessagesRanking = true
            rankingOptions.isClickableForRank = !!RankingMessageStore.messages[item.id]
            rankingOptions.tagged = !!RankingMessageStore.messagesTagged[item.id]
            if rankingOptions.tagged
              rankingOptions.tagName = RankingMessageStore.messages[item.id].tag_name
              rankingOptions.tagGroup = RankingMessageStore.messages[item.id].tag_group
            rankingOptions.skipped = !!RankingMessageStore.messagesSkipped[item.id]
            rankingOptions.isRankSelectedMessage =
              RankingMessageStore.currentMessageId?.toString() == item.id.toString()
            
          else if RankingStore.estimation
            rankingMessageType = RankingStore.estimation.messageType || 'message'
            rankingMessageId = RankingStore.estimation.messageId || ''
            rankingConversationId = (RankingStore.estimation.conversation || {}).id || ''
            isRankSelectedMessage =
              rankingMessageId.toString() == item.id?.toString() && rankingMessageType == item.item_type
            isClickableForRank = false
            if item.conversation_id &&
               rankingConversationId.toString() == item.conversation_id.toString() &&
               ROLES[AuthStore.user.role].value in accessConfig.toEdit &&
               !item.inbound &&
               RankingStore.isSelectable
              isClickableForRank = true
            rankedBy = undefined
            if isRankSelectedMessage
              rankedBy = (RankingStore.estimation.user || {}).name

            rankingOptions.isClickableForRank = isClickableForRank
            rankingOptions.rankedBy = rankedBy
            rankingOptions.isRankSelectedMessage = isRankSelectedMessage

          itemTypes[item.item_type || item.type].call @, item, rankingOptions, isSelected, onlineAgents
        )
      )
    )
    @alreadyBeenScrolled = null if @alreadyBeenScrolled && !anySelected
    result

ConversationItemsListView.propTypes =
  items: propTypes.arrayOf(propTypes.object).isRequired
  selectedItem: propTypes.shape(
    id: propTypes.number.isRequired
  )
  onItemSelect: propTypes.func
  conversationId: propTypes.number

module.exports = ConversationItemsListView
