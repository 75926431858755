{ Link } = require 'react-router-dom'
classnames = require 'classnames'

ConversationHeaderComponent = require '../../conversations/ConversationHeaderComponent/ConversationHeaderComponent'
conversationStyles = require '../../conversations/ConversationComponent/conversation'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
styles = require '../../conversations/ConversationHeaderComponent/conversation-header'
CONVERSATION_STATUS = require '../../../constants/ConversationStatuses'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
constants = require '../../../constants/base'

propTypes = require 'prop-types'

class RankingHeaderComponent extends ConversationHeaderComponent

  @propTypes:
    onMessagesSearch: propTypes.func
    toggleCustomerInfoHandler: propTypes.func.isRequired
    searchEnabled: propTypes.bool
    searchLoading: propTypes.bool
    foundMessages: propTypes.array
    searchStepCallback: propTypes.func
    searchPosition: propTypes.number

  render: =>
    React.createElement("div", {"className": conversationStyles.header},
      React.createElement(SearchInputComponent, { \
        "onChange": @props.onMessagesSearch,  \
        "onClear": @props.onMessagesSearch,  \
        "placeholder": 'Search messages by key words',  \
        "className": styles.search_messages,  \
        "inputClassName": styles.search_input,  \
        "clearCross": true,  \
        "autofocus": true
      },
        (
          if @props.searchEnabled
            length = @props.foundMessages.length
            React.createElement("div", {"className": styles.messages_search_result_panel},
              React.createElement(LoadableWrapperComponent, { \
                "containerClassName": styles.search_loader_wrapper,  \
                "loading": @props.searchLoading,  \
                "loader": (className: styles.search_loader)
              },
                (
                  if length > 0
                    React.createElement("div", {"className": styles.navigation},
                      React.createElement("div", { \
                        "className": styles.prev,  \
                        "onClick": ( R.partial(@props.searchStepCallback, [-1]) )
                      }
                      ),
                      React.createElement("span", {"className": styles.position},
                        ( "#{@props.searchPosition}/#{length}" )
                      ),
                      React.createElement("div", { \
                        "className": styles.next,  \
                        "onClick": ( R.partial(@props.searchStepCallback, [1]) )
                      })
                    )
                )
              )
            )
        )
      )
    )
module.exports = RankingHeaderComponent
