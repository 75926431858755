BaseComponent = require 'components/BaseComponent'
NotificationItem = require './NotificationItem'
AgentsActionCreator = require 'actions/AgentsActionCreator'
OnlineStore = require 'stores/OnlineStore'

NOTIFICATION_HEIGHT = 70
MARGIN_BETWEEN = 5

class TeamleadNotifications extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      showCount: 1
    @initComponent()

  dependsOnStores: [OnlineStore]
  
  componentWillReceiveProps: (nextProps) =>
    windowHeight = window.innerHeight
    @setState
      showCount: Math.floor(windowHeight / (NOTIFICATION_HEIGHT + MARGIN_BETWEEN))

  onCloseNotification: (id) =>
    AgentsActionCreator.closeNotification id

  render: =>
    notifications = OnlineStore.dormant_list.slice(0, @state.showCount)
    if notifications.length > 0
      (
        React.createElement("div", null,
          (notifications.map (notification, i) =>
            React.createElement(NotificationItem, { \
              "key": (notification.id),  \
              "position": (i),  \
              "height": (NOTIFICATION_HEIGHT),  \
              "marginBetween": (MARGIN_BETWEEN),  \
              "notification": (notification),  \
              "onClose": (@onCloseNotification)
            })
          )
        )
      )
    else
      null

module.exports = TeamleadNotifications
