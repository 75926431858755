classnames = require 'classnames'
{ Link } = require 'react-router-dom'
DateTimePicker = require 'react-widgets/lib/DateTimePicker'

confirm = require 'components/shared/ConfirmDialog'
styles = require './reminders'
AuthStore = require 'stores/AuthStore'
modalStyles = require 'components/shared/styles/modal'
BaseComponent = require 'components/BaseComponent'
RemindersStore = require './RemindersStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ActiveRemindersComponent = require '../ActiveRemindersComponent/ActiveRemindersComponent'

class RemindersComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> RemindersStore

  requestData: ->
    _.defer @actionCreator.index,
      conversationId: @getConversationId()
      future: true

  getConversationId: => @props.match.params.id

  backUrl: =>
    "/conversations/#{@props.match.params.folder}/#{@props.match.params.id}"

  getState: ->
    reminders: @personalStore.reminders || []
    loading: @personalStore.loading
    newReminder: if @personalStore.errors.length > 0 then @state?.newReminder || @getBlankReminder() else @getBlankReminder()
    errors: @personalStore.errors

  getBlankReminder: ->
    text: ''
    time: @getInitialTime()
    conversationId: @getConversationId()
    userId: AuthStore.user.id

  getInitialTime: ->
    time = new Date()
    time.setSeconds(0)
    time

  onSaveReminder: =>
    if @state.newReminder.id
      @actionCreator.update(@state.newReminder.id, @state.newReminder)
    else
      @actionCreator.create(@state.newReminder)

  onSetDate: (time) =>
    return null if !time
    @state.newReminder.time.setFullYear(time.getFullYear())
    @state.newReminder.time.setDate(time.getDate())
    @state.newReminder.time.setMonth(time.getMonth())
    @forceUpdate()

  onSetTime: (time) =>
    return null if !time
    console.log 'time: ', time
    @state.newReminder.time.setHours(time.getHours())
    @state.newReminder.time.setMinutes(time.getMinutes())
    @forceUpdate()

  onPresetClick: (presetedHours) ->
    presetedTime = @getInitialTime()
    presetedTime.setMinutes(presetedTime.getMinutes() + (presetedHours * 60))
    newReminder = @state.newReminder
    newReminder.time = presetedTime
    @setState {newReminder}

  onTextChange: (e) =>
    newReminder = @state.newReminder
    newReminder.text = e.target.value
    @setState {newReminder}

  onEditReminder: (reminder) =>
    @setState
      newReminder:
        id: reminder.id
        time: new Date(reminder.time)
        conversationId: reminder.conversation_id
        userId: reminder.user_id
        text: reminder.text

  destroyReminder: (id) =>
    confirm(
      'Are you sure this reminder should be canceled?'
      {
        confirmTitle: 'Cancel reminder'
      }
    ).then(
      => @actionCreator.destroy id
      ->
    )
  render: ->
    React.createElement("div", null,
      React.createElement(Link, {"className": (modalStyles.cross), "to": (@backUrl())}),
      React.createElement("div", {"className": (modalStyles.title)}, """
        Add reminder
"""),
      React.createElement("div", {"className": (modalStyles.content)},
        React.createElement("div", {"className": (styles.new_reminder)},
          React.createElement("textarea", { \
            "className": (styles.textarea),  \
            "placeholder": "Enter text here",  \
            "onChange": (@onTextChange),  \
            "value": (@state.newReminder.text)
          }),
          React.createElement("div", {"className": (styles.datepicker_container)},
            React.createElement(DateTimePicker, { \
              "containerClassName": (styles.datepicker),  \
              "placeholder": "Set date",  \
              "time": (false),  \
              "onChange": (@onSetDate),  \
              "value": (@state.newReminder.time)
            })
          ),
          React.createElement("div", {"className": (styles.timepicker_container)},
            React.createElement(DateTimePicker, { \
              "containerClassName": (styles.datepicker),  \
              "placeholder": "Set time",  \
              "date": (false),  \
              "onChange": (@onSetTime),  \
              "value": (@state.newReminder.time)
            })
          ),
          React.createElement("div", {"className": (styles.preset_options)},
           React.createElement("button", { \
              "className": (styles.preset_option),  \
              "onClick": (@onPresetClick.bind @, 0.5)
            }, """
              30m
"""),
            React.createElement("button", { \
              "className": (styles.preset_option),  \
              "onClick": (@onPresetClick.bind @, 2)
            }, """
              2h
"""),
            React.createElement("button", { \
              "className": (styles.preset_option),  \
              "onClick": (@onPresetClick.bind @, 4)
            }, """
              4h
"""),
            React.createElement("button", { \
              "className": (styles.preset_option),  \
              "onClick": (@onPresetClick.bind @, 12)
            }, """
              12h
"""),
            React.createElement("button", { \
              "className": (styles.preset_option),  \
              "onClick": (@onPresetClick.bind @, 24)
            }, """
              24h
""")
          ),
          React.createElement("div", {"className": (styles.add_reminder_wrapper)},
            React.createElement("div", {"className": (styles.add_reminder), "onClick": (@onSaveReminder)}, """
              Save reminder
""")
          )
        ),

        React.createElement("div", null,
          ( for error, i in @state.errors
            do (error, i) ->
              React.createElement("div", {"key": (i), "className": (styles.error)},
                (error)
              )
          )
        ),
        React.createElement(LoadableWrapperComponent, { \
          "containerClassName": (styles.container),  \
          "loader": ( className: styles.loader_container ),  \
          "loading": (@state.loading.reminders)},
          React.createElement(ActiveRemindersComponent, { \
            "activeId": (@state.newReminder?.id),  \
            "reminders": (@state.reminders),  \
            "destroyReminder": (@destroyReminder),  \
            "onEditReminder": (@onEditReminder)
          })
        ),
        React.createElement("div", {"className": (styles.actions)},
          React.createElement(Link, {"className": (styles.save), "to": (@backUrl())}, " Save ")
        )
      )
    )

module.exports = RemindersComponent
