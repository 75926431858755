{ Link } = require 'react-router-dom'
browserHistory = require './../../../history'
config = require 'root/config'
classnames = require 'classnames'

constants = require 'constants/base'
AuthStore = require 'stores/AuthStore'
styles = require './conversation-header'
conversationStyles = require '../ConversationComponent/conversation'
BaseComponent = require '../../BaseComponent.cjsx'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
SearchInputComponent = require '../../shared/SearchInputComponent/SearchInputComponent'
IconButtonWithTooltipComponent = require '../../shared/IconButtonWithTooltipComponent/IconButtonWithTooltipComponent'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
TagsWarningComponent = require '../../tags/TagsWarningComponent'
TagsWarningDashboardComponent = require '../../tags/TagsWarningDashboardComponent'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
ConversationItemsStore = require 'stores/ConversationItemsStore'
{ ASSIGN_BUTTON_ACCESS, TAG_CHECKING_EXCEPT } = require 'constants/Agents'
{ TEXT_FIND_1, TEXT_FIND_2, TAG_DASHBOARD, TAGS_FOR_DASHBOARD } = require 'constants/conversations'
ScheduledMessagesManagerComponent = require 'components/ScheduledMessagesManagerComponent'
Select = require '../../shared/Select'

propTypes = require 'prop-types'

CONVERSATION_STATUS_ACTION =
  SHOW_MODAL_TAG_ACTION: 'SHOW_MODAL_TAG_ACTION'
  SHOW_MODAL_TAG_DASHBOARD_ACTION: 'SHOW_MODAL_TAG_DASHBOARD_ACTION'
  UPDATE_STATUS: 'UPDATE_STATUS'

class ConversationHeaderComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    folder: propTypes.string.isRequired
    updateConversationStatus: propTypes.func.isRequired
    conversation: propTypes.object.isRequired
    errors: propTypes.shape
      takeNew: propTypes.string
    onMessagesSearch: propTypes.func
    toggleAssignerHandler: propTypes.func
    toggleCustomerInfoHandler: propTypes.func.isRequired

  statuses: CONVERSATION_STATUS._ALL

  dependsOnStores: [AuthStore, CustomerOrdersStore]

  statusToLabel:
    "#{CONVERSATION_STATUS.NEW_MESSAGE}": 'New'
    "#{CONVERSATION_STATUS.OPENED}": 'Opened'
    "#{CONVERSATION_STATUS.CLOSED}": 'Closed'
    "#{CONVERSATION_STATUS.DORMANT}": 'Dormant'

  searchTextInMessage: (message) =>
    text = message?.text.toLowerCase()
    message?.type == 'message' && (text.indexOf(TEXT_FIND_1) > -1 || text.indexOf(TEXT_FIND_2) > -1)

  getActionOnChangeStatus: (status) =>
    action = {}
    showDashboardModal = false
    showModal = false
    hasKeyWords = false
    isLead = @props.conversation?.customer?.lead
    hasTag = @props.conversation.tags?.some (tag) -> tag.name == TAG_DASHBOARD
    hasTags = @props.conversation.tags?.length > 0
    action.closeModal = hasTags && !hasTag
    # isLead = true
    # console.log('@props.conversation.tags: ', @props.conversation.tags)
    # console.log('hasTags: ', hasTags)
    # console.log('hasTag: ', hasTag)
    # console.log('isLead: ', isLead)

    if @state.status != status
      action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS
      if status == CONVERSATION_STATUS.CLOSED
        if !hasTags
          action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_ACTION

        if isLead
          conversationItems = ConversationItemsStore.conversationItemsSync
          hasKeyWords = conversationItems.some @searchTextInMessage

          if hasKeyWords
            if hasTag
              action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

            if (hasTags && !hasTag) || !hasTags
              action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_DASHBOARD_ACTION
      else
        action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

    return action

  onSetTagWarning: (option) =>
    option =
      label: "Closed",
      value: "closed"
    InterfaceActionCreator.closeModal
    return setTimeout(
      => @onStatusChange option, null, true
      10
    )

  onStatusChange: (option, action, noDashboard) =>
    status = option.value
    hasOrder = CustomerOrdersStore?.orders.length > 0

    if !config.dashboardTags
      @props.updateConversationStatus(status)
    else
      action = @getActionOnChangeStatus(status)
      switch action.name
        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_DASHBOARD_ACTION
          if noDashboard && !TAG_CHECKING_EXCEPT[AuthStore.user.role]
            InterfaceActionCreator.openModal TagsWarningComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
            }
          if !noDashboard
            InterfaceActionCreator.openModal TagsWarningDashboardComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
              closeModal: action.closeModal
              hasOrder: hasOrder
              onClick: @onSetTagWarning
            }
        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_ACTION
          if !TAG_CHECKING_EXCEPT[AuthStore.user.role]
            InterfaceActionCreator.openModal TagsWarningComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
            }
        when CONVERSATION_STATUS_ACTION.UPDATE_STATUS
          @props.updateConversationStatus(status)

  remindersRoute: =>
    "/conversations/#{@props.folder}/#{@props.conversation?.id}/reminders"

  gotoReminder: =>
    browserHistory.default.push "/conversations/#{@props.folder}/#{@props.conversation?.id}/reminders"

  openScheduledMessages: =>
    browserHistory.default.push "/conversations/#{@props.folder}/#{@props.conversation?.id}/schedulers/#{@props.conversation?.customer?.id}"

  render: =>
    statusOptions = @statuses.map (s) =>
      label: @statusToLabel[s] || s
      value: s
    statusValue =
      label: @statusToLabel[@props.conversation.status] || @props.conversation.status
      value: @props.conversation.status

    (React.createElement("div", {"className": conversationStyles.header},
      React.createElement(SearchInputComponent, { \
        "onChange": (@props.onMessagesSearch),  \
        "onClear": (@props.onMessagesSearch),  \
        "placeholder": 'Search messages by key words',  \
        "className": (styles.search_messages),  \
        "inputClassName": (styles.search_input),  \
        "clearCross": (true),  \
        "autofocus": true
      },
        (
          if @props.searchEnabled
            length = @props.foundMessages.length
            React.createElement("div", {"className": (styles.messages_search_result_panel)},
              React.createElement(LoadableWrapperComponent, { \
                "containerClassName": (styles.search_loader_wrapper),  \
                "loading": (@props.searchLoading),  \
                "loader": (className: styles.search_loader)
              },
                (
                  if length > 0
                    React.createElement("div", {"className": (styles.navigation)},
                      React.createElement("div", { \
                        "className": (styles.prev),  \
                        "onClick": ( R.partial(@props.searchStepCallback, [-1]) )
                      }
                      ),
                      React.createElement("span", {"className": (styles.position)},
                        ( "#{@props.searchPosition}/#{length}" )
                      ),
                      React.createElement("div", { \
                        "className": (styles.next),  \
                        "onClick": ( R.partial(@props.searchStepCallback, [1]) )
                      })
                    )
                )
              )
            )
        )
      ),

      React.createElement(Select.default, { \
        "value": (statusValue),  \
        "options": (statusOptions),  \
        "onChange": (@onStatusChange),  \
        "className": (styles.select_state),  \
        "placeholder": 'Set state',  \
        "classNamePrefix": "ReactSelect"
      }),
      React.createElement(IconButtonWithTooltipComponent, { \
        "tooltip": 'Add reminder',  \
        "callback": (@gotoReminder),  \
        "className": (styles.reminder)
      },
        (if @props.conversation.customer?.active_reminders_count > 0
          React.createElement("div", {"className": (styles.button_badge)},
            (@props.conversation.customer?.active_reminders_count)
          )
        )
      ),
      React.createElement(IconButtonWithTooltipComponent, { \
        "tooltip": 'Schedule message',  \
        "callback": (@openScheduledMessages),  \
        "className": (styles.scheduled_message_button)
      },
        (if @props.conversation.customer?.not_completed_scheduled_messages?.length > 0
          React.createElement("div", {"className": (styles.button_badge)},
            (@props.conversation.customer?.not_completed_scheduled_messages.length)
          )
        )
      ),

      (if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
        React.createElement(IconButtonWithTooltipComponent, { \
          "tooltip": 'Assign conversation',  \
          "callback": (@props.toggleAssignerHandler),  \
          "className": (styles.assigner_button)
        })
      )
    ))

module.exports = ConversationHeaderComponent
