classnames = require 'classnames'

styles = require './take-new-button.styl'

takeNewButtonComponent = (props) ->
  { unassignedCount, isBusy } = props
  additionalClass = null
  if unassignedCount == 0 || isBusy 
    additionalClass = styles.button_disabled
  else if props.isGreen
    additionalClass = styles.button_green
  else if props.mustHave
    additionalClass = styles.button_strong
  else
    additionalClass = styles.button_active

  React.createElement("div", {"className": (styles.container)},
    React.createElement("button", { \
      "onClick": (props.onClick),  \
      "className": (classnames(styles.button, additionalClass))
    }, """
      Take New """, ("(#{unassignedCount})" if unassignedCount > 0 && !isBusy)
    )
  )

module.exports = takeNewButtonComponent
