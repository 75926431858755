module.exports =
  STATUSES:
    unranked:
      label:'Unranked'
      value: 'unranked'
    ranked:
      label: 'Ranked'
      value: 'ranked'
    nonrankable:
      label: 'N/rankable'
      value: 'nonrankable'
  QUALITY:
    good:
      label: 'Good'
      value: 'good'
    bad:
      label: 'Bad'
      value: 'bad'
  BAD_TAGS:
    caring:
      label: 'Caring'
      value: 'caring'
    accessible:
      label: 'Accessible'
      value: 'accessible'
    simple:
      label: 'Simple'
      value: 'simple'
    quick:
      label: 'Quick'
      value: 'quick'
    trustworthy:
      label: 'Trustworthy'
      value: 'trustworthy'
  SALES_BAD_TAGS:
    caring:
      label: 'Caring'
      value: 'caring'
    accessible:
      label: 'Accessible'
      value: 'accessible'
    simple:
      label: 'Simple'
      value: 'simple'
    quick:
      label: 'Quick'
      value: 'quick'
    trustworthy:
      label: 'Trustworthy'
      value: 'trustworthy'
    sales:
      label: 'Sales'
      value: 'sales'

  CONVERSATIONS_ORDERING:
    messages_count:
      label: '# messages'
      value: 'messages_count'
    average_response_time:
      label: 'Average RT'
      value: 'average_response_time'

  TO_BE_RANKED_PERCENT: 20
  CONVERSATIONS_PER_PAGE: 20
