config = require '../config'

ChannelsAPI =
  getList: (data, success, error) ->
    $.ajax {
      url: config.urls.channels.getList
      type: 'GET'
      data
      success
      error
      timeout: 5000
    }

  get: (id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.channels.get(id)
      type: 'GET'
      success: successCallback
      error: errorCallback
      timeout: 5000

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.channels.create
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: ({ id, attributes }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.channels.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  batchDestroy: ({ ids, menu }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.channels.batch_destroy
      contentType: 'application/json'
      data: JSON.stringify({ ids: ids })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

  getPagesCount: ({ per_page, query }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.channels.pages_count
      type: 'GET'
      data: { per_page, query }
      success: successCallback
      error: errorCallback
      timeout: 5000

  getChannelsForGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.channels.channelsForGroup
      type: 'GET'
      data
      success
      error
    }

  getForCustomer: (customerId, success, error) ->
    $.ajax {
      url: config.urls.channels.channelsForCustomer customerId
      type: 'GET'
      success
      error
    }

module.exports = ChannelsAPI
