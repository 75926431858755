_ = require 'underscore'
uuid = require 'uuid'
browserHistory = require './../../../history'

BaseComponent = require 'components/BaseComponent'
SettingsActionCreator = require 'actions/SettingsActionCreator'
SettingsStore = require 'stores/SettingsStore'
Button = require 'components/shared/Button'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AuthStore = require 'stores/AuthStore'
styles = require './settings.styl'
{ menus } = require 'root/config'
AuthStore = require 'stores/AuthStore'
{ ROLES } = require 'constants/Agents'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'settings'
    accessConfig =
      toPage: item.accessToPage || null

class SettingsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      settings: {}
      loading: false
      errors: {}
    @initComponent()

  componentWillMount: ->
    if accessConfig.toPage
      unless ROLES[AuthStore.user.role].value in accessConfig.toPage
        browserHistory.default.push '/'

  dependsOnStores: [SettingsStore]

  getState: ->
    settings: SettingsStore.settings
    loading: SettingsStore.loading
    errors: SettingsStore.errors

  requestData: =>
    setTimeout(
      -> SettingsActionCreator.get()
      0
    )

  onSave: => SettingsActionCreator.update(settings: @state.settings)

  onChange: ({ target: { value } }, settingType) =>
    { settings } = @state
    settings[settingType] = settings[settingType] || {}
    settings[settingType].value = value
    @setState { settings }

  render: ->
    { onChange, onChangeIpEnable, onChangeIp, deleteIp } = @
    { settings, errors, loading } = @state
    ip_addresses = settings.ip_addresses?.value || {}
    ip_enable = !!settings.ip_enable?.value

    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.title)}, """
        Settings
"""),
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        (_.keys(settings)
          .filter (settingType) =>
            settingType != 'ip_addresses' && settingType != 'ip_enable'
          .map (settingType, i) =>
            React.createElement("div", {"key": (i), "className": (styles.input_container)},
              React.createElement("div", null, (settingType.split('_').join(' '))),
              React.createElement("input", { \
                "type": 'text',  \
                "name": (settingType),  \
                "value": (settings[settingType].value),  \
                "onChange": ((e) -> onChange(e, settingType)),  \
                "className": (styles.input),  \
                "placeholder": (settingType),  \
                "disabled": (
                  settings[settingType].access &&
                  !settings[settingType].access[AuthStore.user.role]
                )
              }),
              React.createElement("div", {"className": (styles.error)},
                (errors[settingType])
              )
            )
        ),
        React.createElement("div", {"key": ('c')},
          React.createElement(Button, { \
            "title": 'Save settings',  \
            "onClick": (@onSave)
          })
        )
      )
    )

module.exports = SettingsComponent
