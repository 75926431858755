BaseComponent = require 'components/BaseComponent'
styles  = require './extra-info.styl'
classnames = require 'classnames'
AuthStore = require 'stores/AuthStore'
ProfileStore = require 'stores/ProfileStore'
AuthActionCreator  = require 'actions/AuthActionCreator'
ProfileActionCreator  = require 'actions/ProfileActionCreator'
ShiftsActionCreator  = require 'actions/ShiftsActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
{ getToken } = require 'lib/tokenStorage'
{ urls, oauthEnabled } = require '../../../config'

START_SHIFT =
  'teamlead': 'Start shift'
  'second_teamlead': 'Start second shift'

class ExtraInfoComponent extends BaseComponent
  constructor: (props) ->
    super props
    @dropdownIdentifier = 'extra-info'
    @_isunmounted = false
    @initComponent()
    @state = 
      dropdown: false
  
  dependsOnStores: [AuthStore, ProfileStore]
  
  onMenuClick: =>
    return if @state.dropdown
    @setState dropdown: true
    document.addEventListener 'click', @onBlur

  onBlur: =>
    document.removeEventListener 'click', @onBlur
    @setState(dropdown: false) unless @_isunmounted

  componentDidMount: ->
    if AuthStore.user.role == 'manager'
      setTimeout(
        ProfileActionCreator.get
        0
      )
    super()

  componentWillUnmount: ->
    super()
    @_isunmounted = true
    document.removeEventListener 'click', @onBlur

  logout: ->
      AuthActionCreator.logout()
  
  renderAppList: (apps) ->
    hostname = window.location.hostname
    token = getToken()

    return (
      React.createElement("ul", null,
        (apps.map (app, i) ->
          login_url_token = "#{app.front_app_uri}/login/#{token.split(' ')[1]}"
          login_url = if app.name == 'a4' then login_url_token else app.front_app_uri

          if app.front_app_uri.indexOf(hostname) < 0
            React.createElement("li", {"key": (i)},
              React.createElement("a", {"href": (login_url), "target": '_blank', "className": (styles.link)}, (app.name))
            )
        )
      )
    )

  render: ->
    user = AuthStore.user
    return null unless user
    userImage = user.image || require 'components/shared/images/unknown.png'

    profile = ProfileStore.profile || {}
    apps = profile && profile.available_apps

    React.createElement("div", {"id": (@dropdownIdentifier), "className": (styles.container), "onClick": (@onMenuClick)},
      React.createElement("img", {"className": (styles.circle), "src": (userImage)}),
      (if @state.dropdown
        React.createElement("div", {"className": (styles.dropdown_menu)},
          React.createElement(LoadableWrapperComponent, {"loading": (ProfileStore.loading)},
            (if user.available_role
              React.createElement("div", { \
                "className": (styles.dropdown_element),  \
                "onClick": (ShiftsActionCreator.createTeamleadShift)
              },
                React.createElement("div", {"className": (classnames(
                  styles.link,
                  "#{styles.link_center}": !apps
                ))},
                  (START_SHIFT[user.available_role])
                )
              )
            ),
            React.createElement("div", {"className": (styles.dropdown_element)},
              (if apps
                @renderAppList apps
              ),
              React.createElement("a", {"onClick": (@logout),   \
                "className": (classnames(
                styles.link,
                "#{styles.link_center}": !apps
                ))
              }, "Logout")
            )
          )
        )
      )
    )

module.exports = ExtraInfoComponent
