classnames = require 'classnames'

styles = require './agent-edit-popup'


class AgentEditPopup extends React.Component

  constructor: (props) ->
    super()
    errors = {}
    Object.keys props.fields
      .forEach (key) ->
        errors[key] = props.fields[key].validator props.fields[key].value || ''
    @state =
      errors: errors

  onInputHandler: (e) =>
    key = e.target.getAttribute 'data-value'
    error = @props.fields[key].validator e.target.value
    newErrorState = Object.assign @state.errors, "#{key}": error
    @setState errors: newErrorState

  isSaveDisabled: =>
    state = @state
    errors = Object.keys @state.errors
      .filter (key) ->
        state.errors[key]
    !!errors.length

  render: ->
    fields = @props.fields
    that = @
    style =
      left: @props.editedElement.clientWidth
      top: (@props.editedElement.offsetTop - @props.editedElement.clientHeight / 2)
    React.createElement("div", {"style": (style), "className": (styles.popup)},
      React.createElement("div", {"className": (styles.popup__content)},
        (Object.keys fields
          .map (key, i) ->
            React.createElement("div", {"key": (i), "className": (styles.popup__field)},
              React.createElement("input", { \
                "ref": (key),  \
                "type": 'text',  \
                "placeholder": (fields[key].placeholder),  \
                "defaultValue": (fields[key].value),  \
                "onInput": (that.onInputHandler),  \
                "data-value": (key),  \
                "className": (classnames styles.popup__input,
                  "#{styles.popup__input_error}": that.state.errors[key]
                )
              }),
              React.createElement("span", {"className": (styles.popup__error)},
                (that.state.errors[key])
              )
            )
        ),
        React.createElement("div", {"className": (styles.popup__buttons)},
          React.createElement("button", { \
            "className": (styles.popup__button), "onClick": (@props.closePopup)
          }, """
            Cancel
"""),
          React.createElement("button", { \
            "className": (classnames styles.popup__button, styles.popup__button_save,
              "#{styles.popup__button_disabled}": @isSaveDisabled()
            ),  \
            "onClick": (@props.saveData unless @isSaveDisabled())
          }, """
            Save
""")
        )
      ),
      React.createElement("div", {"className": (styles.popup_arrow)})
    )

module.exports = AgentEditPopup
