Isvg = require 'react-inlinesvg'
moment = require 'moment'
browserHistory = require './../../history'
DateTimePicker = require 'react-widgets/lib/DateTimePicker'

BaseComponent = require 'components/BaseComponent'
Button = require 'components/shared/Button'
ScheduledMessageItemComponent = require './ScheduledMessageItemComponent'
ScheduledMessagesActionCreator = require 'actions/ScheduledMessagesActionCreator'
ScheduledMessagesStore = require 'stores/ScheduledMessagesStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
AuthStore = require 'stores/AuthStore'

styles = require './scheduled-messages-manager.styl'

class ScheduledMessagesManagerComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      message: ''
      date: new Date
      scheduleEdit: false
    }
    @initComponent()

  dependsOnStores: [ScheduledMessagesStore]

  componentDidMount: ->
    super()
    setTimeout(
      =>
        ScheduledMessagesActionCreator.getUncompletedMessagesByCustomerId(
          @props.match.params.customerId
        )
      10
    )

  onMessageChange: (e) => @setState message: e.target.value

  onScheduleEdit: => @setState scheduleEdit: true

  onScheduleEditCancel: => @setState scheduleEdit: false

  deleteSchedule: (message) =>
    @setState scheduleEdit: false
    ScheduledMessagesActionCreator.destroy message.id

  editSchedule: (messageId, message) =>
    @setState scheduleEdit: false
    message.userId = AuthStore.user.id
    ScheduledMessagesActionCreator.update messageId, message

  createMessage: =>
    ScheduledMessagesActionCreator.create(
      {
        customerId: @props.match.params.customerId
        conversationId: @props.match.params.id
        userId: AuthStore.user.id
        text: @state.message
        time: @state.date
      },
      => @setState { message: '', date: new Date }
    )

  setNewDateTime: (date, time) =>
    datePart = moment(date).format('YYYY-MM-DD')
    timePart = moment(time).format('HH:mm Z')
    @setState date: moment("#{datePart} #{timePart}").toDate()

  backUrl: =>
    browserHistory.default.push "/conversations/#{@props.match.params.folder}/#{@props.match.params.id}"

  onSetDate: (date) =>
    @setNewDateTime(date, @state.date)

  onSetTime: (time) =>
    @setNewDateTime(@state.date, time)

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("header", {"className": (styles.header)},
        React.createElement("h2", null, """
          Schedule Message
"""),
        React.createElement("span", {"className": (styles.close), "onClick": (@backUrl)},
          React.createElement(Isvg.default, {"src": (require 'components/shared/images/cross.svg')})
        )
      ),

      React.createElement("div", {"className": (styles.content)},
        React.createElement("div", {"className": (styles.new_schedule)},
          React.createElement("textarea", { \
            "placeholder": "Add text of your message here",  \
            "className": (styles.new_message),  \
            "onChange": (@onMessageChange),  \
            "value": (@state.message)
          }),

          React.createElement("div", {"className": (styles.controls)},
            React.createElement("div", {"className": (styles.datepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set date",  \
                "time": (false),  \
                "onChange": (@onSetDate),  \
                "value": (@state.date)
              })
            ),
            React.createElement("div", {"className": (styles.timepicker_container)},
              React.createElement(DateTimePicker, { \
                "containerClassName": (styles.datepicker),  \
                "placeholder": "Set time",  \
                "date": (false),  \
                "onChange": (@onSetTime),  \
                "value": (@state.date)
              })
            ),
            React.createElement(Button, { \
              "title": "Add Message Schedule",  \
              "disabled": (!@state.message),  \
              "className": (styles.submit),  \
              "onClick": (@createMessage)
            })
          ),
          (React.createElement("div", {"className": (styles.disabled_overlay)}) if @state.scheduleEdit)
        ),

        (if ScheduledMessagesStore.error
          React.createElement("p", {"className": (styles.error)}, (ScheduledMessagesStore.error))
        ),

        React.createElement("div", {"className": (styles.divider)}, """
          Active schedules """, React.createElement("strong", null, "(", (ScheduledMessagesStore.totalUncompleted), ")")
        ),

        React.createElement(LoadableWrapperComponent, {"loading": (ScheduledMessagesStore.loading)},
          React.createElement("ul", {"className": (styles.messages)},
            (ScheduledMessagesStore.uncompletedMessages.map (m) =>
              React.createElement("li", {"className": (styles.message_item), "key": (m.uuid)},
                React.createElement(ScheduledMessageItemComponent, { \
                  "onEditClick": (@onScheduleEdit),  \
                  "onEditSubmit": (@editSchedule),  \
                  "onCancelEdit": (@onScheduleEditCancel),  \
                  "onDelete": (@deleteSchedule),  \
                  "message": (m),  \
                  "disabled": (@state.scheduleEdit)
                })
              )
            )
          )
        )
      )
    )

module.exports = ScheduledMessagesManagerComponent
