{ Link } = require 'react-router-dom'
browserHistory = require './../../../../history'
classnames = require 'classnames'

styles = require './customer-info'
CustomerCardComponent = require 'components/customers/CustomerCardComponent/CustomerCardComponent'
TagsSelectorComponent = require 'components/tags/TagsSelectorComponent/TagsSelectorComponent'
CustomerCommentComponent = require 'components/customers/CustomerCommentComponent/CustomerCommentComponent'
CustomerInfoActionCreator = require 'actions/CustomerInfoActionCreator'
OnlineCustomerOrdersActionCreator = require 'actions/OnlineCustomerOrdersActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
CustomerTagsSelector = require './CustomerTagsSelector'
Button = require 'components/shared/Button'
BaseComponent = require 'components/BaseComponent'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
{ crmPluginEnabled } = require 'root/config'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs

propTypes = require 'prop-types'

class CustomerInfoComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @withContext = props.conversation?.status != CONVERSATION_STATUS.CLOSED
    @initComponent()
    @nowSubscribe = null

  dependsOnStores: [CRMInfoModuleStore]

  componentWillReceiveProps: (nextProps) =>
    @withContext = nextProps.conversation?.status != CONVERSATION_STATUS.CLOSED

    if @props.externalClientId && @props.externalClientId != nextProps.externalClientId
      setTimeout(
          =>
            OnlineCustomerOrdersActionCreator.unSubscribe @props.externalClientId
          0
      )

    if nextProps.externalClientId && nextProps.externalClientId != @props.externalClientId
      @nowSubscribe = nextProps.externalClientId
      setTimeout(
        =>
          OnlineCustomerOrdersActionCreator.subscribe nextProps.externalClientId
        10
     )

  createCustomerTags: (tags) =>
    for tag in tags
      CustomerInfoActionCreator.createTag @props.customer.id, tag

  destroyCustomerTags: (tags) =>
    for tag in tags
      CustomerInfoActionCreator.destroyTag @props.customer.id, tag

  startConversation: =>
    CustomerInfoActionCreator.startConversationByCustomerId(
      @props.customer.id
      (conversation) ->
        browserHistory.default.push("/conversations/all_conversations/#{conversation?.id}")
    )

  openClientPlugin: =>
    if crmPluginEnabled
      config = pluginConfigs.ClientPlugin({ clientId: @props.externalClientId, options: { isSMSTool: true, entityKey: @props.entityKey } })
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-client-#{@props.externalClientId}", @withContext
      )

  render: ->
    customer = @props.customer
    if customer?.tags
      tags = R.reject(R.isNil) customer.tags

    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
        (if !@props.loading && @props.customer
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.section)},
              React.createElement(CustomerCardComponent, { \
                "customer": (customer),  \
                "customerLink": (@props.customerLink)
              })
            ),
            (if customer.tags.length
              React.createElement("div", {"className": (classnames(styles.section, "tags_section"))},
                React.createElement("div", {"className": (styles.title)}, " Client tags "),
                React.createElement(CustomerTagsSelector, { \
                  "customerId": (customer.id),  \
                  "activeTags": (customer.tags)
                })
              )
            ),
            React.createElement("div", {"className": (styles.section)},
              React.createElement(Button, { \
                "title": "Client plugin",  \
                "onClick": (@openClientPlugin),  \
                "disabled": (!@props.externalClientId)
              })
            ),
            (if CRMInfoModuleStore['payments']
              React.createElement("div", {"className": (styles.section)},
                React.createElement("div", {"className": (styles.title)},
                  React.createElement("span", {"className": (styles.balance_label)},
                    React.createElement("i", {"className": (styles.wallet_icon)}), """
                    Balance:
"""),
                  React.createElement("b", {"className": (styles.title_balance)},
                    (CRMInfoModuleStore['payments'].balance)
                  )
                )
              )
            ),
            React.createElement("div", {"className": ("comments_section")},
              React.createElement("div", {"className": (styles.title)}, "Comments"),
              React.createElement(CustomerCommentComponent, {"customer": (customer)})
            )
          )
        )
      )
    )

CustomerInfoComponent.propTypes =
  customer: propTypes.object
  loading: propTypes.bool
  profileLink: propTypes.string

module.exports = CustomerInfoComponent
