PersonalizedStore = require 'stores/abstract/PersonalizedStore'
UniqueClientsByAgentsActionCreator = require './UniqueClientsByAgentsActionCreator'
ActionTypes = require 'constants/ActionTypes'

actionsMap =
  "#{ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_SUCCESS}": (data) ->
    @loading = false
    @ucpops = data.ucpops
    @emitChange()
  "#{ActionTypes.UNIQUE_CLIENTS_BY_AGENTS_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class UniqueClientsByAgentsStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @ucpops = null
    @error = null
    @registerActions()

  getActionCreator: -> new UniqueClientsByAgentsActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = UniqueClientsByAgentsStore
