classnames = require 'classnames'
copyToClipboard = require 'clipboard-copy'

styles = require './inline-text-input'
BaseComponent = require 'components/BaseComponent'

propTypes = require 'prop-types'

class InlineTextInput extends BaseComponent
  @propTypes:
    className: propTypes.string
    value: propTypes.string,
    isTitle: propTypes.bool,
    onSubmit: propTypes.func.isRequired
    textAddition: propTypes.func
    disabled: propTypes.bool
    editByIcon: propTypes.bool
    isDecrypted: propTypes.bool
    onDecryptCredential: propTypes.func

  constructor: (props) ->
    super(props)
    @state =
      value: props.value
      isCopied: false
    @initComponent()

  componentWillReceiveProps: (nextProps) ->
    @setState value: nextProps.value unless @state.editing

  componentWillUnmount: ->
    clearTimeout @timeout

  onEdit: =>
    @setState editing: true
    _.defer => ReactDOM.findDOMNode(@refs.input).focus()

  onDecrypt: =>
    @props.onDecryptCredential()

  onBlur: =>
    @setState editing: false
    @props.onSubmit(@state.value)

  onKeyPress: (e) =>
    if e.key == 'Enter'
      @setState editing: false
      @props.onSubmit(@state.value)

  onChange: (e) =>
    value = e.target.value
    @setState value: value

  onCopyValue: =>
    copyToClipboard(@state.value)
    @setState isCopied: true
    @timeout = setTimeout(
      () => @setState isCopied: false
      1500
    )

  render: =>
    containerType = if @props.isTitle
      styles.titleContainer
    else
      styles.container

    isComponentEncrypted = @props.onDecryptCredential && !@props.isDecrypted

    className = classnames(
      containerType,
      "#{styles.editing}": @state.editing,
      @props.className
    )

    React.createElement("div", {"className": (className)},
      (if @state.editing
        React.createElement("input", { \
          "ref": "input",  \
          "type": ('text'),  \
          "value": (@state.value),  \
          "onBlur": (@onBlur),  \
          "onChange": (@onChange),  \
          "onKeyPress": (@onKeyPress),  \
          "className": (classnames(styles.input, @props.className,
            "#{styles.inputOutlined}": @props.isTitle
          ))
        })
      ),
      (!@state.editing && @state.value &&
        React.createElement("div", { \
          "className": (classnames(@props.className, styles.value_container, {
            "#{styles.cursor_auto}": @props.disabled || @props.editByIcon
          })),  \
          "onClick": (if @props.disabled || @props.editByIcon || isComponentEncrypted then null else @onEdit)
        },
          React.createElement("span", {"className": (classnames(
              "#{styles.value}": @props.textAddition
            ))
          },
            (@state.value)
          ),
          (unless @state.editing
            @props.textAddition?(isComponentEncrypted)
          )
        )
      ),
      (!@state.editing && !@state.value &&
        React.createElement("div", { \
          "className": (classnames(@props.className, styles.value,
            "#{styles.gray}": @props.placeholder
          )),  \
          "onClick": (@onEdit)
        },
          (@props.placeholder || '…')
        )
      ),
      React.createElement("div", {"className": (styles.icons_block)},
        (if @props.isDecrypted && @state.value != '' && !@state.isCopied
          React.createElement("div", { \
            "className": (classnames(styles.icon, styles.copy_icon )),  \
            "onClick": (if @state.isCopied then null else @onCopyValue)
          })
        ),
        (if @state.isCopied
          React.createElement("span", {"className": (classnames(styles.copied, styles.gray))}, "Copied")
        ),
        (if @props.editByIcon && !isComponentEncrypted && !@props.disabled
          React.createElement("div", { \
            "className": (classnames(styles.icon, styles.edit_icon)),  \
            "onClick": (@onEdit)
          })
        ),
        (if @props.value && isComponentEncrypted
          React.createElement("div", { \
            "className": (classnames(styles.icon, styles.unlock_icon)),  \
            "onClick": (@onDecrypt)
          })
        ),
        (if @props.onDelete?
          React.createElement("div", { \
            "className": (classnames(styles.icon, styles.remove)),  \
            "onClick": (@props.onDelete)
          })
        )
      )
    )

module.exports = InlineTextInput
