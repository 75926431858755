BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'
config = require 'root/config'

class SoundStore extends BaseStore

  constructor: ->
    super()
    @title = ''
    @options = {}
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_PLAY_SOUND
        conversationId = payload.message.data.conversation_id
        nowconversationId = parseInt window.location.pathname.split('/').reverse()[0]

        if config.mqttSound || conversationId != nowconversationId
          audioEl = document.getElementsByClassName 'audio-element'
          audioEl[0].play() if audioEl[0]
      when ActionTypes.MQTT_NOTIFICATION
        @title = 'New message'
        @options.body = payload.message.text
        @options.data = payload.message.data
        @options.tag = Date.now()
        @emitChange()


module.exports = new SoundStore()
