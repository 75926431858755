RATES =
  'default':
    label: 'Default'
    value: 'default'
  '6+ months rate':
    label: '6+ months rate'
    value: '6+ months rate'
  '12+ months rate':
    label: '12+ months rate'
    value: '12+ months rate'

ROLES =
  qa_teamlead:
    label: 'QA team lead'
    value: 'qa_teamlead'
  manager:
    label: 'Manager'
    value: 'manager'
  root:
    label: 'Manager'
    value: 'manager'
  system_agent:
    label: 'System agent'
    value: 'system_agent'
  teamlead:
    label: 'Team Lead'
    value: 'teamlead'
  skilled_agent:
    label: 'Skilled Agent'
    value: 'skilled_agent'
  agent:
    label: 'Agent'
    value: 'agent'
  newbie_agent:
    label: 'Newbie Agent'
    value: 'newbie_agent'
  fire_fighter:
    label: 'Fire Fighter'
    value: 'fire_fighter'
  side_project:
    label: 'Side Project'
    value: 'side_project'
  sales:
    label: 'Sales'
    value: 'sales'
  ntff:
    label: 'NTFF'
    value: 'ntff'

SHIFT_ROLES =
  qa_teamlead:
    label: 'QA team lead'
    value: 'qa_teamlead'
  manager:
    label: 'Manager'
    value: 'manager'
  root:
    label: 'Manager'
    value: 'manager'
  system_agent:
    label: 'System agent'
    value: 'system_agent'
  teamlead:
    label: 'Team Lead'
    value: 'teamlead'
  second_teamlead:
    label: 'Second team lead'
    value: 'second_teamlead'
  skilled_agent:
    label: 'Skilled Agent'
    value: 'skilled_agent'
  agent:
    label: 'Agent'
    value: 'agent'
  newbie_agent:
    label: 'Newbie Agent'
    value: 'newbie_agent'
  fire_fighter:
    label: 'Fire Fighter'
    value: 'fire_fighter'
  side_project:
    label: 'Side Project'
    value: 'side_project'
  sales:
    label: 'Sales'
    value: 'sales'
  ntff:
    label: 'NTFF'
    value: 'ntff'

ACTIVITIES =
  active:
    label: 'Active'
    value: 'active'
  wasted:
    label: 'Wasted'
    value: 'wasted'

BONUS_STATES =
  striked:
    label: 'Striked'
    value: 'striked'
  approved:
    label: 'Approved'
    value: 'approved'
  pending:
    label: 'Pending'
    value: 'pending'

STATUSES =
  online:
    label: 'Online'
    value: 'online'
  dormant:
    label: 'Dormant'
    value: 'dormant'
  offline:
    label: 'Offline'
    value: 'offline'

TL_SHIFT_GRADES =
  approved:
    label: 'approved'
    value: 'approved'
  striked:
    label: 'striked'
    value: 'striked'
  pending:
    label: 'pending'
    value: 'pending'

TL_SHIFT_ROLES =
  teamlead:
    label: 'teamlead'
    value: 'teamlead'
  second_teamlead:
    label: 'second_teamlead'
    value: 'second_teamlead'
  deleted:
    label: 'deleted'
    value: 'deleted'

TL_SHIFT_DELETED_ROLES =
  deleted: true

AGENTS_PER_PAGE = 30

DEFAULT_STATISTIC_ROWS_PER_PAGE = 30
TL_STATISTIC_ROWS_PER_PAGE = 10
TL_MONTHLY_STATISTIC_ROWS_PER_PAGE = 5

STATISTIC_ROWS_PER_PAGE =
  shifts: TL_STATISTIC_ROWS_PER_PAGE
  weekly: TL_STATISTIC_ROWS_PER_PAGE
  monthly: TL_MONTHLY_STATISTIC_ROWS_PER_PAGE

MIN_PASSWORD_LENGTH = 6

ROLE_SORTING_ORDER =
  "#{ROLES.agent.value}": 1
  "#{ROLES.newbie_agent.value}": 1
  "#{ROLES.sales.value}": 1
  "#{ROLES.ntff.value}": 3
  "#{ROLES.skilled_agent.value}": 4
  "#{ROLES.teamlead.value}": 5

ROLE_STATS_SORTING_ORDER =
  "#{ROLES.agent.value}": 1
  "#{ROLES.newbie_agent.value}": 2
  "#{ROLES.sales.value}": 3
  "#{ROLES.ntff.value}": 4
  "#{ROLES.skilled_agent.value}": 5
  "#{ROLES.teamlead.value}": 6

STATUS_SORTING_ORDER =
  "#{STATUSES.online.value}": 1
  "#{STATUSES.dormant.value}": 2
  "#{STATUSES.offline.value}": 3

TAKE_NEW_BUTTON_ACCESS =
  "#{ROLES.qa_teamlead.value}": false
  "#{ROLES.manager.value}": false
  "#{ROLES.root.value}": false
  "#{ROLES.system_agent.value}": false
  "#{ROLES.teamlead.value}": false
  "#{ROLES.ntff.value}": true
  "#{ROLES.skilled_agent.value}": true
  "#{ROLES.agent.value}": true
  "#{ROLES.newbie_agent.value}": true
  "#{ROLES.sales.value}": true

TAKE_NEW_LEAD_FILTER =
  "#{ROLES.newbie_agent.value}": true
  "#{ROLES.agent.value}": true

DORMANT_ROLES =
  "#{ROLES.newbie_agent.value}": true
  "#{ROLES.agent.value}": true
  "#{ROLES.sales.value}": true

ASSIGN_BUTTON_ACCESS =
  "#{ROLES.qa_teamlead.value}": true
  "#{ROLES.manager.value}": true
  "#{ROLES.root.value}": true
  "#{ROLES.system_agent.value}": true
  "#{ROLES.teamlead.value}": true
  "#{ROLES.skilled_agent.value}": true
  "#{ROLES.ntff.value}": true
  "#{ROLES.agent.value}": false
  "#{ROLES.newbie_agent.value}": false
  "#{ROLES.sales.value}": false

SCROLL_DISABLED =
  "#{ROLES.teamlead.value}": true

BLACKLIST_EDIT_ACCESS =
  "#{ROLES.qa_teamlead.value}": true
  "#{ROLES.manager.value}": true
  "#{ROLES.teamlead.value}": true

FOR_DASHBOARD = [
  ROLES.newbie_agent.value
  ROLES.agent.value
  ROLES.sales.value
  ROLES.ntff.value
]

MQTT_ROLES_SOUND =
  "#{ROLES.agent.value}": true
  "#{ROLES.newbie_agent.value}": true
  "#{ROLES.sales.value}": true
  "#{ROLES.ntff.value}": true
  "#{ROLES.teamlead.value}": true

TAG_CHECKING_EXCEPT =
  "#{ROLES.ntff.value}": true

QA_DASHBOARD_STATISTIC =
  "#{ROLES.manager.value}": true

ALLOW_ACCESS_ERRORS_ROLES =
  "#{ROLES.teamlead.value}": true
  "#{ROLES.qa_teamlead.value}": true
  "#{ROLES.manager.value}": true

HOVER_INFO_FAILED = {
  'twilio': (failed_info) =>
    "Twilio\n\n#{failed_info.info?['docs']}"
  'bandwidth': (failed_info) =>
    "Bandwidth\n\n#{failed_info.info?['Friendly Description']}\n\n#{failed_info.info?['Explanation Of Error']}"
}

module.exports = {
  HOVER_INFO_FAILED
  RATES
  ROLES
  SHIFT_ROLES
  ACTIVITIES
  BONUS_STATES
  STATUSES
  TL_SHIFT_GRADES
  TL_SHIFT_ROLES
  TL_SHIFT_DELETED_ROLES
  AGENTS_PER_PAGE
  DEFAULT_STATISTIC_ROWS_PER_PAGE
  TL_STATISTIC_ROWS_PER_PAGE
  MIN_PASSWORD_LENGTH
  ROLE_SORTING_ORDER
  STATUS_SORTING_ORDER
  TAKE_NEW_BUTTON_ACCESS
  TAKE_NEW_LEAD_FILTER
  DORMANT_ROLES
  ROLE_STATS_SORTING_ORDER
  ASSIGN_BUTTON_ACCESS
  SCROLL_DISABLED
  BLACKLIST_EDIT_ACCESS
  FOR_DASHBOARD
  STATISTIC_ROWS_PER_PAGE
  TAG_CHECKING_EXCEPT
  QA_DASHBOARD_STATISTIC
  MQTT_ROLES_SOUND
  ALLOW_ACCESS_ERRORS_ROLES
}
