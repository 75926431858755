classnames = require 'classnames'
ReactPaginate = require 'react-paginate'

styles = require './customer-orders'
CRMInfoModuleActionCreator = require 'actions/CRMInfoModuleActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
{ IN_PROGRESS_STATUS } = require 'constants/Orders'
ActionTypes = require 'constants/ActionTypes'
{ calculateTimerValue } = require 'lib/utils'
TabOptionsComponent = require '../TabOptionsComponent'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
{ crmPluginEnabled } = require 'root/config'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
{ localStorageKey } = require 'constants/CRMPlugin'
conf = require 'root/config'
ADDITIONAL_ORDER_MENU       = require './../../../../constants/additionalOrderMenu'
paginationStyles = require 'components/shared/styles/pagination.styl'
Dispatcher = require './../../../../dispatcher/Dispatcher'

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs

propTypes = require 'prop-types'

class CustomerOrdersComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      searchQuery: ''

    @withContext = props.conversation?.status != CONVERSATION_STATUS.CLOSED

  dependsOnStores: [CRMInfoModuleStore, CustomerOrdersStore]

  componentWillReceiveProps: (nextProps) =>
    @withContext = nextProps.conversation?.status != CONVERSATION_STATUS.CLOSED
    if nextProps.newWindowClicked
      nextProps.newWindowClicked @props.dataKey, null

  onOrderClick: (e) =>
    if crmPluginEnabled
      orderId = e.currentTarget.dataset.order
      config = pluginConfigs.OrderCompactPlugin(
        orderId: orderId,
        options:
          isResizable: true
          entityKey: @props.entityKey
          conversationTags: @props.customer?.tags
      )
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-order-#{orderId}", @withContext
      )

  onAllOrdersClick: =>
    if crmPluginEnabled
      config = [{
        plugins:
          OrderHistoryPlugin:
            data:
              role: 'client'
              roleId: @props.externalClientId
              isSMSTool: true
      }]
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-allOrders", @withContext
      )

  onNewOrderClick: =>
    if crmPluginEnabled
      config = [{
        plugins:
          OrderCompactPlugin:
            data:
              clientId: @props.externalClientId
              onOrderCreation: @onOrderCreation
              entityKey: @props.entityKey
              options:
                entityKey: @props.entityKey
                isResizable: true
                isShowOptoutWarning: @isShowOptoutWarning(@props.customer)
      }]
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-newOrder", false
      )
      customer = CRMInfoModuleStore.currentCustomer

  onMessageFormOpen: (title, messages) =>
    if crmPluginEnabled
      context = 'offer_cabinet'
      externalClientId = CRMInfoModuleStore.externalClientId

      config = [{
        plugins:
          MessageFormPlugin:
            data:
              messages: messages
              context: context
              title: title
              hideAllBtn: false
              entityKey: @props.entityKey
              client: externalClientId
              options:
                isModal: true
                entityKey: @props.entityKey
                isResizable: true
      }]
      CRMPluginActionCreator.setConfig(
        config, @props.entityKey, "#{@props.entityKey}-#{context}", @withContext
      )

  onOrderCreation: (order) =>
    return unless @withContext
    allConfigs = localStorage.getItem(localStorageKey)
    try
      allConfigs = JSON.parse(allConfigs) || {}
    catch err
      allConfigs = {}
    { entityKey } = @props
    return unless allConfigs[entityKey]
    config = pluginConfigs.OrderCompactPlugin(
      orderId: order.id,
      options:
        isResizable: true
        entityKey: @props.entityKey
    )
    delete allConfigs[entityKey]["#{entityKey}-newOrder"]
    allConfigs[entityKey]["#{entityKey}-order-#{order.id}"] = config
    localStorage.setItem(localStorageKey, JSON.stringify(allConfigs))

  replace: (o) ->
    searchQuery = @state.searchQuery
    return [o.number, " (#{o.key})"] unless searchQuery
    return [o.number, " (#{o.key})"] unless searchQuery.length > 2
    searchQuery = searchQuery.replace(/[^-\w\s]/gi, '')
    parts = o.number.split(new RegExp("(#{searchQuery})", 'gi'))
    for i in [1...parts.length] by 2
      parts[i] = React.createElement("span", {"className": (styles.search), "key": (i)}, (parts[i]))
    parts.push(" (#{o.key})")
    parts

  onMouseEnter: (ref_name) =>
    return () =>
      return unless @refs[ref_name]
      classes = @refs[ref_name].className.split ' '
      classes.push styles.note_visible
      @refs[ref_name].className = classes.join ' '

  onMouseLeave: (ref_name) =>
    return () =>
      return unless @refs[ref_name]
      classes = @refs[ref_name].className.split ' '
      classes = classes.filter (item) ->
        item != styles.note_visible
      @refs[ref_name].className = classes.join ' '

  isShowOptoutWarning: (customer) =>
    return customer?.optout || customer?.optin || customer?.tags?.some( (tag) => tag.name == 'Blacklisted')

  renderOrder: (order, i) =>
    isInProgress = order
      .main_status
      .toLowerCase()
      .indexOf(IN_PROGRESS_STATUS) != -1
    statusClass =
      classnames(styles.status, "#{styles.status_progress}": isInProgress)

    return (React.createElement("li", { \
      "key": (order.id),  \
      "className": (styles.order_container),  \
      "onMouseEnter": (@onMouseEnter("note#{order.id}")),  \
      "onMouseLeave": (@onMouseLeave("note#{order.id}"))
    },
      (if order.title
        React.createElement("span", {"id": "note#{order.id}", "className": (styles.note), "ref": ("note#{order.id}")}, (order.title))
      ),
      React.createElement("div", {"className": (styles.text_container), "data-order": (order.id), "onClick": (@onOrderClick)},
        React.createElement("div", {"className": (styles.title)}, (@replace(order))),
        React.createElement("div", {"className": (statusClass)},
          React.createElement("span", {"className": (styles.status_text)},
            (order.payment_status), " \x2F ", (order.main_status)
          )
        ),
        (React.createElement("div", {"className": (styles.divider)}) unless i == @props.data.length - 1)
      )
    ))

  renderAssignment: (order, i) =>

    (React.createElement("li", { \
      "key": (order.id),  \
      "className": (styles.order_container)
    },
      React.createElement("span", {"className": (styles.draft_icon)}),
      React.createElement("div", {"className": (styles.text_container), "data-order": (order.id), "onClick": (@onOrderClick)},
        React.createElement("div", {"className": (styles.title)}, ("#{order.number} (#{order.key})")),
        React.createElement("div", {"className": (styles.status)},
          React.createElement("span", {"className": (styles.status_text)}, "Draft\x2FAssignment")
        ),
        (React.createElement("div", {"className": (styles.divider)}) unless i == @props.data.length - 1)
      )
    ))

  onLoadNext: (numPage) =>
    if numPage.selected != CustomerOrdersStore.ordersPage-1 && !@props.loading
      customer = CRMInfoModuleStore.currentCustomer
      CRMInfoModuleActionCreator.getOrders(
        customer
        conf.urls.customers.orders(customer.crm_ids[0], numPage.selected + 1)
      )
      Dispatcher.dispatch
        type: ActionTypes.ORDERS_SET_PAGE
        payload:
          page: numPage.selected + 1

  onOrderSearch: (search) =>
    @setState searchQuery: search
    customer = CRMInfoModuleStore.currentCustomer
    unless search
      CRMInfoModuleActionCreator.getOrders(
        customer
        conf.urls.customers.orders(customer.crm_ids[0])
      )
    if search && search.length > 2
      CRMInfoModuleActionCreator.getOrders(
        customer
        conf.urls.customers.ordersSearch(customer.crm_ids[0], window.encodeURI search)
      )

  render: ->
    pageCount = Math.ceil(CustomerOrdersStore.total / CustomerOrdersStore.ordersLimit)
    lastChannel = @props.conversation?.last_channel || {}
    serviceName = lastChannel.service_name
    typeMessage = lastChannel.country_type || ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1
    
    React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
      (if !@props.error
        React.createElement(TabOptionsComponent, { \
          "new": (
            text: 'New order'
            onNewOrder: @onNewOrderClick
          ),  \
          "all": (
            text: 'All orders'
            onClick: @onAllOrdersClick
          ),  \
          "offer": (
            onOpenMessageForm: @onMessageFormOpen
            typeMessage: typeMessage
            serviceName: serviceName
          )
        })
      ),
      React.createElement("div", null,
          React.createElement(SearchInputComponent, { \
            "onChange": (@onOrderSearch),  \
            "onClear": (@onOrderSearch),  \
            "placeholder": 'Search orders',  \
            "className": (styles.search_messages),  \
            "inputClassName": (styles.search_input),  \
            "clearCross": (true)
          })
      ),
      (
        if @props.error
          React.createElement("p", {"className": (styles.error)}, (@props.error))
        else unless (@props.data || {}).length
          React.createElement("p", {"className": (styles.no_data)}, "No data")
        else
          React.createElement("div", null,
            React.createElement("span", {"className": (styles.orders_count)},
              ("Shown #{@props.data.length} orders of #{CustomerOrdersStore.ordersCount}. "),
              (if CustomerOrdersStore.ordersCount > @props.data.length
                React.createElement("span", null, "To view all - click \'All orders\' button above.")
              )
            ),
            React.createElement("ul", {"className": (styles.container)},
              (@props.data.map (order, i) =>
                if order.is_draft
                  @renderAssignment order, i
                else
                  @renderOrder order, i
              )
            ),
            (if pageCount > 1
              React.createElement("div", {"className": (paginationStyles.wrapper)},
                React.createElement(ReactPaginate.default, { \
                  "previousLabel": '<<',  \
                  "nextLabel": '>>',  \
                  "pageCount": (pageCount),  \
                  "marginPagesDisplayed": (1),  \
                  "pageRangeDisplayed": (1),  \
                  "breakClassName": (paginationStyles.page),  \
                  "onPageChange": (@onLoadNext),  \
                  "initialPage": (CustomerOrdersStore.ordersPage-1),  \
                  "pageClassName": (paginationStyles.page),  \
                  "nextClassName": (paginationStyles.page),  \
                  "previousClassName": (paginationStyles.page),  \
                  "activeClassName": (paginationStyles.active),  \
                  "pageLinkClassName": (paginationStyles.link),  \
                  "nextLinkClassName": (paginationStyles.link),  \
                  "previousLinkClassName": (paginationStyles.link),  \
                  "containerClassName": (paginationStyles.container)
                })
              )
            )
          )
      )
    )

CustomerOrdersComponent.propTypes =
  data: propTypes.arrayOf(
    propTypes.shape(
      number: propTypes.string.isRequired
      main_status: propTypes.string.isRequired
    )
  )
  loading: propTypes.bool
  error: propTypes.string

module.exports = CustomerOrdersComponent
