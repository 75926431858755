styles = require './notification-item.styl'

NotificationItem = ({ notification, height, onClose, position, marginBetween }) ->
    minutesAway = Math.round((new Date() - Date.parse(notification.last_activity_at)) / (60 * 1000))
    style =
      height: height
      bottom: 10 + position * (height + marginBetween)
    return (
      React.createElement("div", { \
        "className": (styles.container),  \
        "style": (style)
      },
        React.createElement("button", { \
          "className": (styles.close_button),  \
          "onClick": (() => onClose(notification.id))
        },
          React.createElement("span", null), React.createElement("span", null)
        ),
        React.createElement("div", {"className": (styles.body)},
          React.createElement("h3", {"className": (styles.name)}, ("#{notification.first_name} #{notification.last_name}")),
          React.createElement("p", {"className": (styles.status)}, ("has been inactive for #{minutesAway} minutes"))
        )
      )
    )

module.exports = NotificationItem
