moment = require 'moment'
classnames = require 'classnames'
Isvg = require 'react-inlinesvg'

styles = require './previous-conversation'
Dispatcher = require 'root/dispatcher/Dispatcher'
BaseComponent = require 'components/BaseComponent'
ConversationItemsListComponent = require '../ConversationItemsListComponent'
CREDENTIAL_TYPE = require 'constants/CredentialTypes'
RankingActionCreator = require 'actions/RankingActionCreator'
RankingStore = require 'stores/RankingStore'
{ calculateTopAndBottomPosition } = require 'lib/conversation-utils'
DownloadAllButtonComponent = require 'components/conversations/DownloadAllButtonComponent'

propTypes = require 'prop-types'


getCredentialIconSrc = (credential_type) ->
  iconName = switch credential_type
    when CREDENTIAL_TYPE.PHONE then "phone"
    when CREDENTIAL_TYPE.EMAIL then "mail"
    # when CREDENTIAL_TYPE.FACEBOOK then "facebook" # messenger_id
    when CREDENTIAL_TYPE.FACEBOOK_UNACCESSIBLE then "facebook" # facebook_id
    when CREDENTIAL_TYPE.TWITTER then "twitter"
  return '' unless iconName
  return require("images/icons/credential/#{iconName}.svg")


class PreviousConversationComponent extends BaseComponent
  @propTypes:
    conversation: propTypes.object.isRequired
    itemsListProps: propTypes.shape(
      itemsHighLightingIndex: ConversationItemsListComponent.propTypes.itemsHighLightingIndex
      selectedItem: ConversationItemsListComponent.propTypes.selectedItem
      onItemSelect: ConversationItemsListComponent.propTypes.onItemSelect
      onTop: propTypes.func
    )

  @defaultProps:
    conversation: {}

  constructor: (props) ->
    super(props)
    @state =
      opened: !!props.opened
    @scrollTop = 0
    @scrollBottom = 0
    @initComponent()

  componentDidMount: ->
    super()
    if @state.opened && @props.scrollElement
      @props.scrollElement?.addEventListener('scroll', @onContainerScroll)
    if @state.opened && @props.rankable
      { conversation } = @props
      setTimeout(
        -> RankingActionCreator.openConversation conversation
        30
      )

  componentWillUnmount: ->
    super()
    @props.scrollElement?.removeEventListener('scroll', @onContainerScroll)

  onContainerScroll: =>
    return unless @state.opened
    rootTopOffset = @refs.root.offsetTop + @refs.title.clientHeight
    rootBottomOffset = @refs.root.offsetTop + @refs.root.clientHeight
    { scrollElement } = @props
    isElementAboveView = rootBottomOffset < scrollElement.scrollTop
    isElementBellowView = rootTopOffset > scrollElement.scrollTop + scrollElement.clientHeight
    return if isElementAboveView || isElementBellowView

    maxScrollValue = @refs.root.clientHeight - @refs.title.clientHeight
    { scrollTop, scrollBottom } = calculateTopAndBottomPosition(
      rootTopOffset, rootBottomOffset, scrollElement, maxScrollValue
    )

    # use native js dom management instead of React approach because of render
    # function is too heavy for rerender scroll every time
    scrollTopElement = document.getElementById "scrollOpenedToTop-#{@props.conversation.id}"
    return unless scrollTopElement

    if scrollTop <= 0
      scrollTopElement.classList.add styles.visibility_hidden
    else
      scrollTopElement.classList.remove styles.visibility_hidden
      scrollTopElement.setAttribute 'style', "top: #{scrollTop}px"
    scrollBottomElement = document.getElementById "scrollOpenedToBottom-#{@props.conversation.id}"
    if scrollBottom <= 0
      scrollBottomElement.classList.add styles.visibility_hidden
    else
      scrollBottomElement.classList.remove styles.visibility_hidden
      scrollBottomElement.setAttribute 'style', "bottom: #{scrollBottom}px"

    @scrollTop = if scrollTop >= 0 then scrollTop else 0
    @scrollBottom = if scrollBottom >=0 then scrollBottom else 0

  toggleOpen: =>
    if @state.opened
      RankingActionCreator.closeConversation @props.conversation
      @props.scrollElement.removeEventListener('scroll', @onContainerScroll)
    else
      if @props.scrollElement
        @props.scrollElement.addEventListener('scroll', @onContainerScroll)
      RankingActionCreator.openConversation @props.conversation
    @setState opened: !@state.opened

  formatTags: (tags = []) =>
    tags.map (tag, index) =>
      React.createElement("span", { \
        "key": (index),  \
        "className": (styles.tag),  \
        "onMouseEnter": (@onTagEnter),  \
        "onMouseLeave": (@onTagLeave)
      },
        ( tag?.name )
      )

  onTagEnter: (e) =>
    e.target.classList.add styles.tag_full

  onTagLeave: (e) =>
    e.target.classList.remove styles.tag_full

  componentWillReceiveProps: (props) ->
    if !R.isNil(props.itemsListProps?.selectedItem)
      if @props.scrollElement && !@state.opened
        @props.scrollElement.addEventListener('scroll', @onContainerScroll)
      @setState opened: true

  onTopClick: =>
    @props.onTop(@refs.root)

  onBottomClick: =>
    @props.onBottom(@refs.root)

  render: ->
    isRanking = (RankingStore.estimation.conversation || {}).id == @props.conversation.id
    channel = @props.conversation.last_message?.channel
    titleClassname = classnames(styles.title,
      "#{styles.title_opened}": @state.opened
    )
    # React16 CHECK FIX credential_icon
    React.createElement("div", {"ref": "root", "className": (styles.root)},
      React.createElement("div", {"className": (titleClassname), "ref": "title", "onClick": (@toggleOpen)},
        (React.createElement("span", {"className": (styles.title__ranked)}) if @props.rankable && isRanking),
        (
          if channel?.channel_type?
            React.createElement("span", {"className": (styles.credential_icon)},
              (if getCredentialIconSrc(channel.channel_type)
                React.createElement(Isvg.default, {"src": (getCredentialIconSrc(channel.channel_type))})
              )
            )
          else
            React.createElement("span", {"className": (styles.credential_icon)}, React.createElement(Isvg.default, {"src": (getCredentialIconSrc('phone'))}))

        ),
        React.createElement("span", {"className": (styles.primary_text)},
          (moment(@props.conversation.created_at).format('D MMM,'))
        ),
        React.createElement("span", {"className": (styles.secondary_text)},
          (moment(@props.conversation.created_at).format('YY'))
        ),
        (
          if @props.conversation.shift_type
            React.createElement("span", {"className": (styles.secondary_text)}, """
              (""", (@props.conversation.shift_type), """)
""")
        ),
        (
          if @props.conversation.last_message?.channel?
            React.createElement("span", {"className": (styles.secondary_text)},
              (' / ' + @props.conversation.last_message?.channel?.name)
            )
        ),
        React.createElement("span", {"className": (styles.last_user)},
          (if @props.conversation.last_user
            "@#{@props.conversation.last_user.last_name}"
          )
        ),
        React.createElement("span", {"className": (styles.download_all)},
          React.createElement(DownloadAllButtonComponent, {"conversationId": (@props.conversation.id)})
        ),
        React.createElement("div", {"className": (styles.tags)},
          (@formatTags(@props.conversation.tags))
        ),
        React.createElement("div", {"className": 'clr'})
      ),
      (
        if @state.opened
          React.createElement("table", {"className": (styles.body)},
            React.createElement("tbody", null,
              React.createElement("tr", null,
                (if @props.onTop
                  React.createElement("td", {"className": (styles.scroll_column)},
                    React.createElement("span", { \
                      "id": "scrollOpenedToTop-#{@props.conversation.id}",  \
                      "style": (top: @scrollTop),  \
                      "onClick": (@onTopClick),  \
                      "className": (classnames(styles.scroll_item, styles.scroll_top,
                        "#{styles.visibility_hidden}": @scrollTop == 0
                      ))
                    }),
                    React.createElement("span", { \
                      "id": "scrollOpenedToBottom-#{@props.conversation.id}",  \
                      "style": (bottom: @scrollBottom),  \
                      "onClick": (@onBottomClick),  \
                      "className": (classnames(styles.scroll_item, styles.scroll_bottom,
                        "#{styles.visibility_hidden}": @scrollBottom == 0
                      ))
                    })
                  )
                ),
                React.createElement("td", {"className": (styles.messages_column)},
                  React.createElement(ConversationItemsListComponent, Object.assign({ \
                    "conversation": (@props.conversation)
                    }, @props.itemsListProps, { \
                    "rankedConversation": (@props.rankedConversation),  \
                    "scrollTop": (=> @props.onTop @refs.root if @props.opened && @props.onTop),  \
                    "items": (@props.items),  \
                    "loading": (@props.loading),  \
                    "error": (@props.error)
                  }))
                )
              )
            )
          )
      )
    )

module.exports = PreviousConversationComponent
