BaseComponent = require '../../BaseComponent'
moment = require 'moment'

TakeNewButtonComponent = require './TakeNewButtonComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AnomalyActionCreator = require 'actions/AnomalyActionCreator'
AuthStore = require 'stores/AuthStore'
SettingsStore = require 'stores/SettingsStore'
ConversationsUnassignedStore = require 'stores/ConversationsUnassignedStore'
{
  TAKE_NEW_BUTTON_ACCESS,
  TAKE_NEW_LEAD_FILTER
} = require '../../../constants/Agents'
DEFAULT_FILTER_TIME = 2
DEFAULT_UC_TIME = 5

styles = require './take-new-conversation'

conversationsActionCreator = new ConversationsActionCreator()

class TakeNewConversationComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      shift:
        average_response_time: 0
        unique_clients_count: 0
      unassignedCount: 0
    @initComponent()

  dependsOnStores: [ConversationsUnassignedStore, AuthStore, SettingsStore]

  getState: ->
    mustHave: @calculateMustHave()
    activeConvLimit: SettingsStore.values.active_conv_limit || 5
    shift: AuthStore.user?.last_shift
    unassignedCount: @unassignedCount()

  unassignedCount: =>
    filterTime = (SettingsStore.values.lead_filter_time || DEFAULT_FILTER_TIME) * 60 * 1000
    if SettingsStore.values.lead_filter_time == 0
      filterTime = 0
    conversations = ConversationsUnassignedStore.activeConversations
    if TAKE_NEW_LEAD_FILTER[AuthStore.user.role]
      conversations = conversations.filter (c) ->
        if !c.customer
          false
        else
          wt = 0
          if c.awaiting_from
            wt = new Date() - Date.parse(c.awaiting_from)
          wt > filterTime || !c.customer.lead
      conversations = conversations.filter (c) ->
        if c.awaiting_from
          wt = new Date() - Date.parse(c.awaiting_from)
          waiting_seconds = AuthStore.user.last_shift?.waiting_seconds
          if waiting_seconds == undefined
            waiting_seconds = DEFAULT_UC_TIME
          wt > waiting_seconds * 1000
        else
          true
    if AuthStore.user.role == 'sales'
      conversations = conversations.filter (c) ->
        if c.customer
          c.customer.lead
        else
          false
    conversations.filter((c) -> !c.reserve && c.status != 'dormant').length

  calculateMustHave: =>
    if AuthStore.user
      AuthStore.user.is_take_new_musthave
    else
      true

  isBusy: =>
    new_count = AuthStore.user.new_conversations_count
    open_count = AuthStore.user.open_conversations_count
    activeConv = new_count + open_count
    if AuthStore.user.role == 'sales'
      return !@isGreen()
    activeConv >= @state.activeConvLimit

  isGreen: =>
    if AuthStore.user.role == 'sales'
      conversations = ConversationsUnassignedStore.activeConversations
      lead_conversations_count = conversations
        .filter (c) ->
          if c.customer
            c.customer.lead
          else
            false
        .length
      if lead_conversations_count > 0
        return true
    false

  onTakeNew: =>
    isBusy = @isBusy()
    AnomalyActionCreator.takeNewClick()
    if !isBusy && @state.unassignedCount > 0
      conversationsActionCreator.assignMostWaiting()

  render: ->
    averageRT = @state.shift?.average_response_time || 0
    rt = Math.round(parseFloat(averageRT) * 100 / 60) / 100
    uniqClients = @state.shift?.unique_clients_count || 0

    React.createElement("div", null,
      React.createElement("span", {"className": (styles.stats)}, """
        UC: """, React.createElement("strong", null, (uniqClients)),
        React.createElement("span", {"className": (styles.slash)}, " \x2F "), """
        RT: """, React.createElement("strong", null, (rt), " min")
      ),
      React.createElement(TakeNewButtonComponent, { \
        "isBusy": (@isBusy()),  \
        "mustHave": (@state.mustHave),  \
        "isGreen": (@isGreen()),  \
        "unassignedCount": (@state.unassignedCount),  \
        "onClick": (@onTakeNew)
      })
    )

module.exports = TakeNewConversationComponent
