Button = require '../Button'
styles = require './info-modal.styl'

module.exports = (props) ->
  React.createElement("div", {"className": (styles.container)},
    React.createElement("p", {"className": (styles.text)},
       (props.data.text || '')
    ),
    React.createElement(Button, { \
      "title": (props.data.buttonTitle || 'Got it'),  \
      "onClick": (props.onClick),  \
      "className": (styles.button)
    }),
    (props.data.onCancel &&
      React.createElement(Button, { \
        "white": true,  \
        "title": (props.data.cancelButtonTitle || 'Cancel'),  \
        "onClick": (props.data.onCancel),  \
        "className": (styles.button)
      })
    )
  )