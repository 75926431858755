classnames = require 'classnames'
uuid = require 'uuid'
copyToClipboard = require 'clipboard-copy'
moment = require 'moment'
_ = require 'underscore'

styles = require './message-form'
FastClick = require 'fastclick'
MessagesActionCreator = require 'actions/MessagesActionCreator'
AgentsActionCreator = require 'actions/AgentsActionCreator'
ConversationTemplatesActionCreator = require 'actions/ConversationTemplatesActionCreator'
AuthStore = require 'stores/AuthStore'
ActionTypes = require 'constants/ActionTypes'
{ TAG_CHECKING_EXCEPT } = require 'constants/Agents'
RequestUtils = require 'lib/RequestUtils'
Dispatcher = require 'root/dispatcher/Dispatcher'
AssignedAgentsComponent = require '../../messages/AssignedAgentsComponent/AssignedAgentsComponent'
BaseComponent = require 'components/BaseComponent'
ChannelsComponent = require '../ChannelsComponent/ChannelsComponent'
ReplyTemplateSelectorComponent = require 'components/replyTemplates/ReplyTemplateSelectorComponent/ReplyTemplateSelectorComponent'
ConversationTagsComponent = require '../ConversationTagsComponent'
PromptsStore = require './PromptsStore'
EmojiPickerComponent = require 'components/shared/EmojiPickerComponent'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
InfoModal = require 'components/shared/InfoModal'
confirm = require 'components/shared/ConfirmDialog'
FileUploaderComponent = require 'components/shared/FileUploaderComponent'
DownloadAllButtonComponent = require 'components/conversations/DownloadAllButtonComponent'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
Tooltip = require 'components/shared/Tooltip'
AuthStore = require 'stores/AuthStore'
crminfo = require '@verdaccio/crminfo'
{ ReplyTemplateShortcuts } = crminfo.controls

propTypes = require 'prop-types'

MessagesActionCreator = new MessagesActionCreator()

STATUSES =
  HIDE: 'hide'
  SHOW: 'show'


STATUSES._CHANGES = {
  [STATUSES.HIDE]: 'show',
  [STATUSES.SHOW]: 'hide',
}

MAX_CHARACTERS =
  'facebook': 2000
  'phone': 160
  'custom': 1000
  'test': 1000
  'default': 1000
  'crm_id': 1000

class MessageFormComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    recovery = JSON.parse(sessionStorage.getItem('messageRecovery') || '{}')
    @initComponent()

    @state =
      text: recovery[props.conversation.id] || ''
      fileUploading: false
      doResetUploading: false
      tags: []
      clientKey: CRMInfoModuleStore.crmInfo?.clientKey
      orders: CustomerOrdersStore.orders
      isOpenTemplateList: false
      replyStatus: if @checkAutocompleteStatus() then STATUSES.SHOW else AuthStore?.user?.reply_template_status || STATUSES.SHOW
      shortcutsStatus: AuthStore?.user?.shortcuts || STATUSES.SHOW

    @debounceChange ||= _.debounce(@setOpenTemplate, 2000)

  @propTypes:
    conversation: propTypes.object.isRequired
    last_channel: propTypes.object
    sendMessage: propTypes.func.isRequired
    unassignAgent: propTypes.func
    agents: propTypes.array

  @defaultProps:
    conversation: {}

  dependsOnStores: [PromptsStore]

  checkAutocompleteStatus: =>
    role = AuthStore?.user?.role
    DATA = '2021-06-03';
    DATA_BEFORE = moment(DATA).format 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
    isBefore = moment().isBefore DATA_BEFORE
    ROLES = ['sales', 'newbie_agent', 'agent']

    return isBefore && ROLES.indexOf(role) > -1

  getServiceName: => @state.channel?.service_name || ''

  componentDidMount: ->
    super()
    FastClick.attach ReactDOM.findDOMNode @refs.container
    @props.getTextPasteFunc(@setTemplate)

  componentDidUpdate: =>
    @refs.input.focus() if @state.focus

  getState: =>
    channel: @props.conversation?.last_message?.channel || @props.last_channel
    text: PromptsStore.text
    focus: PromptsStore.focus
    tags: @props.conversation.tags || []

  componentWillReceiveProps: (nextProps) ->
    recovery = JSON.parse(sessionStorage.getItem('messageRecovery') || '{}')

    if @props.conversation?.id != nextProps.conversation?.id
      @setState
        isOpenTemplateList: false

    @setState
      channel: nextProps.conversation?.last_message?.channel || nextProps.last_channel
      text: recovery[nextProps.conversation.id] || ''
      clientKey: CRMInfoModuleStore.crmInfo?.clientKey
      orders: CRMInfoModuleStore.orders

  handleBlur: =>
    @setState focus: false

  componentWillUnmount: ->
    setTimeout(
      ->
        ConversationTemplatesActionCreator.clearTemplate()
      0
    )

  onSaveStatusShortcuts: () =>
    { id } = AuthStore.user
    status = STATUSES._CHANGES[@state.shortcutsStatus];
    data = { shortcuts: status }
    setTimeout(
      ->
        AgentsActionCreator.updateStatusReply(id, data)
      0
    )
    @setState shortcutsStatus: status

  onSaveStatusAutocomplete: () =>
    { id } = AuthStore.user
    { text, replyStatus } = @state
    status = STATUSES._CHANGES[@state.replyStatus];
    data = { reply_template_status: status }
    setTimeout(
      ->
        AgentsActionCreator.updateStatusReply(id, data)
      0
    )

    if text.length > 2 && text.length <= 40
      if status == STATUSES.SHOW
        @setState isOpenTemplateList: true
      else
        @setState isOpenTemplateList: false

    @setState
      replyStatus: status

  saveText: (text) =>
    if text
      @setRecovery @props.conversation.id, text
    else
      @deleteRecovery @props.conversation.id

  setOpenTemplate: =>
    if @state.replyStatus == STATUSES.SHOW
      @setState isOpenTemplateList: true
    else
      @setState isOpenTemplateList: false

  handleInput: (e) =>
    text = e.target.value
    if text.length > 2 && text.length <= 40
      if @state.replyStatus == STATUSES.SHOW then @debounceChange()
    else
      @setState focus: true
      @setState isOpenTemplateList: false

    if @refs.input
      @refs.input.focus()

    @saveText(e.target.value)
    @setState text: e.target.value

  isImage: (url, callback, timeout) =>
    timeout = timeout || 5000
    timedOut = false || timer
    img = new Image()
    img.onerror = img.onabort = ()=>
      if (!timedOut)
        clearTimeout(timer)
        alert 'No such sticker.' # error
    img.onload = ()=>
      if (!timedOut)
        clearTimeout(timer)
        callback(url) # success
    img.src = url
    timer = setTimeout(()=>
      timedOut = true
      alert 'Timeout error. Sticker image can\'t be loaded.' # timeout
    , timeout)

  commandHandling: (text) =>
    links = text.match(/(https?:\/\/[^\s]+)/g)
    isValidLink = true

    if links
      isValidLink = @checkPersonalLinks(links)

    switch true
      when /^\/st\s/i.test(text) # /st - sending stickers
        sticker_name = text.replace('/st ', '').replace(' ', '_')
        sticker_link = "https://gonerdify.com/static/stikers/#{sticker_name}.png"
        @isImage(sticker_link, @send)
      when !isValidLink then @onSendLink(text)
      else
        @send(text)

  submitForm: (e) =>
    e.preventDefault()
    if @state.text && !(@state.text.length == 0 || !@state.text.trim())
      txt = @commandHandling(@state.text)

  sendMessage: (txt) =>
    @props.sendMessage({
        conversationId: @props.conversation.id,
        text: txt,
        channelId: @state.channel?.id
        prompt_id: PromptsStore.promptId
      },
      ->
        PromptsStore.promptId = null
    )

  send: (txt) =>
    @sendMessage(txt)

    @deleteRecovery @props.conversation.id
    @setState text: ''
    @setState isOpenTemplateList: false

  sendFiles: =>
    _.values(@state.filesObject).filter((file) -> file.url).forEach (file) =>
      if @props.conversation.id == file.conversationId
        @props.sendMessage
          conversationId: file.conversationId,
          text: file.url,
          channelId: @state.channel?.id
          agent: AuthStore.user

    @setState doResetUploading: true

  setRecovery: (key, text) ->
    recovery = JSON.parse(sessionStorage.getItem('messageRecovery') || '{}')
    recovery[key] = text
    sessionStorage.setItem 'messageRecovery', JSON.stringify recovery

  deleteRecovery: (key) ->
    recovery = JSON.parse(sessionStorage.getItem('messageRecovery') || '{}')
    delete recovery[key]
    sessionStorage.setItem 'messageRecovery', JSON.stringify recovery

  onKeyDown: (e) =>
    if e.ctrlKey && e.keyCode == 13
      @submitForm e

  setChannel: (channel) =>
    @setState channel: channel

  setTemplate: (replyTemplate) =>
    @saveText(replyTemplate)
    @setState text: replyTemplate
    @setState isOpenTemplateList: false
    if @refs.input
      @refs.input.focus()
    else
      copyToClipboard replyTemplate
      InterfaceActionCreator.openModal InfoModal, {
        text: 'Active conversation is not assigned to you. Text has been saved to the clipboard.'
      }

  insertEmoji: (code) =>
    newText = (@state.text || '') + code
    @setRecovery @props.conversation.id, newText
    @setState text: newText

  beginUploading: =>
    @setState
      fileUploading: true
      readyForSendFile: false

  resetUploading: =>
    newState = @state
    newState.fileUploading = false
    newState.readyForSendFile = false
    newState.doResetUploading = false
    @setState newState

  readyForSendFile: (filesObject) =>
    @setState
      readyForSendFile: true
      filesObject: filesObject

  isValidFiles: =>
    !!_.values(@state.filesObject || {}).filter((file) -> file.url).length

  checkPersonalLinks: (links) =>
    isValidLink = true
    clientKey = @state.clientKey
    orders = @state.orders

    links.map (l) ->
      match = l.match(/\/authkey\/([\w\-\.]+[^#?\W]+)/) || l.match(/\/r\/([\w\-\.]+[^#?\W]+)/) || l.match(/\/a\/([\w\-\.]+[^#?\W]+)/)
      matchPayment = l.match(/\/p\/([\w\-\.]+[^#?\W]+)/)

      if (!orders || !orders.length) && isValidLink && matchPayment
        isValidLink = false

      if orders && orders.length > 0 && matchPayment && isValidLink
        isValidLink = !orders.every((o) => matchPayment[1] != 'i' + o.key)

      if match && isValidLink
        isValidLink = match[1] == clientKey

    isValidLink

  onSendLink: (text) =>
    confirm(
      'Link doesn’t match this client’s generated links.  Send it anyway?'
      confirmTitle: 'Yes'
    ).then(
      => @send(text)
      ->
    )

  renderCounter: =>
    maxCharacters = MAX_CHARACTERS[@state.channel.channel_type || 'default']
    maxCharacters = MAX_CHARACTERS['default'] unless maxCharacters
    len = @state.text.length
    mod = len % maxCharacters
    inc = if mod == 0 then 0 else 1
    "#{maxCharacters - mod}/#{(len - mod) / maxCharacters + inc}"

  getGroupTypeClassname: =>
    { conversation } = @props
    "group_type_#{conversation.groups[0]['group_type']}" if conversation.customer?.lead? && conversation.groups?[0]

  renderIconAutoComplete: =>
    isDisabled = @checkAutocompleteStatus()
    if isDisabled
      React.createElement("i", {"className": (classnames(styles.status_autocomplete, styles.disabled))})
    else
      React.createElement("i", { \
        "className": (classnames(styles.status_autocomplete, {[styles[@state.replyStatus]]: @state.replyStatus})),  \
        "onClick": (@onSaveStatusAutocomplete)
      })

  render: ->
    status = STATUSES._CHANGES[@state.shortcutsStatus];
    React.createElement("div", {"className": (styles.container), "ref": "container"},
      React.createElement("div", {"className": (styles.container_shortcuts)},
        React.createElement(ReplyTemplateShortcuts, { \
          "onReplyTemplatePick": (@sendMessage),  \
          "showShortcuts": (@state.shortcutsStatus),  \
          "onClick": (@onSaveStatusAutocomplete)
        })
      ),

      React.createElement("textarea", { \
        "ref": "input",  \
        "id": 'messageinput',  \
        "value": (@state.text),  \
        "onChange": (@handleInput),  \
        "onKeyDown": (@onKeyDown),  \
        "onBlur": (@handleBlur),  \
        "className": (classnames styles.text_area,
          "#{styles.visibility_hidden}": @state.fileUploading
        ),  \
        "placeholder": "Enter message..."
      }),
      React.createElement("div", {"className": (styles.status_shortcuts_wrap)},
        React.createElement(Tooltip, {"content": ("#{status} shortcuts"), "positionRight": true},
          React.createElement("i", { \
            "className": (classnames(styles.status_shortcuts, {[styles[@state.shortcutsStatus]]: @state.shortcutsStatus})),  \
            "onClick": (@onSaveStatusShortcuts)
          })
        )
      ),

      (@renderIconAutoComplete()),

      (if @state.channel
        React.createElement("span", {"className": (styles.counter)}, (@renderCounter()))
      ),
      React.createElement("div", {"className": (styles.bottom_group)},
        React.createElement("div", {"className": styles.actions_left},
          React.createElement(FileUploaderComponent, { \
            "key": (@state.rebootFileUploader),  \
            "conversationId": (@props.conversation.id),  \
            "beginUploading": (@beginUploading),  \
            "resetUploading": (@resetUploading),  \
            "readyForSend": (@readyForSendFile),  \
            "doResetUploading": (@state.doResetUploading)
          }),

          (unless @state.fileUploading
            React.createElement("span", null,
              React.createElement("span", {"className": (styles.actions_left_container)},
                React.createElement(ReplyTemplateSelectorComponent, { \
                  "onPick": (@setTemplate),  \
                  "searchQuery": (@state.text),  \
                  "templateType": ('message'),  \
                  "isOpenTemplateList": (@state.isOpenTemplateList)
                })
              ),
              React.createElement("span", {"className": (styles.actions_left_container)},
                React.createElement(ConversationTagsComponent, { \
                  "conversationId": (@props.conversation.id),  \
                  "withTooltip": (!TAG_CHECKING_EXCEPT[AuthStore.user.role] && !(@props.conversation.tags || []).length)
                })
              ),
              React.createElement("span", {"className": (styles.actions_left_container)},
                React.createElement(EmojiPickerComponent, {"onSelectEmoji": (@insertEmoji)})
              ),
              React.createElement("span", {"className": (styles.actions_left_container)},
                React.createElement(DownloadAllButtonComponent, { \
                  "conversationId": (@props.conversation.id)
                })
              ),
              React.createElement("span", {"className": (classnames(
                styles.actions_left_container
                styles.actions_left_container_assigners
              ))},
                React.createElement(AssignedAgentsComponent, { \
                  "unassignAgent": (@props.unassignAgent),  \
                  "agents": (@props.agents)
                })
              )
            )
          )
        ),
        (if !@state.fileUploading || (@state.readyForSendFile && @isValidFiles())
          React.createElement("div", {"className": styles.actions_right},
            React.createElement(ChannelsComponent, { \
              "customerId": (@props.customerId),  \
              "value": (@state.channel || {name: 'Select channel ...'}),  \
              "onChange": (@setChannel),  \
              "className": (@getGroupTypeClassname())
            }),
            React.createElement("button", { \
              "onClick": (if @state.readyForSendFile then @sendFiles else @submitForm),  \
              "className": (styles.submit)
            }, """
              Send
""")
          )
        )
      )
    )

module.exports = MessageFormComponent
