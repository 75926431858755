ConversationsStore = require './abstract/ConversationsStore'
ActionTypes = require '../constants/ActionTypes'

class ConversationsUnassignedStore extends ConversationsStore
  constructor: ->
    super()
    @registerActions()

  getActions: =>
    "#{ActionTypes.CONVERSATIONS_SET_DEFAULT_UNASSIGNED_STATE}": @onSetDefaultState
    "#{ActionTypes.CONVERSATIONS_GET_LIST_REQUESTING}": @onSearchInit
    "#{ActionTypes.CONVERSATIONS_GET_LIST_SUCCESS}": @onSearchSuccess
    "#{ActionTypes.CONVERSATIONS_GET_LIST_FAILURE}": @onSearchError
    "#{ActionTypes.CONVERSATIONS_SEARCH_RESET}": @onSearchReset
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_REQUEST}": @onConversationsRequest
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING_INITIAL}": @onConversationsRequest
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING}": @onConversationsUpdating

module.exports = new ConversationsUnassignedStore()
