BaseComponent = require 'components/BaseComponent'
CustomerInfoModule = require '../CustomerInfoModule'
CRMInfoModule = require '../CRMInfoModule'
RankingModule = require 'components/ranking/RankingModule'
MessagesRankingModule = require 'components/ranking/MessagesRankingModule'
CustomersStore = require 'stores/CustomersStore'
CustomersActionCreator = require 'actions/CustomersActionCreator'
styles = require './base-info'

propTypes = require 'prop-types'

moduleMap = 
  'client-info': CustomerInfoModule
  'crm-info': CRMInfoModule
  'qa-info': RankingModule
  'message-info': MessagesRankingModule

class BaseInfoComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      blocksState: {}
      customer: null
      loading: false
    }
    @initComponent()

  dependsOnStores: [CustomersStore]

  componentDidMount: ->
    super()
    @customerDownloadingId = setTimeout(
      CustomersActionCreator.get
      30
      @props.customerId
    )

  componentWillReceiveProps: (props) ->
    if @props.customerId != props.customerId &&
       props.customerId != CustomersStore.currentCustomerId ||
       @props.conversation.id != props.conversation.id
      clearTimeout @customerDownloadingId
      @customerDownloadingId = setTimeout(
        CustomersActionCreator.get
        30
        props.customerId
      )

  getState: ->
    {
      customer: CustomersStore.customer
      loading: CustomersStore.customerLoading
    }

  onToggleBlock: (blockName) =>
    { blocksState } = @state
    blocksState[blockName] = !blocksState[blockName]
    isAllCollapsed = true
    isAllCollapsed = !Object.keys(blocksState).some((key) -> blocksState[key])

    @props.onAllCollapse() if isAllCollapsed
    @setState { blocksState } unless isAllCollapsed

  setTabsState: (state) =>
    { blocksState } = @state
    blocksState = Object.assign blocksState, state
    @setState blocksState

  render: ->
    React.createElement("div", {"className": (styles.container)},
      (Object.keys @props.customerModules
        .map (key, i) =>
          return unless @props.customerModules[key] 
          Component = moduleMap[key]
          React.createElement(Component, {  \
            "key": (i),  \
            "onToggleBlock": (@onToggleBlock),  \
            "anyOpen": (if i == 0 then !!@props.anyOpen else false),  \
            "customerId": (@props.customerId),  \
            "setTabsState": (@setTabsState),  \
            "customer": (@state.customer),  \
            "loading": (@state.loading),  \
            "customerLink": (@props.conversation.id if @props.customerLink),  \
            "conversation": (@props.conversation)
          })
      )
    )

BaseInfoComponent.propTypes = 
  customerId: propTypes.number.isRequired
  customerModules: propTypes.objectOf(propTypes.bool).isRequired
  anyOpen: propTypes.bool
  onAllCollapse: propTypes.func
  conversation: propTypes.object

module.exports = BaseInfoComponent
