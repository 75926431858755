BaseComponent               = require 'components/BaseComponent'
crminfo                     = require '@verdaccio/crminfo'
styles                      = require './tab-options'
sitesConstants              = require 'constants/Sites'

OrderActionListComponent    = require './OrderActionListComponent'
CRMInfoModuleStore          = require 'stores/CRMInfoModuleStore'
ADDITIONAL_ORDER_MENU       = require 'constants/additionalOrderMenu'

class TabOptionsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      showDropdown: false
    @initComponent()

  handleClickOutside: =>
    @setState showDropdown: !@state.showDropdown

  onItemClick: (type, e) =>
    if type != ADDITIONAL_ORDER_MENU.NEW
      if (type == ADDITIONAL_ORDER_MENU.DETAILS_REQUEST || type == ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST || type == ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN || type == ADDITIONAL_ORDER_MENU.DASHBOARD_TASK) && @props.offer.serviceName == 'Mynerdify'
        typeMessage = ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify
      else typeMessage = @props.offer.typeMessage 

      messages = ADDITIONAL_ORDER_MENU._MESSAGE[type][typeMessage].messages
      replace = ADDITIONAL_ORDER_MENU._MESSAGE[type][typeMessage].templates

      #US, FB, [MyNerdify] — TYPE_1
      #CA и AU — TYPE_2

      newMessages = []

      #[link to cabinet]
      linkTo = CRMInfoModuleStore?.crmInfo.OrderLink
      # values = [linkTo]

      #[file sharing link]
      fileSharingLink = CRMInfoModuleStore?.crmInfo.CustomerSharingLink
      values = [linkTo, fileSharingLink]

      for i in [0...messages.length]
        mes = messages[i]
        if (replace.length > 0)
          for j in [0...values.length]
            v = values[j]
            mes = mes.replace replace[j], v
        newMessages[i] = mes;

    switch type
      when ADDITIONAL_ORDER_MENU.NEW then @props.new.onNewOrder()
      when ADDITIONAL_ORDER_MENU.OFFER_DETAIL then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DETAILS_REQUEST then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DASHBOARD_TASK then @props.offer.onOpenMessageForm(type, newMessages)

  onOpenDropDown: (e) =>
    @setState showDropdown: true

  render: ->
    hasTag = false
    if CRMInfoModuleStore.currentCustomer
      hasTag = CRMInfoModuleStore.currentCustomer.tags.filter((t) -> t.name == 'NO ACCOUNT').length > 0
    hasPaidOrder = !CRMInfoModuleStore.crmInfo?.isLead || false
    isUK = CRMInfoModuleStore.crmInfo?.site_acronym == sitesConstants.TEXT_NERDY_UK
    # canSentMessage = !hasTag && !isUK
    isMynerdify = @props.offer?.serviceName == 'Mynerdify'

    React.createElement("div", {"className": (styles.container)},
      (if @props.all
        React.createElement("span", {"className": (styles.all), "onClick": (@props.all.onClick)},
          React.createElement("img", { \
            "src": (require 'components/shared/images/all.svg'),  \
            "className": (styles.icon)
          }),
          (@props.all.text)
        )
      ),
      (if @props.new
        # <span className={styles.new} onClick={if canSentMessage then @onOpenDropDown else @props.new.onNewOrder}>
        React.createElement("span", {"className": (styles.new), "onClick": (if !isUK then @onOpenDropDown else @props.new.onNewOrder)},
          React.createElement("img", { \
            "src": (require 'components/shared/images/add.svg'),  \
            "className": (styles.icon)
          }),
          (@props.new.text),
          (
            @state.showDropdown &&  
              React.createElement(OrderActionListComponent, { \
                "onItemClick": @onItemClick,  \
                "handleClickOutside": @handleClickOutside,  \
                "hasPaidOrder": hasPaidOrder,  \
                "hasTag": (hasTag),  \
                "isMynerdify": (isMynerdify)
              })
          )
        )
      )
    )

module.exports = TabOptionsComponent
