Highcharts = require 'highcharts'

BaseComponent = require 'components/BaseComponent'
ConversationsDashboardStore = require 'stores/ConversationsDashboardStore'
AgentsDashboardStore = require 'stores/AgentsDashboardStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AgentsDashboardActionCreator = require 'actions/AgentsDashboardActionCreator'
{ buildOptions } = require 'lib/dashboard-utils'
{ ROLES, FOR_DASHBOARD } = require 'constants/Agents'

styles = require '../dashboard.styl'
ConversationsActionCreator = new ConversationsActionCreator()

class ActiveStatusConversationByAgentsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()
  
  dependsOnStores: [ConversationsDashboardStore, AgentsDashboardStore]

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeDashboardConversations()
        AgentsDashboardActionCreator.subscribe()
      0
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.unSubscribeDashboardConversations()
        AgentsDashboardActionCreator.unSubscribe()
      0
    )


  getActiveConversationsInAgentsStatus: (position) =>
    countedNew = {}
    countedOpened = {}
    countedDormant = {}
    agentsList = ConversationsDashboardStore.agents

    ids = Object.keys(agentsList).filter (id) ->
      ROLES[agentsList[id].role].value in FOR_DASHBOARD
    index = Math.trunc(ids.length / 2)
    arrays = [ids.slice(0, index), ids.slice(index)]
    ids = arrays[position]

    xAxis:
      categories: _.map ids, (id) ->
        uc = '-'
        agent = AgentsDashboardStore.agents[parseInt(id)]
        if agent
          uc = agent.last_shift?.unique_clients_count || 0
        agentsList[id].last_name + ' <b>' + uc + '</b>'
    series: [{
        name: 'New'
        data: _.map ids, (id) -> if agentsList[id].new_message then agentsList[id].new_message else null
        color: '#76d466'
    }, {
        name: 'Open'
        data: _.map ids, (id) -> if agentsList[id].opened then agentsList[id].opened else null
        color: '#53a8ff'
    }, {
        name: 'Dormant'
        data: _.map ids, (id) -> if agentsList[id].dormant then agentsList[id].dormant else null
        color: '#ffdc00'
    }]
    chart:
      type: 'column'
    plotOptions:
      column:
        groupPadding: 0
        stacking: 'normal'
        dataLabels:
          enabled: true
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
          style: textShadow: '0 0 3px black'
    title: text: 'Currently  active  status  conversation by agents'
    yTitle: 'Conversations'

  render: ->
    React.createElement("div", {"className": (styles.grid)},
      React.createElement("div", {"className": (styles.full_row_of_grid)},
        React.createElement("div", {"className": (styles.chart_container)},
          React.createElement(ChartComponent, { \
            "container": 'ActiveConversationsInAgentsStatusTop',  \
            "options": (buildOptions @getActiveConversationsInAgentsStatus(0))
          })
        )
      ),
      React.createElement("div", {"className": (styles.full_row_of_grid)},
        React.createElement("div", {"className": (styles.chart_container)},
          React.createElement(ChartComponent, { \
            "container": 'ActiveConversationsInAgentsStatusBottom',  \
            "options": (buildOptions @getActiveConversationsInAgentsStatus(1))
          })
        )
      )
    )

module.exports = ActiveStatusConversationByAgentsComponent
