BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
deepcopy = require 'deepcopy'

class ConversationsDashboardStore extends BaseStore
  constructor: ->
    super()
    @conversations = []
    @conversationsHash = {}
    @agents = {}
    @state =
      errors: null
      loading: false
    @registerActions()

  onConversationsRequest: () ->
    @state.loading = true
    @conversationsHash = {}
    @conversations = []
    @emitChange()

  onConversationsUpdating: (payload) ->
    Object.keys(payload.message).forEach (id) =>
      if payload.message[id]
        @conversationsHash[id] ||= {}
        Object.keys(payload.message[id]).forEach (key) =>
          @conversationsHash[id][key] = payload.message[id][key]
      else
        delete @conversationsHash[id]
    @conversations = Object.keys(@conversationsHash).map (key) =>
      @conversationsHash[key]
    agents = {}
    _.each @conversations, (conversation) =>
      _.each conversation.agents, (agent) =>
        agents[agent.id] ||= deepcopy(agent)
        agents[agent.id][conversation.status] ||= 0
        agents[agent.id][conversation.status] += 1
    @agents = agents
    @state.loading = false if payload.initial
    @emitChange()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_DASHBOARD_CONVERSATIONS_REQUEST
        @onConversationsRequest()
      when ActionTypes.MQTT_DASHBOARD_CONVERSATIONS_UPDATING_INITIAL
        @onConversationsRequest()
      when ActionTypes.MQTT_DASHBOARD_CONVERSATIONS_UPDATING
        @onConversationsUpdating(payload)

module.exports = new ConversationsDashboardStore()
