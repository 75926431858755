BaseComponent = require 'components/BaseComponent'
AuthStore = require 'stores/AuthStore'
DormantModalComponent = require '../DormantModalComponent'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
TakeNewModalComponent = require '../TakeNewConversationComponent/TakeNewModalComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'

ConversationsActionCreator = new ConversationsActionCreator()
propTypes = require 'prop-types'

class ConversationsComponent extends BaseComponent
  @propTypes:
    pageSize: propTypes.number.isRequired

  constructor: (props) ->
    super(props)
    @search = {}
    @currentDormantConversation = {}
    @previousReservedChat = {}
    @currentReservedChat = {}

  searchPageSize: => @search.pageSize

  handleUpdatedConversations: (conversations) =>
    newDormant = null
    newReserved = null
    currentDormantExists = false
    currentReservedExists = false
    if conversations.length > 0
      conversations.forEach (c) =>
        currentDormantExists = true if c.id == @currentDormantConversation.id
        currentReservedExists = true if c.id == @currentReservedChat.id
        newDormant = @checkDormantConversation(c) unless newDormant
        newReserved = @checkReservedConversation(c) unless newReserved
        newReserved = null if newReserved && !newReserved.customer
    else
      @currentDormantConversation = {}

    @currentDormantConversation = {} unless currentDormantExists
    unless currentReservedExists
      @previousReservedChat = @currentReservedChat
      @currentReservedChat = {}

    # handle dormant conversations
    if @currentDormantConversation.id
      @setPopupForDormant @currentDormantConversation
    else if !@currentDormantConversation.id && newDormant
      @currentDormantConversation = newDormant
      @setPopupForDormant newDormant

    # handle reserved conversations
    if !@currentReservedChat.reserve && !newReserved
      setTimeout(
        ->
          InterfaceActionCreator.closeModal('reserve')
        10
      )
      @previousReservedChat = @currentReservedChat
    else if !@currentReservedChat.reserve && newReserved
      @previousReservedChat = @currentReservedChat
      @currentReservedChat = newReserved
      InterfaceActionCreator.openModal(TakeNewModalComponent, {
        bonusLink: "/agents/#{AuthStore.user.id}/shifts"
        customer: newReserved.customer.name
        reserve_type: newReserved.reserve_type
        acceptChat: ConversationsActionCreator.assignToAgent.bind(
          ConversationsActionCreator
          [newReserved.id]
          [AuthStore.user.id]
        )
        rejectChat: ConversationsActionCreator.rejectReservation.bind(
          ConversationsActionCreator
          newReserved.id
        )
        type: 'reserve'
      })
    else if @previousReservedChat.id != @currentReservedChat.id
      InterfaceActionCreator.openModal(TakeNewModalComponent, {
        bonusLink: "/agents/#{AuthStore.user.id}/shifts"
        customer: @currentReservedChat.customer.name
        reserve_type: @currentReservedChat.reserve_type
        acceptChat: ConversationsActionCreator.assignToAgent.bind(
          ConversationsActionCreator
          [@currentReservedChat.id]
          [AuthStore.user.id]
        )
        rejectChat: ConversationsActionCreator.rejectReservation.bind(
          ConversationsActionCreator
          @currentReservedChat.id
        )
        type: 'reserve'
      })

  checkReservedConversation: (conversation) =>
    { reserve } = conversation
    if conversation.id == @currentReservedChat.id
      @currentReservedChat = conversation

    if reserve == AuthStore.user.id && @currentReservedChat.id != conversation.id
      conversation

  checkDormantConversation: (conversation) =>
    if conversation.id == @currentDormantConversation.id
      @currentDormantConversation = if conversation.remember
        conversation
      else
        {}

    isAgentAssigned = conversation.agents.some((a) -> a.id == AuthStore.user.id)
    if conversation.remember && isAgentAssigned && @currentDormantConversation.id != conversation.id
      conversation

  setPopupForDormant: (conversation) =>
    @currentDormantConversation = conversation
    InterfaceActionCreator.openPopup(DormantModalComponent, {
      conversation: conversation
      onChange: @onPopupSubmit
    })

module.exports = ConversationsComponent
