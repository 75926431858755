export default {
  // host for API server
  apiHost: 'https://operator.tn-company.com',
  apiHostTickets: 'https://tickettool-stage.dsh-agency.com',

  // version of used API
  apiVersionPartV1: '/api/v1',
  apiVersionPart: '/api/v3',

  writerToolSite: 'https://myaccount-stage.lrwriters.com',
  graderToolSite: 'https://gradertool-stage.lrwriters.com',

  apiHostAuthBack: 'https://auth-back-stage.dsh-agency.com',

  a4Host: 'https://operator.tn-company.com',
  a5Host: 'https://a5-stage.dsh-agency.com',

  mynerdifyLink: 'http://mynerdify-stage.dsh-agency.com',
  //  geeklyLabSite: 'http://geeklylab-stage.dsh-agency.com',
  //  geeklyHubSite: 'http://geeklyhub-stage.dsh-agency.com',
  geeklyLabSite: 'https://stage.geeklylab.com',
  geeklyHubSite: 'https://stage.geeklyhub.net',

  homeworkLabSite: 'http://homeworklab-stage.dsh-agency.com',

  fileServerUrl: 'https://ue551dknff.execute-api.eu-west-1.amazonaws.com/dev',

  apiHostSocket: 'wss://mqtt-stage.dsh-agency.com:8084/mqtt',
  clientChatUrl: 'https://xmpp-bot-stage.dsh-agency.com/get_order_chat_items',

  apiHostSmsTool: 'https://connect-stage.dsh-agency.com',
  apiVersionSmsTool: '/api',

  OCRProxy: 'https://api-stage.tn-company.com/h2c',
  OCRResults: 'https://api-stage.tn-company.com/ocrspace/parse/image',

  isSmsTool: true,
};
