moment = require 'moment'

styles = require './log-message.styl'

# IMPORTANT: this component uses base.styl hardly!
# div classes come from the server
# look for .logMessage class

class LogMessageComponent extends React.Component
  @propTypes:
    logMessage: require('prop-types').object.isRequired

  render: ->
    React.createElement("div", {"className": styles.root},
      React.createElement("span", {"className": styles.time}, ( moment(@props.logMessage.created_at).format('HH:mm, ') )),
      React.createElement("span", null, ( moment(@props.logMessage.created_at).format('DD MMM YY') )),
      React.createElement("span", null, " - "),
      React.createElement("span", {"className": styles.text, "dangerouslySetInnerHTML": ({__html: @props.logMessage.text})})
    )

module.exports = LogMessageComponent
