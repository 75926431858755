CustomerInfoComponent = require './CustomerInfoComponent'
InfoBlockComponent = require 'components/shared/InfoBlockComponent'
BaseComponent = require 'components/BaseComponent'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'

propTypes = require 'prop-types'

class CustomerInfoModule extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [CRMInfoModuleStore]

  getState: ->
    profileLink: CRMInfoModuleStore.profileLink
    externalClientId: CRMInfoModuleStore.externalClientId

  componentDidMount: ->
    super()
    @props.setTabsState
      'client-info': !!@props.anyOpen

  render: ->
    React.createElement(InfoBlockComponent, { \
      "dataKey": 'client-info',  \
      "title": 'Client info',   \
      "openByDefault": (!!@props.anyOpen),  \
      "onToggleBlock": (@props.onToggleBlock),  \
      "icon": (require 'components/shared/images/client.svg')
    },
      React.createElement(CustomerInfoComponent, { \
        "customer": (@props.customer),  \
        "profileLink": (@state.profileLink),  \
        "loading": (@props.loading),  \
        "customerLink": (@props.customerLink),  \
        "externalClientId": (@state.externalClientId),  \
        "entityKey": ("conv-#{@props.conversation.id}"),  \
        "conversation": (@props.conversation)
      })
    )

CustomerInfoModule.propTypes = 
  setTabsState: propTypes.func.isRequired
  onToggleBlock: propTypes.func.isRequired
  customer: propTypes.object
  anyOpen: propTypes.bool
  loading: propTypes.bool

module.exports = CustomerInfoModule
