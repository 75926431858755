Isvg = require 'react-inlinesvg'
Button = require 'components/shared/Button'
styles = require './confirm-dialog.styl'

propTypes = require 'prop-types'

class ConfirmDialog extends React.Component
  onOverlay: (e) =>
    @props.onDissmis() if e.target.className == styles.overlay

  render: =>
    return null if !@props.show
    (
      React.createElement("div", { \
        "className": (styles.overlay),  \
        "onClick": (@onOverlay)
      },
        React.createElement("div", {"className": (styles.container)},
          React.createElement("div", {"className": (styles.header)},
            React.createElement("span", {"className": (styles.close), "onClick": (@props.onDissmis)},
              React.createElement(Isvg.default, {"src": (require 'components/shared/images/cross.svg')})
            )
          ),
          React.createElement("div", {"className": (styles.body)},
            (@props.confirmationMessage)
          ),
          React.createElement("div", {"className": (styles.footer)},
            React.createElement(Button, { \
              "title": (@props.options.confirmTitle || 'Yes'),  \
              "onClick": (@props.onConfirm),  \
              "className": (styles.button),  \
              "red": true
            }),
            React.createElement(Button, { \
              "title": (@props.options.cancelTitle || 'No'),  \
              "onClick": (@props.onCancel),  \
              "className": (styles.button),  \
              "white": true
            })
          )
        )
      )
    )

ConfirmDialog.defaultProps =
  confirmationMessage: 'Are you sure?'

ConfirmDialog.propTypes =
  show: propTypes.bool
  confirmationMessage: propTypes.string
  onConfirm: propTypes.func
  onCancel: propTypes.func
  onDissmis: propTypes.func
  options: propTypes.object

module.exports = ConfirmDialog
