require './lib/StringExtension'
require './lib/RamdaExtension'

require 'react-widgets/dist/css/react-widgets.css'
momentLocalizer = require 'react-widgets-moment'
moment = require('moment')
require 'intro.js/introjs.css'
require './lib/introjs.css'
require '@verdaccio/crminfo/dist/style.css'
require '@verdaccio/crminfo/dist/reach-editable-style.css'
crminfo = require('@verdaccio/crminfo')
config = require './config'
Bugsnag = require('@bugsnag/js')

crminfo.init(config.crminfo)

{ hotjar } = require 'react-hotjar'

momentLocalizer()

require('prop-types').resetWarningCache()

Bugsnag.start
  apiKey: 'a98d10a22264c3d856a24b7405f07bf8'
  autoDetectErrors: false
  enabledBreadcrumbTypes: []

Router = require './router'

jQuery.support.cors = true
window.ReactDOM = ReactDOM
window.R = R

if config.hotjarId && config.hotjarSv
  hotjar.initialize(config.hotjarId, config.hotjarSv)

infinityPing = require './infinity-ping.coffee'
infinityPing()

infinitySync = require './infinity-sync.coffee'
infinitySync()

ReactDOM.render Router, document.getElementById('root')
