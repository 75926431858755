crminfo = require('./crminfo.staging').default

defaultConfig = require('./default-config')({
  domain: 'reconnect.dsh-agency.com'
  protocol: 'https'
  bugsnag: true
  updateNotification: true
  crmUrl: 'https://a4.dsh-agency.com/api/v1/crminfo'
  crmUrlA5: 'https://api-stage.tn-company.com/api/v3'
  GMTOffset: '+0300'
  crmPluginEnabled: true
  oauthEnabled: false
  authApiHost: 'https://oauth-stage.dsh-agency.com'
  authBackApiHost: 'https://auth-back-stage.dsh-agency.com'
  fileServerUrl: 'https://ue551dknff.execute-api.eu-west-1.amazonaws.com/dev'
  mqttUrl: 'wss://reconnect.dsh-agency.com:8084/mqtt'
  introEnabled: true
  crminfo: crminfo
  dashboardTags: false
})

module.exports = _.extend defaultConfig, {}
