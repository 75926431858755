moment = require 'moment'
classnames = require 'classnames'
crminfo = require '@verdaccio/crminfo'
uuid = require 'uuid'

styles = require './message'
SearchUtils = require 'lib/SearchUtils'
TextWithLinks = require 'components/shared/TextWithLinks/TextWithLinks'
FilePreviewComponent = require('components/shared/FilePreviewComponent').default
msc = require 'constants/MessageSend'
{ agentStatus } = require 'lib/utils'
MultilineText = require 'components/shared/MultilineText/MultilineText'
UrlExtractor = require 'lib/UrlExtractor'
baseConstants = require 'constants/base'
PromptsComponent = require './PromptsComponent/PromptsComponent'
PROMPT_STATUSES = require 'constants/PromptStatuses'
conversationStatuses = require 'constants/ConversationStatuses'
RankingActionCreator = require 'actions/RankingActionCreator'
ImagePreviewActionCreator = require 'actions/ImagePreviewActionCreator'
ImagePreviewStore = require 'stores/ImagePreviewStore'
BaseComponent = require 'components/BaseComponent'
CustomerCardCredentialFormComponent = require 'components/shared/CustomerCardCredentialFormComponent'
CustomerCardCredentialsFormByCustomerStore = require 'components/customers/CustomerCardComponent/CustomerCardCredentialsFormComponent/CustomerCardCredentialsFormByCustomerStore'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
ModalPreviewComponent = require 'components/shared/ModalPreviewComponent'
_ = require 'underscore'
{ HOVER_INFO_FAILED } = require 'constants/Agents'

{ urls } = require 'root/config'
Tooltip = require 'components/shared/Tooltip'

propTypes = require 'prop-types'

USER_TYPE_BOT = "bot"
FAILED_MESSAGE_STATUS = 4
AUTO_MESSAGE_STATUS = 13
OPTOUT_MESSAGE_STATUS = 11
FB_TAG_MESSAGE_STATUS = 15
TIMEOUT_STATUS = 16
APPBLOCK_MESSAGE_STATUS = 14
DUPLICATE_MESSAGE_STATUS = 12
DEFAULT_HIGHLIGHT = '#a9b5c9'
BASE64_TYPE = 'base64'

SHORT_INFO_FAILED = {
  'twilio': (failed_info) =>
    "Error code: [#{failed_info.info?['code']}]"
  'bandwidth': (failed_info) =>
    "Error code: [#{failed_info.info?['Code']}]"
}

isStickerPart = (part) ->
  part.type == UrlExtractor.PART_TYPE_URL && (part.content.match(baseConstants.STICKER_REGEXP))

isPicturePart = (part) ->
  part.type == UrlExtractor.PART_TYPE_URL &&
    (part.content.startsWith("https://api.twilio.com/2010-04-01/Accounts/") ||
    part.content.match(baseConstants.ANY_PICTURE_URL_REGEXP))

isFilePart = (part) ->
  try
    new URL(part.content)
    (part.type == UrlExtractor.PART_TYPE_URL && new URL(part.content).host.includes('.s3')) ||
    (part.type == UrlExtractor.PART_TYPE_URL && new URL(part.content).host.includes('s3.')) ||
    isPicturePart(part) || part.content.match(baseConstants.ANY_FILE_URL_REGEXP)
  catch err
    return false

isTextPart = (part) ->
  part.type == UrlExtractor.PART_TYPE_TEXT

isUrlPart = (part) ->
  part.type == UrlExtractor.PART_TYPE_URL

isBandwidthUrlPart = (part) ->
  part.type == UrlExtractor.PART_TYPE_URL && part.content.includes(baseConstants.BANDWIDTH_URL)

buildTextBlock = (textParts, index) ->
  { status } = @props.message
  { isRankSelectedMessage, rankedBy, isClickableForRank, tagged, skipped, tagName, tagGroup } = @props.rankingOptions
  messageId = @props.message.id
  React.createElement("div", {"className": (classnames(styles.wrap,
      "#{styles.wrap_estimate}": isRankSelectedMessage
      "#{styles.wrap_failed}": isFalied(@props.message)
      "#{styles.wrap_tagged}": tagged
      "#{styles.wrap_skipped}": skipped
    )), "ref": (if isRankSelectedMessage then "activeMessage" else 'message'), "key": (index)
  },
    (if isClickableForRank
      React.createElement("span", {"className": (styles['wrap__ranking-hover'])})
    ),
    (if rankedBy
      React.createElement("div", {"className": (styles.rank_blame)}, """
        Ranked by
""", React.createElement("b", null, (rankedBy))
      )
    ),
    (if tagged
      React.createElement("div", {"className": (styles.tagged_info)},
        (tagGroup), " -\x3E ", (tagName)
      )
    ),
    React.createElement("div", {"className": (styles.text)},
      (textParts)
    )
  )

# https://developer.mozilla.org/ru/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
b64DecodeUnicode = (str) ->
  decodeURIComponent(
    atob(str)
      .split('')
      .map (c) ->
        charString = "00#{c.charCodeAt(0).toString(16)}"
        "%#{charString.slice(-2)}";
      .join('')
  );

renderText = (text, inbound, index, selected) ->
  return '' if !text?
  textWithoutTwilioBr = text.replace("<br/>", '')
  textOrComponent = SearchUtils.decodeElasticsearchHighlighting(textWithoutTwilioBr)
  if R.is(String, textOrComponent)
    React.createElement(MultilineText, { \
      "text": (textOrComponent),  \
      "className": (classnames(styles.message,
        "#{styles['outbound-color']}": !inbound && !selected
      )),  \
      "key": (index),  \
      "emojiSupport": (true)
    })
  else
    textOrComponent

isFalied = (message) ->
  status = message.status
  status == FAILED_MESSAGE_STATUS ||
  status == OPTOUT_MESSAGE_STATUS ||
  status == FB_TAG_MESSAGE_STATUS ||
  status == DUPLICATE_MESSAGE_STATUS


class MessageComponent extends BaseComponent

  @propTypes:
    message: propTypes.shape(
      text: propTypes.string.isRequired
      updated_at: propTypes.any
      agent: propTypes.object
    )
    conversation: propTypes.object.isRequired
    selected: propTypes.bool

  @defaultProps:
    selected: false

  constructor: (props) ->
    super(props)

    @state =
      credential: null
      selectedImage: {}
    @initComponent()

  dependsOnStores: [ImagePreviewStore]

  componentDidMount: =>
    super()

  isSending: (message) ->
    message.id == undefined && message[msc.FIELD_NAME_SEND_STATUS] == msc.SEND_STATUS_SENDING

  isSendFailure: (message) ->
    message.id == undefined && message[msc.FIELD_NAME_SEND_STATUS] == msc.SEND_STATUS_FAILURE

  componentDidUpdate: =>
    if @props.rankingOptions?.isMessagesRanking
      @refs.activeMessage?.scrollIntoView()

  messageForRanking: =>
    RankingActionCreator.selectRankedMessage @props.message.id, 'message'

  onDecryptCredential: (credential) =>
    store = new CustomerCardCredentialsFormByCustomerStore
    action = store.actionCreator || store.getActionCreator?()
    action?.get(credential.id, @onSuccessDecryptCredential)

  buildBlocks: (message) =>
    blocks = []
    textParts = []
    hasPicture = false
    { tagged, isRankSelectedMessage, skipped, tagName, tagGroup } = @props.rankingOptions
    messageId = @props.message.id

    isBase64 =  message?.approve_data?.message_type == BASE64_TYPE

    text = if isBase64 then b64DecodeUnicode(message.text) else message.text

    for part, index in UrlExtractor.toParts(text)
      if isBandwidthUrlPart(part)
        part.content = '[Image loading ...]'
        part.type = UrlExtractor.PART_TYPE_TEXT
      if isStickerPart(part)
        url = message.text.replace(/\n/g, '').trim()
        textParts.push(
          React.createElement("div", {"key": (index), "className": (styles.sticker)},
            React.createElement("img", {"src": (url)})
          )
        )
        break
      # else if isPicturePart(part)
      else if isFilePart(part)
        hasPicture = true
        blocks.push(buildTextBlock.call @, textParts) if textParts.length > 0
        textParts = []
        url = part.content
        status = message.status

        blocks.push(
          React.createElement("div", { \
            "className": (classnames(styles.picture_wrap, "#{styles.wrap_picture_failed}": isFalied(message), "#{styles.wrap_tagged}": tagged, "#{styles.wrap_estimate}": isRankSelectedMessage, "#{styles.wrap_skipped}": skipped, "activeSelectMessage": isRankSelectedMessage)),  \
            "key": (url + index),  \
            "ref": (if isRankSelectedMessage then "activeMessage" else 'message')
          },
            (@renderFile(message, url, index)),
            (if tagged
              React.createElement("div", {"className": (styles.tagged_info)},
                (tagGroup), " -\x3E ", (tagName)
              )
            )
          )
        )
      else if isUrlPart(part)
        textParts.push(
          React.createElement("a", {"key": (index), "className": (styles.link), "href": (part.content), "target": '_blank'}, " ", ( part.content ), " ")
        )
      else if isTextPart(part)
        textParts.push renderText(part.content, message.inbound, index, @props.selected)

    blocks.push(buildTextBlock.call @, textParts, index) if textParts.length > 0
    { blocks, hasPicture }


  onSuccessDecryptCredential: (credential) =>
    @setState credential: _.extend {}, credential, { isDecrypted: true }

  onCheckImage: (idx, url) =>
    {checked} = ImagePreviewStore
    newChecked = {}
    if !checked[idx]
      newChecked = Object.assign({}, checked)
      newChecked[idx] = url
    else
      newChecked = Object.assign({}, checked)
      delete newChecked[idx]
    setTimeout(
      -> ImagePreviewActionCreator.imageCheck(newChecked)
      0
    )

  onCloseModalPreview: () ->
    InterfaceActionCreator.closeModal()

  shortInfoFailed: (failed_info) ->
    SHORT_INFO_FAILED[failed_info.operator](failed_info)

  hoverInfoFailed: (failed_info) ->
    HOVER_INFO_FAILED[failed_info.operator](failed_info)

  onOpenModalPreview: (id, url) =>
    { ImagePreviewComponent } = crminfo.controls
    InterfaceActionCreator.openModal ImagePreviewComponent, {
      onClick: => @onCloseModalPreview
      url
      id
      showNewWindowIcon: true
    }

  renderFile: (message, url, index) =>
    {checked} = ImagePreviewStore
    isShowElement = false
    try
      isShowElement = new URL(url).host.includes('s3')
    catch
      isShowElement = false

    isShowPanel = !_.isEmpty checked
    name = url.split('/')
    name = decodeURIComponent(name[name.length - 1]) if name

    React.createElement(FilePreviewComponent, { \
      "key": (url),  \
      "id": (url),  \
      "name": (name),  \
      "url": (url),  \
      "checked": (!!checked[url]),  \
      "onCheck": (@onCheckImage),  \
      "onPreview": (@onOpenModalPreview),  \
      "isInbox": (message.inbound),  \
      "conversation": (@props.conversation.id),  \
      "index": (index),  \
      "isShowElement": (isShowElement),  \
      "isShowPanel": (isShowPanel)
    })

  render: ->
    isSendFailure = @isSendFailure(@props.message)
    message = @props.message
    { blocks, hasPicture } = @buildBlocks(message)
    className = classnames(styles.container,
      "#{styles.inbox}": @props.message.inbound
      "#{styles.outbox}": !@props.message.inbound
      "#{styles.selected}": @props.selected
      "#{styles.status_error}": isSendFailure
    )
    if @props.message.user
      agent = @props.message.user
      status = agentStatus(agent, @props.onlineAgents)
    person_style = if agent?.user_type == USER_TYPE_BOT
      styles.bot
    else
      styles.person

    personImage = if (agent || {}).image
      agent.image
    else
      require 'components/shared/images/unknown.png'

    messageSenderCredential = message?.credential &&
      React.createElement(CustomerCardCredentialFormComponent, { \
        "credential": (@state.credential || message.credential),  \
        "onDecryptCredential": (@onDecryptCredential.bind(@)),  \
        "isNotChoosable": true
      })

    React.createElement("div", {"className": (styles.container_wrapper)},
      React.createElement("div", {"className": (className), "onClick": (
        if @props.rankingOptions.isClickableForRank
          @messageForRanking
      )},
        React.createElement("img", {"className": (person_style), "src": (personImage)}),
        (
          if status
            React.createElement("div", {"className": (classnames(styles.status, styles[status]))})
        ),
        ( blocks ),
        (
          if isSendFailure
            React.createElement("div", {"className": (styles.error_message)},
              (@props.message[msc.FIELD_NAME_SEND_ERROR])
            )
        ),
        React.createElement("div", {"className": (classnames(styles.small, "#{styles.haspic}": hasPicture))},
          (
            if @isSending(@props.message)
              "Sending..."
            else if @props.message?.user?
              React.createElement("span", {"className": (styles.agentName)},
                (@props.message.user.last_name)
              )
          ),
          (
            if @props.message.created_at?
              React.createElement("div", {"className": (styles.smallText)},
                React.createElement("span", {"className": (styles.time)}, (moment(@props.message.created_at).format('HH:mm, '))),
                React.createElement("span", null, (moment(@props.message.created_at).format('DD MMM YY')))
              )
          ),
          (
            if @props.message.channel?.name?
              color = @props.message.channel.highlight_color
              color = DEFAULT_HIGHLIGHT unless color
              color = DEFAULT_HIGHLIGHT if color == 'white'
              React.createElement(Tooltip, { \
                "className": (styles.credential),  \
                "content": (messageSenderCredential || 'Channel'),  \
                "positionRight": true
              },
                React.createElement("span", {"style": ({color: color})}, """
                  [""", (@props.message.channel.name), """]
""")
              )
          ),
          (
            if (@props.message.channel?.service_name == 'Facebook') && (@props.message.status == FAILED_MESSAGE_STATUS)
              React.createElement("span", {"className": (styles.status_failed)}, "failed")
          ),
          (
            if (@props.message.status == FAILED_MESSAGE_STATUS && message.info && message.info.failed_info)
              React.createElement("span", { \
                "className": (styles.status_failed),  \
                "title": (@hoverInfoFailed(message.info.failed_info))
              },
                (@shortInfoFailed(message.info.failed_info))
              )
          ),
          (
            if @props.message.status == OPTOUT_MESSAGE_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "failed(optout)")
          ),
          (
            if @props.message.status == FB_TAG_MESSAGE_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "failed(24 hours facebook timeout)")
          ),
          (
            if @props.message.status == TIMEOUT_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "Network timeout. Try again after 5 sec please.")
          ),
          (
            if @props.message.status == APPBLOCK_MESSAGE_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "failed(appblock)")
          ),
          (
            if @props.message.status == DUPLICATE_MESSAGE_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "failed(duplicate)")
          ),
          (
            if @props.message.status == AUTO_MESSAGE_STATUS
              React.createElement("span", {"className": (styles.status_failed)}, "auto message")
          )
        )
      ),
      (
        prompts = R.reject(R.propEq('status', PROMPT_STATUSES.SENT))(@props.message.prompts || [])
        if prompts.length > 0
          React.createElement(PromptsComponent, {"prompts": (prompts), "conversation": (@props.conversation)})
      )
    )

module.exports = MessageComponent
