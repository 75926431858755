import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { createStore } from '@verdaccio/crminfo';

import config from './config';

import AppComponent from './components/AppComponent';
import SingleColumnComponent from './components/layouts/SingleColumnComponent/SingleColumnComponent';
import DoubleColumnComponent from './components/layouts/DoubleColumnComponent/DoubleColumnComponent';
import DoubleOverflowColumnComponent from './components/layouts/DoubleColumnComponent/DoubleOverflowColumnComponent';

import SettingsComponent from './components/settings/SettingsComponent/SettingsComponent';
import TagsComponent from './components/tags/TagsComponent';
import ChannelsComponent from './components/channels/ChannelsComponent/ChannelsComponent';
import ChannelFormComponent from './components/channels/ChannelFormComponent/ChannelFormComponent';
import LandingsComponent from './components/landings/LandingsComponent';
import LandingsFormComponent from './components/landings/LandingsFormComponent';
import RotationsComponent from './components/rotations/RotationsComponent';
import RotationsFormComponent from './components/rotations/RotationsFormComponent';

import CustomerFormWrapper from './components/customers/CustomerFormComponent/CustomerFormWrapper';
import CustomersComponent from './components/customers/CustomersComponent/CustomersComponent';

import WrappedConversationsComponent from './components/conversations/WrappedConversationsComponent/WrappedConversationsComponent';
import ConversationComponent from './components/conversations/ConversationComponent/ConversationComponent';
import RemindersComponent from './components/reminders/RemindersComponent/RemindersComponent';
import ScheduledMessagesManagerComponent from './components/ScheduledMessagesManagerComponent';

import DashboardTabsComponent from './components/dashboard/DashboardTabsComponent/DashboardTabsComponent';

import QADashboardComponent from './components/qa-dashboard/QADashboardComponent';

import RankingConversationsComponent from './components/ranking/RankingConversationsComponent';
import MessagesRankingConversationsComponent from './components/ranking/MessagesRankingConversationsComponent';
import RankingConversationComponent from './components/ranking/RankingConversationComponent';
import MessagesRankingConversationComponent from './components/ranking/MessagesRankingConversationComponent';

import CSVExportComponent from './components/CSVExportComponent';

import GroupsComponent from './components/groups/GroupsComponent';
import GroupSettingsComponent from './components/groups/GroupSettingsComponent';

import ReplyTemplatesPage from './components/replyTemplates/ReplyTemplatesPage';

import AdminWrapperComponent from './components/admin/AdminWrapperComponent';
import ReminderEditComponent from './components/admin/ReminderEditComponent';
import ScheduledMessageEditComponent from './components/admin/ScheduledMessageEditComponent';

import AgentListComponent from './components/agents/AgentListComponent';
import AgentProfileWrapperComponent from './components/agents/AgentProfileWrapperComponent';

const store = createStore();

const AppRouteComponent = (props) => {
  return (
    <Provider store={store}>
      <AppComponent {...props}>
        {/* React16 CHECK THIS */}
        {!config.reconnectUrl && (
          <Switch>
            <Route path="/settings" render={(routeProps) => <SingleColumnComponent col1={<SettingsComponent />} />} />
            <Route path="/tags" render={(routeProps) => <SingleColumnComponent col1={<TagsComponent />} />} />
            <Route path="/landings/new" render={(routeProps) => <DoubleColumnComponent col1={<LandingsComponent />} col2={<LandingsFormComponent new {...routeProps} />} />} />
            <Route path="/landings/:id" render={(routeProps) => <DoubleColumnComponent col1={<LandingsComponent />} col2={<LandingsFormComponent new {...routeProps} />} />} />
            <Route path="/landings" render={(routeProps) => <DoubleColumnComponent col1={<LandingsComponent />} />} />
            <Route path="/rotations/new" render={(routeProps) => <DoubleOverflowColumnComponent col1={<RotationsComponent />} col2={<RotationsFormComponent new {...routeProps} />} />} />
            <Route path="/rotations/:id" render={(routeProps) => <DoubleOverflowColumnComponent col1={<RotationsComponent />} col2={<RotationsFormComponent new {...routeProps} />} />} />
            <Route path="/rotations" render={(routeProps) => <DoubleOverflowColumnComponent col1={<RotationsComponent />} />} />
            <Route path="/channels/new" render={(routeProps) => <DoubleColumnComponent col1={<ChannelsComponent />} col2={<ChannelFormComponent new {...routeProps} />} />} />
            <Route path="/channels/:id" render={(routeProps) => <DoubleColumnComponent col1={<ChannelsComponent />} col2={<ChannelFormComponent  {...routeProps} />} />} />
            <Route path="/channels" render={(routeProps) => <DoubleColumnComponent col1={<ChannelsComponent />} />} />
            <Route path="/customers/new" render={(routeProps) => <DoubleColumnComponent col1={<CustomersComponent />} col2={<CustomerFormWrapper {...routeProps} new />} />} />
            <Route path="/customers/:id" render={(routeProps) => <DoubleColumnComponent col1={<CustomersComponent />} col2={<CustomerFormWrapper {...routeProps} />} />} />
            <Route path="/customers" render={(routeProps) => <DoubleColumnComponent col1={<CustomersComponent />} />} />
            <Route path="/conversations/:folder/:id/schedulers/:customerId" render={(routeProps) => <DoubleColumnComponent col1={<WrappedConversationsComponent {...routeProps} />} col2={<ConversationComponent {...routeProps} />} modal={<ScheduledMessagesManagerComponent {...routeProps} />} />} />
            <Route path="/conversations/:folder/:id/reminders" render={(routeProps) => <DoubleColumnComponent col1={<WrappedConversationsComponent {...routeProps} />} col2={<ConversationComponent {...routeProps} />} modal={<RemindersComponent {...routeProps} />} />} />
            <Route path="/conversations/:folder/:id" render={(routeProps) => <DoubleColumnComponent col1={<WrappedConversationsComponent {...routeProps} />} col2={<ConversationComponent {...routeProps} />} />} />
            <Route path="/conversations/:folder" render={(routeProps) => <DoubleColumnComponent col1={<WrappedConversationsComponent {...routeProps} />} />} />
            <Route path="/dashboard" component={ DashboardTabsComponent } />
            <Route path="/qa-dashboard" component={QADashboardComponent} />
            <Route path="/ranking/:id" render={(routeProps) => <DoubleColumnComponent col1={<RankingConversationsComponent {...routeProps} />} col2={<RankingConversationComponent {...routeProps} />} />} />
            <Route path="/ranking" render={(routeProps) => <DoubleColumnComponent col1={<RankingConversationsComponent {...routeProps} />} />} />
            <Route path="/messages_ranking/:id" render={(routeProps) => <DoubleColumnComponent col1={<MessagesRankingConversationsComponent {...routeProps} />} col2={<MessagesRankingConversationComponent {...routeProps} />} />} />
            <Route path="/messages_ranking" render={(routeProps) => <DoubleColumnComponent col1={<MessagesRankingConversationsComponent {...routeProps} />} />} />
            <Route path="/csv-export" render={(routeProps) => <SingleColumnComponent col1={<CSVExportComponent />} />} />
            <Route path="/groups/:groupId" render={(routeProps) => <DoubleColumnComponent col1={<GroupsComponent {...routeProps} />} col2={<GroupSettingsComponent {...routeProps} />} />} />
            <Route path="/groups" render={(routeProps) => <DoubleColumnComponent col1={<GroupsComponent {...routeProps} />} />} />
            <Route path="/reply_templates" render={(routeProps) => <SingleColumnComponent col1={<ReplyTemplatesPage />} />} />
            <Route path="/admin/:type/edit_message/:date/:scheduledMessageId" render={(routeProps) => <SingleColumnComponent col1={<AdminWrapperComponent {...routeProps} />} modal={<ScheduledMessageEditComponent {...routeProps} />} />} />
            <Route path="/admin/:type/edit_reminder/:date/:reminderId" render={(routeProps) => <SingleColumnComponent col1={<AdminWrapperComponent {...routeProps} />} modal={<ReminderEditComponent {...routeProps} />} />} />
            <Route path="/admin/:type" render={(routeProps) => <SingleColumnComponent col1={<AdminWrapperComponent {...routeProps} />} />} />
            <Route path="/agents/new" render={(routeProps) => <AgentProfileWrapperComponent {...routeProps} new />} />
            <Route path="/agents/:id/:tab?" component={AgentProfileWrapperComponent} />
            <Route path="/agents" component={AgentListComponent} />
          </Switch>
        )}
      </AppComponent>
    </Provider>
  );
}

export { store };

export default AppRouteComponent;
