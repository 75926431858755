import React, { useCallback, useState, useMemo } from 'react';
import classnames from 'classnames';

import { controls, pluginConfigs, mainHubActions } from '@verdaccio/crminfo';
import { crmPluginEnabled } from 'root/config';

import config from 'root/config';

import { store } from 'root/AppRouteComponent';

import { IMAGE_TYPES, DOC_TYPES } from 'constants/FileType';

import CRMPluginActionCreator from 'actions/CRMPluginActionCreator';
import CRMInfoModuleActionCreator from 'actions/CRMInfoModuleActionCreator';

import CRMInfoModuleStore from 'stores/CRMInfoModuleStore';

import Checkbox from '../Checkbox';

import styles from './image-preview.styl';

const FilePreviewComponent = React.memo((props) => {
  const { MenuFile, SubOrdersMenu } = controls;

  let name = props.name.split('/');
  name = name[name.length - 1];

  let ext = name.split('.');
  ext = ext[ext.length - 1].toLowerCase();

  const isImage = IMAGE_TYPES.indexOf(ext);
  const isDoc = DOC_TYPES.indexOf(ext);

  const [stayOpen, setStayOpen] = useState(false)

  const notificationHandler = useCallback(
    ({ orderId, configKey = null }) => {
      if (configKey) {
        store.dispatch(mainHubActions.forceCLosePlugin(configKey));
      }
      setTimeout(() => {
        const entityKey = `conv-${props.conversation}`;
        const config = pluginConfigs.OrderCompactPlugin({
          orderId,
          options: {
            isResizable: true,
            entityKey,
          },
        });
        CRMPluginActionCreator.setConfig(
          config, entityKey, `${entityKey}-order-${orderId}`, true);
      }, 1);
    },
    [],
  );

  const openMenuHandler = useCallback(
    (value) => {
      setStayOpen(value);
    },
    [setStayOpen],
  );

  const onDetailPreview = (url) => {
    const menuOptions = {
      submenu: {
        mainParent: 'copyto',
        subComponent: <SubOrdersMenu filesUrls={[props.url]} isRight clientId={CRMInfoModuleStore.externalClientId} />
      },
      notificationHandler: notificationHandler
    }
    CRMPluginActionCreator.openFilePreview(props.conversation, url, menuOptions, CRMInfoModuleStore.externalClientId)
  };

  const refreshCrmData = (event) => {
    if (event.event === 'createOrder') {
      const customer = CRMInfoModuleStore.currentCustomer;
      const externalClientId = CRMInfoModuleStore.externalClientId;
      if (customer && externalClientId && externalClientId === event.clientId) {
        CRMInfoModuleActionCreator.getOrders(
          customer,
          config.urls.customers.orders(customer.crm_ids[0]),
          {
            forceUpdate: true,
          },
        );
      }
    }
  };

  const needMenuItems = useMemo((items = ['download', 'copyto']) => {
    let dopMenu = [];

    if (ext === 'pdf') dopMenu.push('edit', 'ocr');
    if (isImage > -1) dopMenu.push('ocr');

    return [...items, ...dopMenu];
  }, [ext, isImage]);

  return (
    <div className={styles.container}>
      {!props.checked && !props.isShowPanel && props.isShowElement &&
        <div className={classnames(styles.container_menu, { [styles.stay_open]: stayOpen })}>
          <MenuFile
            needMenuItems={needMenuItems}
            submenu={{
              mainParent: 'copyto',
              subComponent: <SubOrdersMenu refreshCrmData={refreshCrmData} filesUrls={[props.url]} isRight={props.isInbox} clientId={CRMInfoModuleStore.externalClientId} />
            }}
            fileUrl={props.url}
            isHorizontal
            dropdownProps={{ positionFullLeft: props.isInbox, positionFullRight: !props.isInbox }}
            notificationHandler={notificationHandler}
            openMenuHandler={openMenuHandler}
            openFullPreviewHandler={() => onDetailPreview(props.url)}
          />
        </div>
      }
      <div className={styles.picture_inner_wrap}>
        {isImage > -1 &&
          <img className={styles.img} src={props.url} />
        }
        {!props.isShowElement &&
          <a className={styles.other_link} href={props.url} target="_blanc" />
        }
        <i className={styles.overlay} />
        <div className={styles.picture_wrap_overlay}>
          {!props.checked &&
            <div className={styles.icon_wrap}>
              {isDoc > -1 &&
                <i className={classnames(styles.icon_type, styles[ext])} />
              }
              {isDoc == -1 && isImage == -1 &&
                <i className={classnames(styles.icon_type, styles.file)} />
              }
            </div>
          }
          <div className={classnames(styles.data_file, { [styles.active]: props.checked || props.isShowPanel })}>
            {!props.isShowPanel &&
              <div className={styles.icon_wrap}>
                {isImage > -1 &&
                  <div onClick={() => props.onPreview(props.id, props.url)} className={styles.preview} />
                }
                {props.isShowElement &&
                  <a onClick={() => onDetailPreview(props.url)} target='_blank' className={styles.download} />
                }
              </div>
            }
            {props.isShowElement &&
              <div className={styles.checkbox}>
                <Checkbox checked={props.checked} onClick={() => props.onCheck(props.id, props.url)} />
              </div>
            }
            <div className={styles.name}>{name}</div>
          </div>
        </div>
      </div>
    </div >
  )
});

export default FilePreviewComponent;
