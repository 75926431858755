BaseComponent = require 'components/BaseComponent'
CustomerPaymentsComponent = require './CustomerPaymentsComponent'
CustomerOrdersComponent = require './CustomerOrdersComponent'
CustomerCommunicationComponent = require './CustomerCommunicationComponent'
CustomerTicketsComponent = require './CustomerTicketsComponent'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
CustomersStore = require 'stores/CustomersStore'
CRMInfoModuleActionCreator = require 'actions/CRMInfoModuleActionCreator'
InfoBlockComponent = require 'components/shared/InfoBlockComponent'
{ arrayDifference } = require 'lib/utils'
SettingsStore = require 'stores/SettingsStore'
{ buildCRMInfoRequestData } = require 'lib/utils'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
{ localStorageKey } = require 'constants/CRMPlugin'
config = require 'root/config'

moduleMap =
  orders:
    component: CustomerOrdersComponent
    title: 'Orders'
    icon: require 'components/shared/images/orders.svg'
    additional: (data) -> "#{data.length} / #{CustomerOrdersStore.total}"
  payments: 
    component: CustomerPaymentsComponent
    title: 'Payments'
    icon: require 'components/shared/images/payment.svg'
    additional: (data) -> data.balance
    additional_style: 'payments_balance'
  communication:
    component: CustomerCommunicationComponent
    title: 'Communication'
    icon: require 'components/shared/images/communication.svg'
    additional: (data) -> data.length

propTypes = require 'prop-types'

class CRMInfoModule extends BaseComponent

  constructor: (props) ->
    super(props)
    @state =
      newWindowClicked: {}
    
    @entityKey = "conv-#{props.conversation.id}"
    @savedCredentials = props.customer?.credentials || []
    @isInitialDownloading = !CRMInfoModuleStore.currentCustomer
    @withoutCustomer = true
    @initComponent()

  dependsOnStores: [CRMInfoModuleStore, SettingsStore, CustomerOrdersStore, CustomersStore]

  componentDidMount: ->
    super()
    if @entityKey
      setTimeout(
        => @crmPluginConfigRestore @entityKey
        30
      )
    newState = {}
    Object.keys moduleMap
      .forEach (key, i) =>
        newState[key] = if i == 0 then !!@props.anyOpen else false
    @props.setTabsState newState

  crmPluginConfigRestore: (key) ->
    allConfigs = localStorage.getItem(localStorageKey)
    unless allConfigs
      setTimeout(
        ->
          CRMPluginActionCreator.setForceCloseProperty()
        0
      )
      return null
    try
      allConfigs = JSON.parse(allConfigs) || {}
    catch err
      setTimeout(
        ->
          CRMPluginActionCreator.setForceCloseProperty()
        0
      )
      return null
    configKeys = Object.keys(allConfigs[key] || {})
    unless configKeys.length
      setTimeout(
        ->
          CRMPluginActionCreator.setForceCloseProperty()
        0
      )
      return null

    insertConfig = (key, configKey) ->
      CRMPluginActionCreator.setConfig allConfigs[key][configKey], key, configKey, false, true

    setTimeout(
      ->
        CRMPluginActionCreator.setForceCloseProperty()
        configKeys.forEach (configKey) ->
          insertConfig key, configKey
      0
    )

  componentWillReceiveProps: (props) ->
    if props.customer?.id != CRMInfoModuleStore.currentCustomer?.id ||
       @props.conversation.id != props.conversation.id
      @entityKey = "conv-#{props.conversation.id}"
      if @entityKey
        setTimeout(@crmPluginConfigRestore, 10, @entityKey)
    if props.customer?.id != CRMInfoModuleStore.currentCustomer?.id
      setTimeout(@downloadData, 10, props.customer)
      setTimeout(@downloadOrders, 11, props.customer)

  componentWillUnmount: ->
    super()
    setTimeout(
      CRMPluginActionCreator.setForceCloseProperty
      25
    )

  getState: ->
    if @isInitialDownloading && SettingsStore.dataByType && @props.customer
      setTimeout(
        => @downloadData(@props.customer)
        5
      )
      setTimeout(
        => @downloadOrders(@props.customer)
        6
      )
    {}

  downloadData: (customer) =>
    if SettingsStore.dataByType && customer
      @isInitialDownloading = false
      showDrafts = true
      CRMInfoModuleActionCreator.get(
        customer
        config.urls.customers.crmInfo(customer.id)
      )
      @savedCredentials = customer.credentials

  downloadOrders: (customer) =>
    if customer.crm_ids && !CustomerOrdersStore.loading
      CRMInfoModuleActionCreator.getOrders(
        customer
        config.urls.customers.orders(customer.crm_ids[0])
      )
    if !customer.crm_ids
      CRMInfoModuleActionCreator.clearOrders()

  isCredentialsChanged: (newCredentials) =>
    diff = arrayDifference @savedCredentials, newCredentials
    return true if diff.length != 0

    diff = arrayDifference newCredentials, @savedCredentials
    return true if diff.length != 0
    return false

  onNewWindow: (module) =>
    newWindowClicked = @state.newWindowClicked || {}
    newWindowClicked[module] = true
    @setState { newWindowClicked }

  onNewWindowComplete: (module, config) =>
    newWindowClicked = @state.newWindowClicked || {}
    delete newWindowClicked[module]
    @setState { newWindowClicked }
    CRMPluginActionCreator.setAdditionalPlugin config

  render: ->
    return React.createElement("div", null) if CustomersStore.customerLoading
    React.createElement("div", null,
      (
        Object.keys moduleMap
          .map (key, i) =>
            Component = moduleMap[key].component
            additional = moduleMap[key].additional CRMInfoModuleStore[key] if CRMInfoModuleStore[key]
            data = CRMInfoModuleStore[key]
            if key == 'orders' then additional = moduleMap[key].additional CustomerOrdersStore[key] if CustomerOrdersStore[key]
            if key == 'orders' then data = CustomerOrdersStore[key]

            React.createElement(InfoBlockComponent, { \
              "key": (key),  \
              "dataKey": (key),  \
              "icon": (moduleMap[key].icon),  \
              "title": (moduleMap[key].title),  \
              "openByDefault": (if i == 0 then !!@props.anyOpen else false),  \
              "onToggleBlock": (@props.onToggleBlock),  \
              "additional_style": (moduleMap[key].additional_style),  \
              "additional": (additional),  \
              "onNewWindow": (@onNewWindow)
            },
              React.createElement(Component, { \
                "data": (data),  \
                "customer": (@props.customer),  \
                "conversation": (@props.conversation),  \
                "externalClientId": (CRMInfoModuleStore.externalClientId),  \
                "loading": (CRMInfoModuleStore.loading || CustomerOrdersStore.loading || @props.loading),  \
                "error": (CRMInfoModuleStore.error || CustomerOrdersStore.error),  \
                "dataKey": (key),  \
                "newWindowClicked": (@onNewWindowComplete if @state.newWindowClicked[key]),  \
                "entityKey": ("conv-#{@props.conversation.id}")
              })
            )
      )
    )
    
CRMInfoModule.propTypes = 
  onToggleBlock: propTypes.func.isRequired
  setTabsState: propTypes.func.isRequired
  customer: propTypes.object
  loading: propTypes.bool
  anyOpen: propTypes.bool
  additional: propTypes.string

module.exports = CRMInfoModule
