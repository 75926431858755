moment = require 'moment'
classnames = require 'classnames'
{ Link } = require 'react-router-dom'
_ = require 'underscore'

BaseComponent = require '../../BaseComponent'
styles = require './agent-info'
{ capitalizeFirstLetter } = require 'lib/utils'
{ ROLES, ACTIVITIES, RATES } = require 'constants/Agents'
AgentEditPopupComponent = require '../AgentEditPopupComponent'
validators = require 'lib/validators'
CustomSelectComponent = require '../../shared/CustomSelectComponent'
AuthStore = require 'stores/AuthStore'
{ menus } = require 'root/config'
ChangeAvatarComponent = require '../ChangeAvatarComponent'

SOUND_TOOGLE_ROLES = [
  'agent', 'newbie_agent', 'sales', 'ntff'
]


rolesWithoutDuplications = {}
Object.keys ROLES
  .forEach (key) ->
    unless (rolesWithoutDuplications[ROLES[key].value] || {}).active
      rolesWithoutDuplications[ROLES[key].value] = ROLES[key]

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'agents'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toEditRate: item.accessToEditRate || null

class AgentInfoComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    isEditable = false
    isRateEditable = false
    if accessConfig.toEdit
      if accessConfig.toEdit.indexOf((AuthStore.user || {}).role) != -1
        isEditable = true
    if accessConfig.toEditRate
      if accessConfig.toEditRate.indexOf((AuthStore.user || {}).role) != -1
        isRateEditable = true
    @state =
      edit: null
      isEditable: isEditable
      isRateEditable: isRateEditable
      showAvatarChange: false

    unless props.agent
      @state.data =
        role: ROLES.newbie_agent.value
        rate: RATES['default'].value
        first_name: 'Name'
        last_name: 'Surname'
        contacts:
          phones: []
          emails: []
        activity: ACTIVITIES.wasted.value
        email: ''
        image: null
    else
      @state.data =
        role: ROLES[props.agent.role].value
        rate: RATES[props.agent.rate || 'default'].value
        first_name: props.agent.first_name
        last_name: props.agent.last_name
        contacts:
          phones: props.agent.contacts.phones.slice 0
          emails: props.agent.contacts.emails.slice 0
        activity: props.agent.activity
        messages_ranker: props.agent.messages_ranker
        inbound_sound: props.agent.inbound_sound
        email: props.agent.email
    @initComponent()

  setRoleValue: (value) =>
    data = Object.assign @state.data,
      role: value
    @setState
      data: data

  setRateValue: (value) =>
    data = Object.assign @state.data,
      rate: value
    @setState
      data: data

  showEditPopup: (element, fields) =>
    data = {}
    stateData = @state.data
    fields.forEach (item) ->
      data[item.field] =
        value: item.value
        placeholder: item.placeholder
        validator: item.validator

    editedFields =
      element: @refs[element]
      fields: data
    @setState edit: editedFields

  closeEditPopup: =>
    @setState edit: null

  saveEditedData: (inputs) =>
    inputData = {}
    refs = @refs.popup.refs
    Object.keys refs
      .forEach (key) ->
        inputData[key] = refs[key].value
    delete inputData.password unless inputData.password
    data = Object.assign @state.data, inputData
    @setState
      edit: null
      data: data

  generateEditPopup: =>
    if @state.edit
      React.createElement(AgentEditPopupComponent, { \
        "fields": (@state.edit.fields),  \
        "editedElement": (@state.edit.element),  \
        "saveData": (@saveEditedData),  \
        "closePopup": (@closeEditPopup),  \
        "ref": "popup"
      })
    else
      null

  toggleActivity: =>
    newActivity = null
    if @state.data.activity == ACTIVITIES.active.value
      newActivity = ACTIVITIES.wasted.value
    else
      newActivity = ACTIVITIES.active.value
    data = Object.assign @state.data,
      activity: newActivity
    @setState data: data

  toggleSoundNotifications: =>
    inbound_sound = !@state.data.inbound_sound
    data = Object.assign @state.data,
      inbound_sound: inbound_sound
    @setState data: data

  toggleMessagesRanker: =>
    messages_ranker = !@state.data.messages_ranker
    data = Object.assign @state.data,
      messages_ranker: messages_ranker
    @setState data: data

  onEmailClick: (e) =>
    @setState editedEmail: e.target.getAttribute 'data-value'

  onEmailBlur: (e) =>
    data = @state.data
    value = e.target.value
    if value.trim() == ''
      data.contacts.emails.splice @state.editedEmail, 1
    else
      data.contacts.emails[@state.editedEmail] = e.target.value
    newData = Object.assign @state,
      data: data
      editedEmail: null
    @setState newData

  getEmailList: =>
    onEmailClick = @onEmailClick
    editedEmail = Number.parseInt @state.editedEmail
    onEmailBlur = @onEmailBlur
    isEditable = @state.isEditable
    @state.data.contacts.emails.map (email, i) ->
      if i == editedEmail && isEditable
        React.createElement("div", {"className": (styles['info__contact']), "key": (i)},
          React.createElement("input", { \
            "type": 'text', "defaultValue": (email), "onBlur": (onEmailBlur), "ref": "input",  \
            "className": (classnames styles['info__contact-input'], styles['info__contact-input_email'])
          }),
          React.createElement("span", {"className": (styles['info__contact-edit-icon'])})
        )
      else
        React.createElement("div", {"className": (styles['info__contact']), "key": (i)},
          React.createElement("span", { \
            "className": (classnames styles['info__contact-label'],
              styles['info__contact-label_email']
              "#{styles['info__contact-label_hover']}": isEditable
            ),  \
            "onClick": (onEmailClick if isEditable), "data-value": (i)
          },
            (email)
          ),
          React.createElement("span", {"className": (styles['info__contact-edit-icon'])})
        )

  onPhoneClick: (e) =>
    @setState editedPhone: e.target.getAttribute 'data-value'

  onPhoneBlur: (e) =>
    data = @state.data
    value = e.target.value
    if value.trim() == ''
      data.contacts.phones.splice @state.editedPhone, 1
    else
      data.contacts.phones[@state.editedPhone] = e.target.value
    newData = Object.assign @state,
      data: data
      editedPhone: null
    @setState newData

  getPhoneList: =>
    onPhoneClick = @onPhoneClick
    editedPhone = Number.parseInt @state.editedPhone
    onPhoneBlur = @onPhoneBlur
    isEditable = @state.isEditable
    @state.data.contacts.phones.map (phone, i) ->
      if i == editedPhone && isEditable
        React.createElement("div", {"className": (styles['info__contact']), "key": (i)},
          React.createElement("input", { \
            "type": 'text', "className": (styles['info__contact-input']),  \
            "defaultValue": (phone), "onBlur": (onPhoneBlur),  \
            "ref": "input"
          }),
          React.createElement("span", {"className": (styles['info__contact-edit-icon'])})
        )
      else
        React.createElement("div", {"className": (styles['info__contact']), "key": (i)},
          React.createElement("span", { \
            "className": (classnames styles['info__contact-label'],
              "#{styles['info__contact-label_hover']}": isEditable
            ),  \
            "onClick": (onPhoneClick if isEditable), "data-value": (i)
          },
            (phone)
          ),
          React.createElement("span", {"className": (styles['info__contact-edit-icon'])})
        )

  addPhone: =>
    data = @state.data
    data.contacts.phones.push ''
    newState = Object.assign @state,
      data: data
      editedPhone: data.contacts.phones.length - 1
    @setState newState

  addEmail: =>
    data = @state.data
    data.contacts.emails.push ''
    newState = Object.assign @state,
      data: data
      editedEmail: data.contacts.emails.length - 1
    @setState newState

  componentDidUpdate: =>
    @refs.input.focus() if @refs.input
    super()

  getStatusTags: =>
    if @props.agent
      React.createElement("span", null,
        React.createElement("span", { \
          "className": (classnames styles.info__status,
            "#{styles.info__status_online}": @props.agent.status != 'offline'
          )
        },
          (capitalizeFirstLetter @props.agent.status)
        ),
        React.createElement("span", { \
          "className": (classnames styles['info__activity-status'],
            "#{styles['info__activity-status_active']}": @props.agent.activity == ACTIVITIES.active.value
          )
        },
          (ACTIVITIES[@props.agent.activity].label)
        )
      )
    else
      null

  getCreatedAt: =>
    createdAt = null
    if @props.agent
      createdAt = @props.agent.created_at
    else
      createdAt = new Date()
    moment(createdAt).format 'DD MMM YYYY'

  getUserImage: =>
    if @props.agent
      image = if (@props.agent || {}).image
        @props.agent.image
      else
        require "../../shared/images/unknown.png"
    else
      image = if @state.data.image
          @state.data.image
        else
          require "../../shared/images/unknown.png"
    React.createElement("div", { \
      "className": (styles['image-container']),  \
      "onMouseOver": (@onImageHover if @state.isEditable),  \
      "onMouseOut": (@onImageBlur if @state.isEditable),  \
      "onClick": (@onUserImageClick if @state.isEditable)
    },
      React.createElement("img", { \
        "className": (classnames styles['user-image'],
          "#{styles['user-image_editable']}": @state.isEditable
        ),  \
        "src": (image),  \
        "alt": "User image"
      }),
      React.createElement("div", { \
        "ref": "imageHover",  \
        "className": (styles['user-image__hover'])
      }, """
        Change photo
""", React.createElement("i", null)
      )
    )

  passwordValidator: (value) =>
    if @props.agent
      if value.trim() != ''
        validators.validatePassword value
      else
        null
    else
      validators.validatePassword value

  checkEnabledSave: =>
    data = @state.data
    agent = @props.agent
    enabled = true
    if agent
      diff = Object.keys data
        .filter (key) ->
          if key == 'role'
            ROLES[data.role].value != ROLES[agent.role].value
          else
            !_.isEqual data[key], agent[key]
      enabled = diff.length != 0
    else
      enabled = !!@state.data.email
      enabled = !!@state.data.password if enabled
    enabled

  saveData: =>
    data = @state.data
    agent = @props.agent
    needReload = data.inbound_sound != agent.inbound_sound
    @props.saveData @state.data, () -> window.location.reload() if needReload
                                            

  closeModal: =>
    @setState showAvatarChange: false

  onUserImageClick: =>
    @setState showAvatarChange: true

  onImageHover: =>
    $(@refs.imageHover).stop()
    $(@refs.imageHover).animate(
      marginTop: '-34.4px'
      200
    )

  onImageBlur: =>
    $(@refs.imageHover).stop()
    $(@refs.imageHover).animate(
      marginTop: '0'
      200
    )

  onAvatarChange: ({image}) =>
    newData = Object.assign @state.data,
      image: image
    @setState data: newData

  render: ->
    cancelShown = ROLES[AuthStore.user.role].value in accessConfig.another
    enabledSave = @checkEnabledSave()
    canToogleSound = SOUND_TOOGLE_ROLES.includes @state.data.role
    React.createElement("div", {"className": (styles.container)},
      (@getUserImage()),
      React.createElement("div", {"className": (styles.info)},
        React.createElement("p", {"className": (styles.info__creation)}, ('Created on: '),
          React.createElement("span", {"className": (styles.info__creation_date)}, (@getCreatedAt())),
          (@getStatusTags())
        ),
        React.createElement("div", {"className": (styles.info__main)},
          React.createElement("p", { \
            "ref": "username",  \
            "className": (classnames styles['info__main-label'],
              "#{styles['info__main-label_existing']}": @props.agent
              "#{styles['info__main-label_hover']}": @state.isEditable
            ),  \
            "onClick": (
              if @state.isEditable && !@state.edit
                @showEditPopup.bind @, 'username', [
                    field: 'first_name'
                    value: @state.data.first_name
                    placeholder: 'First name'
                    validator: validators.validateOnEmpty
                  ,
                    field: 'last_name'
                    value: @state.data.last_name
                    placeholder: 'Last name'
                    validator: validators.validateOnEmpty
                ]
            )
          },
            ("#{@state.data.first_name} #{@state.data.last_name}")
          ),
          React.createElement("span", {"className": (styles['info__main-icon'])})
        ),
        React.createElement("div", {"className": (styles.info__main)},
          React.createElement("p", { \
            "ref": "credentials",  \
            "className": (classnames styles['info__main-label'], styles['info__main_credentials'],
              "#{styles['info__main-label_existing']}": @props.agent
              "#{styles['info__main-label_hover']}": @state.isEditable
            )
          },
            (
              React.createElement("span", {"className": (styles['info__main_credentials-email'])},
                ("#{@state.data.email}" if @state.data.email)
              )
            )
          )
        ),
        React.createElement("div", {"className": (styles.info__role)},
            React.createElement("span", {"className": (styles['info__role-select'])},
              (rolesWithoutDuplications[@state.data.role].label)
            ),
            (
              if @state.isRateEditable
                React.createElement(CustomSelectComponent, { \
                  "defaultValue": (@state.data.rate),  \
                  "options": (RATES),  \
                  "onOptionSelect": (@setRateValue),  \
                  "customSelectStyle": (styles['info__role-select'])
                })
              else
                React.createElement("span", {"className": (styles['info__role-select'])},
                  (@state.data.rate)
                )
            )
        ),
        React.createElement("div", {"className": (styles.info__contacts)},
          React.createElement("div", {"className": (styles['info__contacts-phones'])},
            (@getPhoneList()),
            (if @state.isEditable
              React.createElement("p", {"className": (styles['info__contacts_add']), "onClick": (@addPhone)}, "+ Add phone")
            )
          ),
          React.createElement("div", {"className": (styles['info__contacts-emails'])},
            (@getEmailList()),
            (if @state.isEditable
              React.createElement("p", {"className": (styles['info__contacts_add']), "onClick": (@addEmail)}, "+ Add email")
            )
          )
        ),
        (if @state.isEditable
          React.createElement("div", {"className": (styles.info__activity)},
            React.createElement("div", { \
              "className": (classnames(styles['info__activity-overlay'],
                "#{styles['info__activity-overlay_active']}": @state.data.activity == ACTIVITIES.active.value
              )),  \
              "onClick": (@toggleActivity)
            },
              React.createElement("div", {"className": (styles['info__activity-switcher'])})
            ),
            React.createElement("span", null, "Active")
          )
        ),
        (if @state.isEditable
          React.createElement("div", {"className": (styles.info__activity)},
            React.createElement("div", { \
              "className": (classnames(styles['info__activity-overlay'],
                "#{styles['info__activity-overlay_active']}": @state.data.messages_ranker
              )),  \
              "onClick": (@toggleMessagesRanker)
            },
              React.createElement("div", {"className": (styles['info__activity-switcher'])})
            ),
            React.createElement("span", null, "Message Ranker")
          )
        ),
        (if canToogleSound
          React.createElement("div", {"className": (styles.info__activity)},
            React.createElement("div", { \
              "className": (classnames(styles['info__activity-overlay'],
                "#{styles['info__activity-overlay_active']}": @state.data.inbound_sound
              )),  \
              "onClick": (@toggleSoundNotifications)
            },
              React.createElement("div", {"className": (styles['info__activity-switcher'])})
            ),
            React.createElement("span", null, "Sound notifications")
          )
        ),
        (@generateEditPopup())
      ),
      React.createElement("div", {"className": (styles.controls)},
        (if cancelShown
          React.createElement(Link, {"to": '/agents', "className": (styles.controls__button)}, "Cancel")
        ),
        (if @state.isEditable || canToogleSound
          React.createElement("button", { \
            "className": (classnames styles.controls__button, styles.controls__button_save,
              "#{styles.controls__button_disabled}": !enabledSave
            ),  \
            "onClick": (@saveData if enabledSave)
          }, """
            Save
""")
        )
      ),
      (if @state.showAvatarChange
        React.createElement(ChangeAvatarComponent, { \
          "closeModal": (@closeModal),  \
          "onSaveClick": (
            if @props.agent
              @props.onAvatarChange
            else
              @onAvatarChange
          )
        })
      )
    )

module.exports = AgentInfoComponent
