BaseComponent = require 'components/BaseComponent'
HashTags = require 'components/HashTags'
TagsStore = require 'stores/TagsStore'
TagsActionCreator = require 'actions/TagsActionCreator'
TagTargetActionCreator = require 'actions/TagTargetActionCreator'
TagTypes = require 'constants/Tags'
styles = require './customer-tags-selector'
classnames = require 'classnames'

propTypes = require 'prop-types'

class CustomerTagsSelector extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      openSelector: false
      page: 0
      perPage: 30
    @initComponent()

  dependsOnStores: [TagsStore]

  onSelectorOpen: =>
    return if @state.openSelector
    TagsActionCreator.getGeneralList
      type: TagTypes.CUSTOMER_TAGS_TYPE
      page: @state.page
      per_page: @state.perPage
      target_id: @props.customerId
    document.addEventListener('click', @closeSelector)
    @setState openSelector: true

  closeSelector: (e) =>
    element = e.target
    outsideContainer = true
    while outsideContainer && element.parentElement
      outsideContainer = false if element.getAttribute('id') == 'customer-tags-selector'
      element = element.parentElement

    if outsideContainer
      document.removeEventListener('click', @closeSelector)
      @setState openSelector: false

  onGroupSelected: (group) =>
    TagsActionCreator.getTagsByGroup(
      group
      @props.customerId
      0
      @state.perPage
    )

  onTagSelected: (tag) =>
    if tag.checked
      TagTargetActionCreator.destroy tag, @props.customerId
    else
      TagTargetActionCreator.create tag, @props.customerId

  onTagDelete: (tag) =>
    unless tag.hidden
      tag.tag_type = TagTypes.CUSTOMER_TAGS_TYPE
      TagTargetActionCreator.destroy tag, @props.customerId

  onPageChange: (selected, group) =>
    if selected != @state.page
      if group.id
        TagsActionCreator.getTagsByGroup(
          group
          @props.customerId
          selected
          @state.perPage
        )
      else
        TagsActionCreator.getGeneralList({
          type: TagTypes.CUSTOMER_TAGS_TYPE
          page: selected
          per_page: @state.perPage
          target_id: @props.customerId
        })

      @setState page: selected

  onBackToGroups: =>
    TagsActionCreator.getGeneralList({
      type: TagTypes.CUSTOMER_TAGS_TYPE
      page: @state.page
      per_page: @state.perPage
      target_id: @props.customerId
    })

  componentDidUpdate: ->
    height = 0
    if @refs.tagsContainer && @refs.tagsContainer.clientHeight > @refs.tags.clientHeight
      height += @refs.tagsContainer.clientHeight
    else
      height += @refs.tags.clientHeight

    if @state.height != height
      @setState { height }

  render: ->
    { onTagDelete } = @
    React.createElement("div", {"style": ( height: @state.height + 'px')},
      React.createElement("div", {"ref": "tags", "className": (styles.tags)},
        (@props.activeTags.map (tag, i) ->
          className = classnames(
            styles.tag,
            "#{styles.tag_hidden}": tag.hidden,
            "#{styles.tag_subscription_active}": tag.name == 'subscription_active',
            "#{styles.tag_subscription_inactive}": tag.name == 'subscription_inactive',
            "#{styles.tag_subscription_pastdue}": tag.name == 'subscription_pastdue'
          )
          React.createElement("div", { \
            "className": (className),  \
            "key": (i),  \
            "title": (tag.name)
          },
            React.createElement("span", null, "#", (tag.name)),
            (unless tag.hidden
              React.createElement("span", {"className": (styles.close), "onClick": (-> onTagDelete(tag))})
            )
          )
        )
      )
    )

CustomerTagsSelector.propTypes =
  customerId: propTypes.number.isRequired
  activeTags: propTypes.array.isRequired

module.exports = CustomerTagsSelector
