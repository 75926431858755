moment = require 'moment'
{ Link } = require 'react-router-dom'
browserHistory = require './../../../history'

TextareaAutosize = require 'react-textarea-autosize'
classnames = require 'classnames'
_ = require 'underscore'

styles = require './customer-form.styl'
BaseComponent = require 'components/BaseComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
CustomerInfoActionCreator = require 'actions/CustomerInfoActionCreator'
CRMInfoModuleActionCreator = require 'actions/CRMInfoModuleActionCreator'
TagsSelectorComponent = require 'components/tags/TagsSelectorComponent/TagsSelectorComponent'
CustomerTagsSelector = require '../CustomerInfoModule/CustomerInfoComponent/CustomerTagsSelector'
InlineTextInput = require 'components/shared/InlineTextInput/InlineTextInput'
InlineTimezoneInput = require 'components/shared/InlineTimezoneInput/InlineTimezoneInput'
CredentialTypeListComponent = require 'components/shared/CredentialTypeListComponent/CredentialTypeListComponent'
CustomersAPI = require 'api/CustomersAPI'
CredentialsAPI = require 'api/CredentialsAPI'
CredentialsFormComponent = require '../CredentialsFormComponent/CredentialsFormComponent'
emptyAvatarImage = require "!file-loader!components/shared/images/unknown.png"
SwitchComponent = require 'components/shared/SwitchComponent'
BlacklistedWarningModal = require 'components/shared/BlacklistedWarningModal'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
AuthStore = require 'stores/AuthStore'
{ BLACKLIST_EDIT_ACCESS } = require 'constants/Agents'
CustomersActionCreator = require 'actions/CustomersActionCreator'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
CustomerMergeModalComponent = require './CustomerMergeModalComponent'
config = require 'root/config'

getAvatarSrc = (customer) -> emptyAvatarImage

class CustomerFormComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @state =
      customerId: props.id
      attributes: {}
      phone: []
      email: []
      other_credentials: []
      unsaved: false
      saved: false
      credential_errors: []
      submitedAttr: null
      openedMergeForm: false
      limit: 20
      init: true
      foundedIds: []
      sameCustomersLoading: false
      optout: !!(props.customer || {}).optout
      optin: !!(props.customer || {}).optin
      appblock: !!(props.customer || {}).appblock
    @redirect = true
    @initComponent()

  componentWillReceiveProps: (props) ->
    if !@state.submitedAttr?
      credentials = props.customer?.credentials
      parsedCredentials = @parseCredentials credentials
      @setState(Object.assign(parsedCredentials, {
        optout: !!(props.customer || {}).optout
        optin: !!(props.customer || {}).optin
        appblock: !!(props.customer || {}).appblock
      }))

  parseCredentials: (credentials) ->
    result = {
      phone: []
      email: []
      other_credentials: []
    }

    { pluckCredential } = @
    _.forEach(credentials, (credential) ->
      pluckedCredential = pluckCredential credential
      if result[pluckedCredential.credential_type]
        result[pluckedCredential.credential_type].push pluckedCredential
      else
        result.other_credentials.push pluckedCredential
    )
    result

  dependsOnStores: [CRMInfoModuleStore]

  getState: ->
    clientId: CRMInfoModuleStore.crmInfo?.clientId

  requestData: =>
    customer = @props.customer
    return if @props.id == 'new'
    url = config.urls.customers.crmInfo(@props.id)
    setTimeout(
      -> CRMInfoModuleActionCreator.get(customer, url)
      0
    )

  componentWillUpdate: ( props, state ) =>
    if state.searchEnabled && (state.searchLoading == true || @state.query != state.query) && state.query
      CustomersAPI.getList query_string: state.query, size: state.limit,
        ( response ) =>
          customer = _.extend {}, @state.submitedAttr?.customer || @props.customer, @state.attributes, {credentials: _.union [], @state.phone, @state.email, @state.other_credentials}
          getIds = (c)=>(c.id)
          customerReplicaIds = R.map(getIds)(customer?.replicas || [])
          fileredCustomerIds = R.without(R.flatten([@state.customerId, customerReplicaIds]))(response)
          @setState
            searchInit: false
            searchLoading: false
            foundedIds: fileredCustomerIds
        ( errors ) =>
          console.log 'customer api error'
          console.log errors

  startConversation: () =>
    browserHistory.default.push("/conversations/all_conversations/#{@props.lastConversationId}")

  createConversation: () =>
    CustomersAPI.startConversation { id: @props.id },
      (responce) =>
        { conversation } = responce
        browserHistory.default.push("/conversations/all_conversations/#{conversation?.id}")
      (error) => console.log error

  submitForm: (customer, e) =>
    compactedCredentials = _.compact customer.credentials
    compactedCredentials = compactedCredentials.map((credential) ->
      if credential.isDecrypted then credential else id: credential.id
    )
    customer.credentials = compactedCredentials
    customer = _.omit customer, 'created_at'
    customer.optout = @state.optout
    customer.optin = @state.optin
    customer.appblock = @state.appblock
    @setState credential_errors: []
    if @props.new
      setTimeout(
        =>
          CustomersActionCreator.create customer, @onSuccessCreate, @onError
        10
      )
    else
      setTimeout(
        =>
          CustomersActionCreator.update @props.id, customer
        10
      )
    e.preventDefault()

  createCustomer: (customer) =>
    CustomersAPI.create customer, null, @onError

  onSuccessCreate: ({ id }) ->
    browserHistory.default.push("/customers/#{id}")

  onError: (error) =>
    @setState
      unsaved: true
      submitLoading: false
    setTimeout(
      => @setState unsaved: false
      2000
    )

  discardChanges: =>
    credentials = @state.submitedAttr?.credentials.credentials || @props.customer?.credentials
    parsedCredentials = @parseCredentials credentials
    @setState(Object.assign(parsedCredentials, {
      attributes: {}
      credential_errors: []
    }))

  handleClickOutside: =>
    @setState openedTypesList: false

  toggleTypeList: =>
    @setState openedTypesList: !@state.openedTypesList

  toggleMergeForm: =>
    InterfaceActionCreator.openModal CustomerMergeModalComponent, {
      customerId: @state.customerId
    }

  onTypeListItemClick: (type, e) =>
    switch type
      when 'phone' then x = @state.phone.push { credential_type: type, value: null }
      when 'email' then x = @state.email.push { credential_type: type, value: null }
      else x = @state.other_credentials.push { credential_type: type, value: null }
    @setState
      openedTypesList: false

  updateCredential: (field, index, value, isChosen) =>
    newArray = @state[field]
    if value != null
      newArray[index].value = value
      newArray[index].isDecrypted = true
      newArray[index].is_chosen = !!newArray[index].is_chosen
    else
      newArray[index].is_chosen = isChosen
    { credential_type } = newArray[index]
    newArray.forEach((c, i) -> c.is_chosen = false if c.credential_type == credential_type && i != index)

    @setState
      "#{field}": newArray

  destroyCredential: (field, index) =>
    newArray = @state[field]
    newArray[index] = null
    @setState
      "#{field}": _.without(newArray, null)

  onDecryptCredential: (field, index) =>
    fieldId = @state[field][index]['id']
    CredentialsAPI.get fieldId,
      (resource) =>
        pluckedCredential = @pluckCredential resource
        newFieldArray = @state[field]
        pluckedCredential.isDecrypted = true
        newFieldArray[index] = pluckedCredential
        @setState(
          "#{field}": newFieldArray
          credential_errors: []
        )
      (error) =>
        errorArray = @state.credential_errors
        errorArray.push(error)
        @setState(
          credential_errors: errorArray
        )

  pluckCredential: (credential) =>
    id: credential.id
    value: credential.value
    credential_type: credential.credential_type
    is_chosen: credential.is_chosen

  updateCustomerAttribute: (field, value) =>
    if field == 'comment'
      value = value.replace(/[ ][ ]+/g, ' ');
      value = '' if value == ' '
    @setState
      attributes: _.extend {}, @state.attributes, {"#{field}": value}

  unmergeCustomer: (customerId) =>
    CustomersActionCreator.unmergeCustomers @state.customerId, [customerId]

  noToggle: (e) =>
    e.stopPropagation();

  componentDidUpdate: =>
    customer = _.extend {}, @state.submitedAttr?.customer || @props.customer, @state.attributes, {credentials: _.union [], @state.phone, @state.email, @state.other_credentials}
    if customer?.original_id && @redirect
      @redirect = false
      browserHistory.default.push("/customers/#{customer.original_id}")

  onOptoutToggle: (value) =>
    @setState optout: value

  onAppblockToggle: (value) =>
    @setState appblock: value

  toggleBlacklist: (value) =>
    data = optin: value
    data.optout = true if value
    @setState data

  onBlacklistToggle: (value) =>
    if value == @props.customer?.optin
      @toggleBlacklist value
      return null
    @setState sameCustomersLoading: true
    CRMInfoModuleActionCreator.getSameCustomers(
      @state.clientId
      (sameCustomers) =>
        if sameCustomers?.length > 0
          @setState sameCustomersLoading: false
          InterfaceActionCreator.openModal BlacklistedWarningModal, {
            onProceed: => @toggleBlacklist value
            sameCustomers: sameCustomers
          }
        else
          @setState sameCustomersLoading: false
          @toggleBlacklist value
      (error) =>
        @setState sameCustomersLoading: false
        console.error(error)
    )

  render: =>
    # TODO Refactor
    customer = _.extend {}, @state.submitedAttr?.customer || @props.customer, @state.attributes, {credentials: _.union [], @state.phone, @state.email, @state.other_credentials}

    return React.createElement(LoadableWrapperComponent, {"loading": true}) if !@props.customer.id && @props.loading

    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.scroll_container)},
        React.createElement("div", {"className": (styles.simple_row)},
          React.createElement("img", {"className": (styles.avatar), "src": (getAvatarSrc(customer))}),
          React.createElement("div", {"className": (styles.head_content)},
            React.createElement("div", {"className": (styles.simple_column)},
              React.createElement(InlineTextInput, { \
                "isTitle": (false),  \
                "className": (styles.name_field),  \
                "value": (customer.name),  \
                "onSubmit": (R.partial(@updateCustomerAttribute, ['name'])),  \
                "placeholder": "Client's name"
              })
            ),
            React.createElement("div", {"className": (styles.right_column)},
              React.createElement("div", {"className": (styles.created_at)}, ("Created: " + moment(@props.customer.created_at).format("DD MM YYYY, HH:mm"))),
              React.createElement(InlineTimezoneInput, { \
                "className": (styles.time),  \
                "value": (customer.timezone),  \
                "auto_timezone": (customer.auto_timezone),  \
                "onSubmit": (R.partial(@updateCustomerAttribute, ['timezone']))
              }),
              (React.createElement("div", {"className": (styles.conversation_link), "onClick": (@startConversation)}, "Go to Conversation") if @props.lastConversationId),
              (React.createElement("div", {"className": (styles.conversation_link), "onClick": (@createConversation)}, "Create Conversation") if !@props.lastConversationId && @props.id != 'new'),
              React.createElement("div", {"className": (styles.optout)},
                React.createElement("span", {"className": (styles.optout__text)}, "Optout"),
                React.createElement(SwitchComponent, { \
                  "onToggle": (@onOptoutToggle),  \
                  "value": (@state.optout),  \
                  "disabled": (!@state.clientId || customer.options?.auto_optout),  \
                  "auto": (!!customer.options?.auto_optout)
                })
              ),
              React.createElement("div", {"className": (styles.optout)},
                React.createElement("span", {"className": (styles.optout__text)}, "Blacklist"),
                React.createElement(SwitchComponent, { \
                  "onToggle": (@onBlacklistToggle),  \
                  "className": (classnames({ "#{styles.loading_cursor}": @state.sameCustomersLoading })),  \
                  "value": (@state.optin),  \
                  "disabled": (!BLACKLIST_EDIT_ACCESS[AuthStore.user.role] || @state.sameCustomersLoading || !@state.clientId)
                })
              ),
              React.createElement("div", {"className": (styles.optout)},
                React.createElement("span", {"className": (styles.optout__text)}, "Appblock"),
                React.createElement(SwitchComponent, { \
                  "onToggle": (@onAppblockToggle),  \
                  "value": (@state.appblock),  \
                  "disabled": (!@state.clientId)
                })
              )
            ),
            React.createElement(CredentialsFormComponent, { \
              "className": (styles.phone_number),  \
              "credentials": (@state.phone),  \
              "onSubmit": (@updateCredential),  \
              "onDestroy": (@destroyCredential),  \
              "onDecryptCredential": (@onDecryptCredential),  \
              "type": 'phone'
            })
          )
        ),
        React.createElement("div", {"className": (styles.simple_row)},
          React.createElement("div", {"className": (styles.add_info), "onClick": (@toggleTypeList)},
            React.createElement("div", {"className": (styles.add_info_title)}, "Add info"),
            React.createElement("div", {"className": (styles.add_info_icon)}, React.createElement("div", {"className": (styles.plus)}))
          ),
          (if @state.openedTypesList
            React.createElement(CredentialTypeListComponent, { \
              "onItemClick": (@onTypeListItemClick),  \
              "handleClickOutside": (@handleClickOutside),  \
              "classList": (styles.new_type_list),  \
              "classListItem": (styles.new_type_list_item)
            })
          ),
          React.createElement("div", {"className": (styles.middle_container)},
            React.createElement(CredentialsFormComponent, { \
              "className": (styles.other_credentials),  \
              "credentials": (@state.other_credentials),  \
              "onSubmit": (@updateCredential),  \
              "onDestroy": (@destroyCredential),  \
              "onDecryptCredential": (@onDecryptCredential),  \
              "type": 'other_credentials'
            }),
            React.createElement("br", null),
            (if !_.isEmpty @state.email
              React.createElement(CredentialsFormComponent, { \
                "className": (styles.email_credential),  \
                "credentials": (@state.email),  \
                "onSubmit": (@updateCredential),  \
                "onDestroy": (@destroyCredential),  \
                "onDecryptCredential": (@onDecryptCredential),  \
                "type": 'email'
              })
            ),
            (_.map(
              @state.credential_errors
              (error) -> React.createElement("span", {"key": (_.uniqueId()), "style": (color: 'red')}, (error), React.createElement("br", null))
            ))
          )
        ),

        (unless @props.new
          React.createElement("div", {"className": (styles.simple_row)},
            React.createElement("div", {"className": (classnames(styles.left_title, styles.left_title_tags))}, "Tags: "),
            React.createElement("div", {"className": (styles.tags_container)},
              React.createElement(CustomerTagsSelector, { \
                "customerId": (@props.id),  \
                "activeTags": (@props.tags)
              })
            )
          )
        ),

        React.createElement("div", {"className": (styles.simple_row)},
          React.createElement("div", {"className": (styles.left_title)}, "Comments: "),
          React.createElement("div", {"className": (styles.comment_container)},
            React.createElement(TextareaAutosize.default, { \
              "className": styles.textarea,  \
              "placeholder": 'Click to add commentaries',  \
              "onChange": ((e)=> @updateCustomerAttribute 'comment', e.target.value),  \
              "value": (customer.comment || '')
            })
          )
        ),

        (
          React.createElement("div", {"className": (styles.simple_row)},
            React.createElement("div", {"className": (styles.add_info), "onClick": (@toggleMergeForm)},
              React.createElement("div", {"className": (styles.add_info_title)}, "Merge account"),
              React.createElement("div", {"className": (styles.add_info_icon)}, React.createElement("div", {"className": (styles.plus)}))
            ),
            React.createElement("div", {"className": (styles.left_title)}, "Merged:"),
            React.createElement("div", {"className": (styles.merged_container)},
              (
                if customer.replicas?.length > 0
                  customer.replicas.map (replica) =>
                    React.createElement("div", {"className": (styles.merged_item)},
                      React.createElement(Link, { \
                        "to": ("/customers/#{replica.id}"),  \
                        "className": (styles.merged_credential),  \
                        "onClick": ((e) -> e.preventDefault())
                      },
                        React.createElement("span", null, (replica.name))
                      ),
                      React.createElement("span", { \
                        "className": (styles.merged_item_unmerge),  \
                        "onClick": (R.partial(@unmergeCustomer, [replica.id]))
                      }, """
                        x Unmerge account
""")
                    )
                else
                  React.createElement("div", {"className": (styles.main_text)}, "No merged customers")
              )
            )
          )
        ),

        React.createElement("div", {"className": (styles.simple_row)},
          React.createElement("div", {"className": (styles.buttons)},
            React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
              React.createElement("div", {"className": (styles.button_save), "onClick": (R.partial(@submitForm, [customer]))}, """
                Save Changes
"""),
              React.createElement("div", {"className": (styles.button_discard), "onClick": (@discardChanges)}, """
                Discard changes
"""),
              (React.createElement("div", {"className": ( styles.popup_error )}, "Unsaved") if @state.unsaved),
              (React.createElement("div", {"className": ( styles.popup_saved )}, "Saved") if @state.saved)
            )
          )
        )
      )
    )

module.exports = CustomerFormComponent
