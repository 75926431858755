module.exports =
  myConversations:
    menu: 'Congrats! You can start using one of the main tools for ' +
          'CSRs. First thing I want you to pay attention to is left-side ' +
          'menu which consists of several tabs. We will review the ' +
          'functionality of each tab today.'
    myMenu: 'This is “My conversations” tab where you can track all your ' +
            'active chats.'
    takeNew: 'You have to take new chats via <b>“Take new”</b> button. This button ' +
             'has 3 possible states:<br/>Grey and inactive means there are ' +
             'no new unassigned chats at the moment<br/>Blue and active - you can take ' +
             'a new conversation.</br>Red and active - you have to take a ' +
             'new chat.<br/>As you can see, this button is inactive and it ' +
             'means that there are no new clients at this moment.'
    statistic: 'Next to “Take new” button you can easily check your ' +
               '<b>statistics</b>.<br/>UC - the amount of unique clients served by ' +
               'you during this shift.<br/>RT - your average response time ' +
               'for this shift.'
    shortcut: 'This is a conversation shortcut. You can quickly check ' +
              'client’s name, time when the last message from the client ' +
              'was received and the message itself. If you have more than 1 ' +
              'assigned conversation you’ll be able to check the whole ' +
              'list here as well.'
  reminders:
    step1: 'This is how a reminder pops up when the time comes.'
    step2: 'Sometimes you need to reschedule the reminder for later. Just ' +
           'hover the mouse over it and snooze for a needed amount of time.'
  conversation:
    step1: 'Chat view looks like this.'
    step2: 'In the chat window you can see operator’s messages'
    step3: 'Client’s messages'
    step4: 'Date and time when the message was sent'
    step5: 'Actions in the chat - status change, assigns, unassigns, setting ' +
           'reminders and tags, etc'
    step6: 'To see old chats you don’t need to scroll. Just select the ' +
           'needed date and time of the conversation and open it.'
    step7: 'This is a field for typing your messages. You can also keep ' +
           'track of your message length by looking at the word count in ' +
           'the right upper corner of this field.'
    step8: 'There is an ability to resize the message window.'
    step9: 'Once your message is ready you have to click on “Send” button.'
    step10: 'Click on this button to attach files to your message. Remember, ' +
            'we can’t send files to SMS clients.'
    stepgg10: 'There are 2 options to preview files in the conversation ' +
              'with the client or any order files section:\n1st option - ' +
              'Quick pop-up for pics only initiated by a click on the ' +
              'first icon.'
    stepgg11: '2nd option - detailed plugin with all kinds of files and ' +
              'pics initiated by a click on the second one. The detailed ' +
              'preview is available for the most common file formats such ' +
              'as word, ppt, pdf, excel + jpeg, png, bmp, gif formats.'
    stepgg12: 'You will have an option to select all files in active chat ' +
              'by choosing at least one file and clicking on the ' +
              'corresponding button at the top left corner'
    step11: 'By clicking on this button you’ll be able to look through all ' +
            'canned messages.'
    step12: 'You can see the full list of available  saved replies for CSR ' +
            'role and also search for them. Autocomplete search through ' +
            'templates will be initiated in 2 seconds once you type 5 ' +
            'symbols in the message input field.'
    steprt1: 'Autocomplete feature can be turned on and off by clicking on ' +
             'the eye icon.'
    steprt2: 'Shortcut panel is displayed right above the message field and ' +
             'gives you an option to send the client one of the most common ' +
             'replies in a single click.'
    steprt3: 'This panel can also be turned on and off according to your ' +
             'preferences.'
    step13: 'Once you click on the needed message it moves to the message ' +
            'field.'
    step14: 'Each conversation has to be tagged appropriately. To set ' +
            'an appropriate conversational or client tag, you will need ' +
            'to press this button.'
    step15: 'When you click on the tag button you’ll see a list with a ' +
            'group of tags.'
    step16: 'When the group of tags is opened you can easily add the needed ' +
            'one.<br/>Please keep in mind that some conversational tags are ' +
            'going to be set automatically, e.g. #revision issue + order ' +
            'number, #paid, still you will need to double-check if all ' +
            'necessary tags are present in the conversation by ' +
            'adding/deleting relevant tags.'
    step16a1: 'All tags from the client tag category will be displayed in ' +
              'the sidebar menu once you choose it from the menu.<br>You ' +
              'will be able to delete set client tags if needed from the ' +
              'sidebar by pressing the ‘x’ next to the tag.'
    step17: 'This button allows you to make your message more friendly :) ' +
            'But don’t abuse this option.'
    step17a1: 'This nifty button will allow you to download all the files ' +
              '- pictures and documents -  from the conversation with ' +
              'the client as a zip archive using one button.'
    step18: 'Here you can check who is assigned to this particular chat. ' +
            'Also, you can unassign yourself by simply clicking on your name.'
    step19: 'There may be rare cases when you need to choose another channel ' +
            'to send the message. You can choose a channel by clicking on ' +
            'this field.'
    step20: 'Chat status can be changed right here. There are 4 possible ' +
            'statuses of the chat:<br/>New - the last message in the chat is ' +
            'from the client or a reminder/system message<br/>Opened - the ' +
            'last message in the chat is from the operator<br/>Dormant - is set ' +
            'automatically when there is no activity in the chat for 15 mins<br/>' +
            'Closed - is to be set manually when you have done everything ' +
            'needed in the system to address client’s request'
    step21: 'This button allows you to set a reminder.'
    step22: 'This is a window to create reminders. To set a reminder you ' +
            'have to enter the text and choose a date and  time. Then press ' +
            '“Save Reminder” button.'
    step23: 'This button is used to create a schedule message. ' +
            'For now we do not use this functionality.'
    step23x1: 'There are two search fields in My conversations tab.\nUsing ' +
              'this search field you can search between the chats assigned ' +
              'to you at the moment.'
    step23x2: 'And this one will help you find information in one chosen chat.'
    step24: 'In this window you can: create a message, choose date and time ' +
            'when the message should be sent, edit message, delete message.'
    step25: 'There are 2 search fields in My conversations tab. You can ' +
            'search between the chats and inside 1 chat.You can select ' +
            'between Full Search and Partial Search in both fields.\nFull ' +
            'Search (FS) results will show only 100% match within all ' +
            'conversation history. Partial Search (PS) results will show ' +
            'partial match results.'
    step26: 'We’re moving to the right-side menu which is called Sidebar. ' +
            'Sidebar in SMS-Tool consists of several fields and allows ' +
            'Nerdify operators to process main client’s requests without ' +
            'switching between Albatross and SMS-Tool tabs.<br/>' +
            'Sidebar gives you access to the following tabs:<br/>' +
            '- Client info<br/>- Comments section<br/>- Orders<br/>' +
            '- Payments<br/>- Communication'
    step27: 'Client info tab allows you quickly check main information about ' +
            'the client.  You can add client’s local time or time zone, ' +
            'edit client’s email, add or delete client’s tags.'
    step28: 'By clicking on this button you can add additional details ' +
            'about the client such as email, phone number, FB account etc.'
    step29: 'When the client asks us to stop texting them, we turn on ' +
            '“OptOut” switch.<br/>However, if the client changes their mind ' +
            'and decides to continue communication with us, you need to ' +
            'turn off this switch first before sending them a message.'
    step30: 'If the client was Blacklisted by Financial Manager or ' +
            'Shift TL, you’ll be able to see “Blacklist” switch turned ' +
            'on. Changing this switch status is not possible for operators.'
    stepappbl1: 'Appblock switch allows to prevent messages from being sent ' +
                'to a mobile application used by the Client as a personal ' +
                'account.'
    step31: 'Here you can set client’s tags when needed.'
    step32: 'Client plugin button gives you wide functionality.'
    step33: 'Right after clicking on Client Plugin button you’ll see a ' +
            'window with the detailed information about this client.'
    step34: 'Comments section allows to leave specific comments about the ' +
            'client, e.g. English is not their first language, APA formatting ' +
            'for PSYC course, etc'
    step35: 'Orders tab gives you access to the list of all placed orders.'
    step36: 'You can check the number of placed orders here'
    step37: 'You will be able to see a full orders list by clicking ' +
            '"All orders" button.'
    step38: 'Every placed order has its own number in our system. It ' +
            'consists of project code - AN for Nerdify orders, year, ' +
            'month and date. The last digits are the serial number of the order.'
    step39: 'These several numbers/letters next to the order number are ' +
            'the payment key of the order. They are the last digits of ' +
            'the payment link for this order.'
    step40: 'Here you can easily check the order and writer status of the order.<br/>' +
            'For example, “Unpaid” orders will always have “Not offered” ' +
            'writer status, while “Paid” orders may have “Not found”, ' +
            '“In work”, “Completed”, etc writer statuses.'
    step41: 'Moreover, writer’s status of this order is also visible to us'
    step42: "When you hover the mouse over the order number, you'll be able " +
            "to see the title of this order."
    stepgg42: 'Here is “Order Search” field. You will be able to find ' +
              'the order you are interested in using its number or title. ' +
              'Results of the search will include all client\'s actual ' +
              'orders - without draft/assignments. Please mind that the ' +
              'search will start when you input at least 3 symbols in the ' +
              'search field.'
    step43: 'By clicking on “New order” button you’ll be redirected to an ' +
            'order form that allows you to place an order according to the ' + '
            client\'s request. You can move this window to any part of ' +
            'the screen.'
    step44: 'This is a Quick order form. It opens in a separate window and ' +
            'it is basically a draft of an order that you are creating.'
    step45: 'Filling in red fields in order form is obligatory. You have ' +
            'to select appropriate descriptive order title, order type, ' +
            'number of words/pages/mins/slides.'
    step46: 'Once you entered the title of the order, don’t forget to hit ' +
            '‘Update’. Otherwise the changes won’t be saved.'
    step47: 'You can set order deadline by choosing an exact date on the ' +
            'calendar after clicking on “Done by” and hitting “Update” button.'
    step48: 'Or you can set order deadline by setting the amount of days ' +
            'after clicking on “Done in” and hitting “Update” button.'
    step49: 'Filling in red fields in order form is obligatory. You have ' +
            'to select appropriate descriptive order title, order type, ' +
            'number of words/pages/mins/slides.'
    step51: 'If you already have all necessary files ready, click here to ' +
            'attach them to the order. Note, if there are more than 5 files ' +
            'you have to attach them in zip file. You can also attach files ' +
            'later on in Full Order form.'
    step52: 'Order price will be automatically generated once all the ' +
            'details were added to the order. The total price will be ' +
            'depicted in this section.'
    step53: 'This section shows the amount of money on client’s balance ' +
            '(if there is any).'
    step54: 'Once the price is automatically calculated, “Send calculation ' +
            'to chat” button becomes available.'
    step55: 'This option will send a following price explanation of ' +
            'this order to your chat with the client. NOTE: Please do ' +
            'not forget to adjust necessary variables.'
    step56: 'If you don’t want this order to be saved in the system, ' +
            'just click “Cancel”.'
    step57: 'If you want to save the order and move forward, press ' +
            '“Create order”.'
    step58: 'By clicking on “New order” button you’ll be redirected to an ' +
            'order form that allows you to place an order according to the ' +
            'client\'s request. You can move this window to any part of the ' +
            'screen.'
    step59: 'Here you can see order number, name of the operator who ' +
            'created this order and date of order creation'
    step60: 'Order and writer statuses are also displayed here.'
    stepx1: 'Filling in red fields in order form is obligatory. You have ' +
            'to select appropriate descriptive order title, main and ' +
            'additional task types, number of words/pages/mins/slides.'
    stepy1: 'Choosing additional task type is also obligatory for any type ' +
            'of order.\nThe main distinction in additional task types lies ' +
            'in where the final paper should be submitted - on client\'s ' +
            'dashboard or in the order form. You will be able to choose ' +
            'the specific subtype using further options.'
    stepy2: 'For instance, if you place an order that requires the Writer ' +
            'to complete a discussion board on Client’s dashboard, you should ' +
            'choose “Submit to online Dashboard: Discussion post” as ' +
            'additional task type.\nPlease remember that filling out this ' +
            'additional task type is obligatory for any client\'s requests, ' +
            'from simple movie reviews and research papers to online quizzes ' +
            'to be completed on the dashboard and SPSS tasks.'
    stepy3: 'Once all the details were added to the order, you will be able ' +
            'to see the order total price here. It shows total price in ' +
            'different currencies, including AUD and CAD. Choose the needed ' +
            'one depending on the client you talk to.'
    stept1: 'After choosing the subtype, you will see optional order ' +
            'elements. The list of these elements will depend on the chosen ' +
            'order subtype. They will be color-coded according to the ' +
            'clarification stages (prior to price introduction, after ' +
            'the payment is made etc.)'
    steptx1: 'Press on one of the order elements (Formatting style in ' +
             'our case) and you’ll see a list of possible options to ' +
             'choose from.'
    stept2: 'Also, you have an option of sending an automatic template to ' +
            'the client to clarify the needed order element. Just press ' +
            '“Ask’ button and the window with the template will pop out. ' +
            'You can adjust the template in the window. After that you ' +
            'have 2 options:<br/>  - sending a message directly to the ' +
            'client<br/>  - copying a message to clipboard and after that to ' +
            'the client'
    stepx2: 'You can set the order deadline by choosing an exact date on the ' +
            'calendar after clicking on “Done by” and hitting “Update” button.'
    stepx3: 'Or you can set the order deadline by setting the amount of ' +
            'days after clicking on “Done in” and hitting “Update” button.'
    stepx4: 'Here you may add the info about the number of sources or ' +
            'formatting style requested by the client.'
    stepx5: 'Here you can also choose Type of English to be used in the ' +
            'order. \'Not specified\' means that the Writer will use ' +
            'American English by default.'
    stepx6: 'Please note that the order description should not be longer ' +
            'than 500 words. You will be able to keep track of the words you ' +
            'have already input in the word count here. Order description ' +
            'template will be available once the order subtype is chosen.'
    stepx7: 'Here is how the information you’ve put into the fields will ' +
            'be displayed in the order form.'
    stepx8: 'Here you will be able to see the amount of money on the ' +
            'client\'s balance (if there is any).'
    stepx9: 'You also will be able to add relevant ‘Add-ons’ to the order ' +
            'in the following section.'
    stepx10: 'Once you check the box next to the title of the needed add-on, ' +
             'the order price will be adjusted according to the add-on ' +
             'price. Remember that all add-ons have different prices.'
    stepx11: 'By hovering over the "Info", you’ll see our pricing matrix. ' +
             'It is made for your convenience, in case the client would ' +
             'like to negotiate the price.'
    stepx12: 'By clicking on the “Additional options” you’ll be able to ' +
             'choose the needed template to send to the Client. All of ' +
             'them are related to payment procedures: sending Nerd ' +
             'introduction, price explanation, payment link or recap ' +
             'message. Also, you are able to copy the payment link itself.'
    stepx13: 'By clicking on “Tips unpaid” you’ll be able to see the window ' +
             'with the template offering the client to tip the Nerd and get ' +
             'credits to the Nerdify balance this way. Template can be sent ' +
             'only if the client rates the order with 5 stars or directly ' +
             'asks you if it’s possible to tip the Nerd.'
    stepx14: 'Info specified in this field will be visible to the Writer ' +
             'only when they accept the order. Any Client\'s personal ' +
             'info is to be specified here.'
    stepx15: 'Click on “Client files” to attach properly titled files ' +
             'to the order. All the attached files should be uploaded ' +
             'to the correct category section:<br/>1. Instructions: ' +
             'pics/screenshots/files with the task instructions<br/>2. ' +
             'Sources: obligatory sources that should be used in ' +
             'order to complete the task appropriately<br/>3. Grading ' +
             'rubric: separate grading guide that explicitly represents ' +
             'the performance expectations for an assignment or piece of ' +
             'work<br/>4. Readings: materials/sources that «would be good» ' +
             'to use in order to prepare for an exam/quiz/test or use as ' +
             'additional sources to complete the task, still they are not ' +
             'obligatory to use.<br/>5. Additional materials: any other ' +
             'materials provided by the Client that do not fit any of the ' +
             'above mentioned categories'
    stepx16: 'To create a new ticket you will need to click on “Create ' +
             'ticket” button in the order. It will be available only ' +
             'after the order has been paid. It’s impossible to create ' +
             'tickets in unpaid orders. The ticket form consists of ' +
             'several fields:<br/>- Reference order (mandatory)<br/>- Type ' +
             'and subtype of the ticket (mandatory)<br/>- Deadline - ' +
             'will be set automatically, depending on the ticket ' +
             'type/subtype (mandatory)<br/>- Team role (mandatory)<br/>- ' +
             'Comments (optional)<br/>It’s very important to make it ' +
             'active when all the fields are filled in and click on ' +
             '“Create” button to save your ticket in the system.'
    stepx17: 'Editing the order deadline via CTZ DDL is also possible by ' +
             'changing the date on the calendar or time on the clock.'
    stepx18: 'You have a possibility to rename files in the order form. ' +
             'You will be able to rename client files from the client ' +
             'files section itself (just click on vertical ellipsis and ' +
             'choose “Rename”) OR from the plugin with files preview when ' +
             'the preview is initiated from the order ' +
             'form.<br/><br/>Similarly you can edit uploaded PDF files ' +
             'right in the order form by choosing “Edit” from the list of ' +
             'actions. New context window will pop up ' +
             'automatically.<br/><br/>Right below will be the option to ' +
             'convert text and images/pics/screenshots from scanned ' +
             'PDF document into the editable DOC format - OCR.'
    step601: 'In Full Order Form you may add the info about number of ' +
             'sources or formatting style requested by the client.'
    step701: 'Here you can also choose Type of English to be used in the ' +
             'order. \'Not specified\' means that the Writer will use ' +
             'American English by default.'
    step702: 'If your client told you a discount code they’d like to have ' +
             'applied to their order, you need to apply a discount code in ' +
             'this field. When the discount code is applied, you will be ' +
             'able to see the discount amount as well as the discounted price.'
    step602: 'Click here to add description.'
    step603: 'After you are done filling in order description form, do not ' +
             'forget to hit “Update”, otherwise none of the information ' +
             'will be saved.'
    step604: 'Once all the details were added to the order, you will be ' +
             'able to see the order total price here.'
    step605: 'Here you will be able to see the amount of money on the ' +
            'client\'s balance (if there is any).'
    step606: 'You can copy the payment link of this order right here. ' +
             'NOTE: if order description field is empty, you won’t be able ' +
             'to copy the payment link.'
    step607: 'Once the payment link is sent you are to check “Payment link ' +
             'sent” box. This way if the client forgets to make the payment ' +
             'for the order, they will receive an auto follow-up message ' +
             'from us in 24 hours.'
    step608: 'If you checked “Plag report” box in Quick Order Form, ' +
             'this information will be transferred to Full Form as well.'
    step609: 'These 2 sections are needed to store any order related ' +
             'comments that our writers need to know. Please save client’s ' +
             'personal information or any login information for “Comments ' +
             'for Assigned Writer” field.'
    step6010: 'Click on “Client files” to attach properly titled files to ' +
              'the order. If ' +
              'there are more than 5 files you have to attach them in zip file.'
    step61: 'It’s very important to click on Done button. Otherwise, ' +
            'your order won’t be saved.'
    step610: 'You can minimize order form by clicking this button.'
    step6101: 'Order form will be downsized to this window with full order ' +
              'number. To get back to the regular sized window just click ' +
              'on the order number.'
    step611: 'If the order consists of several types of tasks ' +
             '(writing + presentation) you will have to create a complex ' +
             'order. A complex order can be created only as an ' +
             'adjustment to the existing order meaning you have to ' +
             'create a regular order first and add order extras after that.'
    step612: 'In order to create a complex order, you have ' +
             'to:<br/><br/>click on the "measured in" field in the order ' +
             'form<br/>click on "Add more"<br/>select type of your ' +
             'request and specify the number of pages/words/slides/minutes ' +
             'that are ordered.<br/>click “Add”<br/>A new part of the order ' +
             'will be saved and the payment link will be ' +
             'updated.<br/><br/>You will be able to add multiple parts ' +
             'of the order at a time, e.g. slides + words + hours of work.'
    step62: 'This tab gives you the opportunity to review all information which ' +
            'is related to payments.'
    step63: 'Here you can quickly check the available client\'s balance.'
    step64: 'By clicking on All payments button you’ll be able to check the ' +
            'list of transactions which are related to this client.'
    step65: 'This is client’s personal referral link'
    step66: 'There are links to the client\'s profile aka Nerdify Account. ' +
            'You can use "Place new order link" when you offer the client ' +
            'to place a new order in the cabinet'
    step66x: 'This link will allow the Client to check out information about ' +
             'our subscription and subscribe to it to receive awesome perks.'
    step66y: 'File sharing link should be offered every time you offer the ' +
             'client to send files via email (except the Nerdify App users, ' +
             'as they can send files/pics directly via App)'
  communication:
    step1: 'By clicking on “All threads” button you’ll be able to check ' +
           'the Threads lists of the orders (communication inside of the ' +
           'order between writers, OFFF and QAFF).'
  ticketingNew:
    step0: 'To create a new ticket you will need to click on “Create ' +
           'ticket” button in the order.'
    step1: 'You can check the ticket form which ' +
           'consists of several fields<br/>' +
           '- Reference order (mandatory)<br/>' +
           '- Type and subtype of the ticket (mandatory)<br/>' +
           '- Deadline - will be set automatically, depending on the ticket ' +
           'type/subtype (mandatory)<br/>- Team role (mandatory)<br/>' +
           '- Comments (optional)'
    step2: 'It’s very important to make it active when all the fields are ' +
           'filled in.'
    step3: 'Click on Create button to save your ticket in the system.'
  customers:
    step1: 'Customers tab allows you to search for the clients and create ' +
           'new ones when needed.'
    step2: 'This is a search field.'
    step3: 'Click on “Go to conversation” button if you need to contact the ' +
           'client.'
  agents:
    step1: 'This is a storage of your shifts key performance indicators. ' +
           'You can sort them by shifts, weeks and months.'
    step1x: 'Enabled “Sound notifications” feature allows to receive ' +
            'melody alert to a new message in chats that are not in your ' +
            'view at this particular moment.'
    step2: 'Here you can check the amount of bonuses you’ve earned per any ' +
           'given shift.'
    step2x1: 'And by checking this column you will be able to see whether ' +
             'you will get these bonuses - “approved” - or not - “striked”.'
  ranking:
    step1: 'This tab gives you an opportunity to check all of your chats.'
    step2: 'You can sort this data by days, weeks and months.'
    step3: 'Besides, you can search for chats using this filter.'
    step4: 'Don’t forget to log out when your shift is finished.'
    step5: 'By clicking on the chosen shift you can check the whole list of ' +
           'your chats during this shift. If the chat is striked it’s ' +
           'marked as Bad. You can also check unranked chats and bad chats.'
    step6: 'To check your mistake you are to click on the chat shortcut.'
    step7: 'Striked message is highlighted in yellow.'
    step8: 'You can also check the comments from QA TL.'
    step9: 'If your chat is good, but QA TL left a comment in it with some ' +
           'advice on how the situation could have also been handled, for ' +
           'example, the ‘good’ will be in yellow color.'
  dashboard:
    step1: 'Dashboard tab gives you detailed statistics on the shift workload ' +
           'and performance. Here you can check the number of unique ' +
           'conversations per shift, unique clients pers shift, ' +
           'response and wait time. '
