introText = require 'constants/introjs'
config = require 'root/config'
introJs = require('intro.js/intro.js')
fetchMock = require('fetch-mock').default

fetchMock.config.fallbackToNetwork = true
fileResponse = {
  "results":[
    {
      "comment":"",
      "status":0,
      "file":"Milestone Two Guidelines.pdf",
      "group":"task",
      "name":"Milestone Two Guidelines.pdf",
      "tags":[],
      "deleted":false,
      "available_for_plagscan":true,
      "created_at":"2021-08-25T13:37:51.208Z",
      "real_location":"https://s3.amazonaws.com/molab/molab/wt/61263d1ea5d07ecdb0fc54a6/5199b93f-e977-4d24-89c0-f243bc31a6a1/Milestone%20Two%20Guidelines.pdf",
      "subtype":"instructions",
      "owner_role":"operator",
      "location":"https://ue551dknff.execute-api.eu-west-1.amazonaws.com/dev/api/v1/files/temp/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbmZvIjp7Im9yZGVyX2lkIjoiNjEyNjNkMWVhNWQwN2VjZGIwZmM1NGE2IiwiaWRzIjpbIjYxMjY0N2FmZjljNjAwOTNjYzlkMWUwZSJdfSwidXJsIjoiaHR0cHM6Ly9zMy5hbWF6b25hd3MuY29tL21vbGFiL21vbGFiL3d0LzYxMjYzZDFlYTVkMDdlY2RiMGZjNTRhNi81MTk5YjkzZi1lOTc3LTRkMjQtODljMC1mMjQzYmMzMWE2YTEvTWlsZXN0b25lJTIwVHdvJTIwR3VpZGVsaW5lcy5wZGYiLCJmaWxlX25hbWUiOiJNaWxlc3RvbmUgVHdvIEd1aWRlbGluZXMucGRmIiwiZmlsZV90eXBlIjoiYXdzIiwiZXhwIjoxNjM3Njc0NjcxLCJpYXQiOjE2Mjk4OTg2NzEsIndlYmhvb2tfdXJsIjoiaHR0cHM6Ly9vcGVyYXRvci50bi1jb21wYW55LmNvbS9maWxlcy9hcGkvd2giLCJmYWlsdXJlX3VybCI6Imh0dHBzOi8vb3BlcmF0b3IudG4tY29tcGFueS5jb20vZmlsZXMvYXBpL3doX2ZhaWx1cmUifQ.KwR_plt749hwFsClKKt4p9DfV04q055puC6-MyG-sdY",
      "owner_info":{
        "_id":"5a2a825139fe130f8e84ffe0",
        "role":"operator",
        "name":"cs****@***",
        "email":"cs****@***"
      },
      "type": "order_form",
      "owner": "5a2a825139fe130f8e84ffe0",
      "_id": "612647aff9c60093cc9d1e0e",
      "override_pgch": false,
      "size": 465900}
  ]
}
fileMatcher = (url) ->
  s = url.split('/')
  s[3] == 'api' && s[4] == 'v3' && s[5] == 'orders' && s[7] == 'files'
fetchMock.mock(fileMatcher, fileResponse)

CONVERSATION_AFTER_ACTIONS =
  setOptions: (intro) ->
    return intro
      .setOption('disableInteraction', true)
      .setOption('exitOnOverlayClick', false)
      .setOption('exitOnEsc', false)
      .setOption('overlayOpacity', 0.3)
      .setOption('hidePrev', true)

  logout: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.extra-info-dropdown_element__IgVgx'
            intro: introText.ranking.step4
            highlightClass: 'introjs-highlightClass'
          }])
          .start()
      500
    )

  rankingMessage: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.logout(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()

        goodRanking = document.querySelectorAll(
          '[class*="ranking-conversation-item-conversation__conversation-info-tag_good"]'
        )[0]
        goodRanking = goodRanking.parentElement.parentElement.parentElement
        goodRanking.click()
      100
    )
    setTimeout(
      ->
        targetElement.click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.message-wrap_estimate__30HPu'
            intro: introText.ranking.step7
          },{
            element: '.ranking-status-comment__textarea__3rF-Q'
            intro: introText.ranking.step8
          },{
            element: document.querySelectorAll('.ranking-conversation-item-conversation__conversation-info-tag_comment__3CXw5')[0]
            intro: introText.ranking.step9
          },{
            element: '.extra-info-container__3TXFX'
            intro: ''
          }])
          .start()
      2000
    )

  rankingStatus: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.rankingMessage(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        badRanking = document.querySelectorAll(
          '.ranking-conversation-item-conversation__conversation-info-tag_bad__1Hpk2'
        )[0]
        if badRanking
          badRanking = badRanking.parentElement.parentElement.parentElement
        newIntro
          .addSteps([{
            element: '.ranking-filter-selectors-container__3m9n1'
            intro: introText.ranking.step3
          },{
            element: '.ranking-conversations-conversations__list__2NBJC'
            intro: introText.ranking.step5
          },{
            element: badRanking
            intro: introText.ranking.step6
          },{
            element: badRanking
            intro: ''
          }])
          .start()
      1000
    )

  ranking: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.rankingStatus(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Ranking"]'
            intro: introText.ranking.step1
          },{
            element: '.calendar-slider__2l7Yv'
            intro: introText.ranking.step2
          },{
            element: '.ranking-filter-filters__VSBvu'
            intro: ''
          }])
          .start()
      2000
    )

  dashboard: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.ranking(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Dashboard"]'
            intro: introText.dashboard.step1
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Ranking"]'
            intro: ''
          }])
          .start()
      2000
    )

  agents: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.dashboard(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.agent-profile-wrapper-content__3HBuM'
            intro: introText.agents.step1
          },{
            element: '[class^="agent-info-info__activity__"]'
            intro: introText.agents.step1x
          },{
            element: '.base-agent-statistic-head__2dslZ tr td:nth-child(8)'
            intro: introText.agents.step2
          },{
            element: '.base-agent-statistic-head__2dslZ tr td:nth-child(9)'
            intro: introText.agents.step2x1
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Dashboard"]'
            intro: ''
          }])
          .start()
      2500
    )

  customer: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.agents(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.customer-form-conversation_link__3pxHr'
            intro: introText.customers.step3
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Agents"]'
            intro: ''
          }])
          .start()
      1000
    )

  customers: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.customer(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        document.querySelectorAll(
          '.app-component-menuContainer__36Jx3 a[title="Customers"]'
        )[0].click()
      400
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Customers"]'
            intro: introText.customers.step1
          },{
            element: '.search-input-root__3lmmU'
            intro: introText.customers.step2
          },{
            element: document.querySelectorAll(
              '.customer-list-container__1Ei5r .list-item-container__1Smft'
            )[0]
            intro: ''
          }])
          .start()
      1200
    )

  communication: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.customers(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll(
          '.payments_crm_info_module .info-block-title_container__3CGtP'
        )[0].click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.communication_crm_info_module'
            intro: introText.communication.step1
          },{
            element: document.querySelectorAll(
              '.ticketing_crm_info_module .info-block-title_container__3CGtP'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  payments: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.communication(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        element = document.querySelectorAll(
          '[class^="message-form-text_area"]'
        )[0]
        element.value = ''
        event = new Event('input', { bubbles: true })
        event.simulated = true
        element.dispatchEvent(event)
      200
    )

    setTimeout(
      ->
        document.querySelectorAll(
          '.payments_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
        document.querySelectorAll(
          '.orders_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.payments_crm_info_module'
            intro: introText.conversation.step62
          },{
            element: '.payments_crm_info_module ' +
                     '[class^="customer-payments-balance_container"]'
            intro: introText.conversation.step63
          },{
            element: '.payments_crm_info_module [class^="tab-options-all"]'
            intro: introText.conversation.step64
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[0]
            intro: introText.conversation.step65
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[2]
            intro: introText.conversation.step66
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[3]
            intro: introText.conversation.step66y
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[5]
            intro: introText.conversation.step66x
          },{
            element: document.querySelectorAll(
              '.communication_crm_info_module [class^="info-block-title_container"]'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  complexOrderEnd: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.payments(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-details_dropdown_container"]'
            intro: introText.conversation.step612
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="cross-icon_container"]'
            intro: ''
          }])
          .start()
      1000
    )

  complexOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.complexOrderEnd(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="customer-orders-text_container"]')[0].click()
      1000
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class*="order-details_order_detail_complex"]'
            intro: introText.conversation.step611
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class*="order-details_order_detail_complex"]'
            intro: ''
          }])
          .start()
      3000
    )

  minOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.complexOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="plugins-container_header"]'
            intro: introText.conversation.step6101
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container_close"] span',
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton5: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.minOrder(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="plugins-container_minimize"] span',
            intro: introText.conversation.step610
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container_minimize"] span',
            intro: ''
          }])
          .start()
      300
    )

  tickets: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton5(@, targetElement)
          return false
        true
      )
      .onchange((targetElement) ->
        setTimeout(
          ->
            document.querySelectorAll(
              '[class^="plugins-container_container"]'
            )[1].focus()
          500
        )
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll(
              '[class^="plugins-container_container"]'
            )[1]
            intro: introText.ticketingNew.step1
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="create-ticket-plugin_status__"]'
            )[0]
            intro: introText.ticketingNew.step2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="create-ticket-plugin_btn_wrapper"] [type^="button"]'
            )[0]
            intro: introText.ticketingNew.step3
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="cross-icon_container"]'
            )[1]
            intro: ''
          }])
          .start()
      1500
    )

  updateOrderButton75:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton5(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll('[class^="index_menu_wrap__"] ul')[0]
            highlightClass: 'introjs-highlightClass'
            intro: introText.conversation.stepx18
          },{
            element: document.querySelectorAll('[class^="order-plugin_create__"]')[0]
            intro: introText.conversation.stepx16
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="order-plugin_create__"]')[0]
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton72:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton75(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="index_subcategory_header_container__"]')[0].click()
      200
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-files_collapse_content__"]'
            intro: introText.conversation.stepx15
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-files"] [class^="index_menu_icon"]'
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton71:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton72(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll('[class^="comments-for-writers_comments_for_writers_title__"]')[0].parentElement
            intro: introText.conversation.stepx14
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="order-files_section_header__"]')[0]
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton61:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton71(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="plugins-container_container__"]')[1].style.right = '700px'
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: targetElement
            intro: introText.conversation.stepx13
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="cross-icon_container__"]')[1] 
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton51:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton61(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.style.display = ''
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="payment-info_disable_copy_link_block__"] button')[0].click()
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown_content__"]'
            intro: introText.conversation.stepx12
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="styles_tips_dropdown__"] button')[0]
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton4:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton51(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.style.display = 'block'
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: targetElement
            intro: introText.conversation.stepx11
            highlightClass: 'introjs-highlightClass'
          },{
            element: targetElement
            intro: ''
          }])
          .start()
      300
    )

  updateOrderButton3:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton4(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="addons_item"] label')[0].click()
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown_content"]'
            intro: introText.conversation.stepx10
            highlightClass: 'introjs-highlightClass'
          }, {
            element: '[class^="styles_price_info"]'
            intro: ''
          }])
          .start()
      2500
    )


  updateOrderButton2: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton3(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="addons_container__"]'
            intro: introText.conversation.stepx9
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="addons_container"] [class^="button_container"]',
            intro: ''
          }])
          .start()
      300
    )

  descriptionOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton2(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class*="order-description_comment_text"]')[0].rows = 2
      200
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class*="editable-text_value_length"]'
            intro: introText.conversation.stepx6
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-details_order_details_container__"]'
            intro: introText.conversation.stepx7
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="payment-info_payment_info__"]'
            intro: introText.conversation.stepy3
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="payment-info_balance__"]'
            intro: introText.conversation.step605
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="styles_container__"]')[2]
            intro: introText.conversation.step702
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="addons_container"] [class^="button_container"]'
            intro: ''
          }])
          .start()
      500
    )

  deadlineOrder3: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.descriptionOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="deadline_deadline_editable__"]')[2].click()
      200
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="calendar_container__"]'
            intro: introText.conversation.stepx17
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="deadline_deadline_editable__"]')[3]
            intro: ''
          }])
          .start()
      500
    )

  deadlineOrder2: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.deadlineOrder3(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      200
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="editable-remain-time_container__"]'
            intro: introText.conversation.stepx3
            highlightClass: 'introjs-highlightClass'
          },{
            element: targetElement
            intro: ''
          }])
          .start()
      500
    )

  deadlineOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.deadlineOrder2(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
        document.querySelectorAll('[class^="order-details_btn_asc__"]')[2].style.display = ''
      100
    )

    setTimeout(
      ->
        targetElement = document.querySelectorAll('[class^="deadline_deadline_editable__"]')[0]
        targetElement.click()
      200
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="deadline_deadline_calendar"]'
            intro: introText.conversation.stepx2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="deadline_deadline_editable__"]')[1]
            intro: ''
          }])
          .start()
      600
    )

  askOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.deadlineOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        targetElement = document.querySelectorAll('[class^="order-details_btn_asc__"]')[2]
        targetElement.style.display = 'block'
        targetElement.click()
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="plugins-container_container__"]')[1].style.right = '600px'
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: targetElement
            intro: introText.conversation.stept2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="cross-icon_container__"]')[1] 
            intro: ''
          }])
          .start()
      500
    )

  formatOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.askOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown_content"]'
            intro: introText.conversation.steptx1
            highlightClass: 'introjs-highlightClass'
          },{
            element: targetElement
            intro: ''
          }])
          .start()
      500
    )

  firstDeadline: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.formatOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-description_order_detail__"]')[0].click()
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown_list_item"]')[0].click()
      300
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown_list_item"]')[1].click()
      400
    )

    setTimeout(
      ->
        document.querySelectorAll('.order-details_order_details_check__39wdu').forEach (e) ->
          e.classList.add('order-details_checked__1tahv')
        newIntro
          .addSteps([{
            element: document.querySelectorAll('[class^="order-details_order_details"]')[2]
            intro: introText.conversation.stept1
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-format-dropdown_text__"]'
            intro: ''
          }])
          .start()
      2000
    )

  additionalTask: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.firstDeadline(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-description_dropdown_container"]')[0].setAttribute('style', 'z-index: 102!important')
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown_list_item"]')[0].click()
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-types-dropdown_nested_list"]'
            intro: introText.conversation.stepy2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="deadline_deadline_editable"]')[0]
            intro: ''
          }])
          .start()
      500
    )

  calendarOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.additionalTask(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        targetElement.click()
      400
    )

    setTimeout(
      ->
        element = document.querySelectorAll('[class^="editable-text_textarea"]')[0]
        lastValue = element.value
        element.value = 'TEST'
        event = new Event('input', { bubbles: true })
        event.simulated = true
        tracker = element._valueTracker
        if tracker
          tracker.setValue(lastValue)
        element.dispatchEvent(event)
      700
    )
    setTimeout(
      ->
        element = document.querySelectorAll('[class^="editable-text_textarea"]')[0]
        event = new Event('blur')
        event.simulated = true
        event.relatedTarget = document.querySelectorAll('.editableTextBtn')[0]
        element.dispatchEvent(event)
      1000
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="order-details_order_detail"]')[2].click()
      1600
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown_list_item"]')[2].click()
      1700
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="subject-dropdown_text"]')[0].click()
      2500
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="select-list_item"] a')[0].click()
      2800
    )

    setTimeout(
      ->
        targetElement = document.querySelectorAll('[class^="deadline_deadline_editable"]')[0]
        targetElement.click()
      3200
    )

    setTimeout(
      ->
        calendar = document.querySelectorAll('[class^="calendar_date_part"]')[0]
        rows = calendar.children
        columns = rows[rows.length - 1].children
        columns[columns.length - 1].click()
      3300
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="calendar_submit_button"]')[0].click()
      3400
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-description_order_detail__"]')[0].click()
      3700
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-types-dropdown_container"]'
            intro: introText.conversation.stepy1
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="order-description_order_detail__"]')[0]
            intro: ''
          }])
          .start()
      3800
    )

  newOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.calendarOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="tab-options-dropdown_item__"]')[1].click()
      200
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="tab-options-dropdown__"]')[0].hidden = true
      300
    )
    setTimeout(
      ->
        document.querySelectorAll('[class*="order-description_comment_text"]')[0].rows = 5
      3500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="plugins-container_container"]'
            intro: introText.conversation.step58
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container_title"]'
            intro: introText.conversation.step59
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-plugin_statuses"]'
            intro: introText.conversation.step60
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container_content_container"]'
            intro: introText.conversation.stepx1
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-details_order_title_text"]'
            intro: ''
          }])
          .start()
      3700
    )

  ordersTooltip: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.newOrder(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        event = new MouseEvent('mouseover', {
          'view': window
          'bubbles': true
          'cancelable': true
        })
        targetElement.dispatchEvent(event)
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-note"]'
            )[0]
            intro: introText.conversation.step42
          },{
            element: document.querySelectorAll('[class^="search-input-input__"]')[1]
            intro: introText.conversation.stepgg42
          },{
            element: '.orders_crm_info_module [class^="tab-options-new"]'
            intro: ''
          }])
          .start()
      300
    )

  orders: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.ordersTooltip(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        document.querySelectorAll(
          '.orders_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
        document.querySelectorAll(
          '.client_info_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
      500
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.orders_crm_info_module'
            intro: introText.conversation.step35
          },{
            element: '.orders_crm_info_module [class^="tab-options-all"]'
            intro: introText.conversation.step37
          },{
            element: '.orders_crm_info_module [class^="info-block-title_group"]'
            intro: introText.conversation.step36
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-title"]'
            )[0]
            intro: introText.conversation.step38
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-title"]'
            )[0]
            intro: introText.conversation.step39
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-status_text"]'
            )[0]
            intro: introText.conversation.step40
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-text_container"]'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  crm: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '[class^="plugins-container_container"]'
        intro: introText.conversation.step33
        highlightClass: 'introjs-highlightClass'
      },{
        element: '[class^="cross-icon_container"]'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.orders(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      1000
    )

  search: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.base-info-container__1f4ky'
        intro: introText.conversation.step26
      },{
        element: document.querySelectorAll('[class^="info-block-children_container"]')[0]
        intro: introText.conversation.step27
      },{
        element: '.customer-card-credentials-form-button_new__-n9Dc'
        intro: introText.conversation.step28
      },{
        element: document.querySelectorAll('.customer-card-optout__2sITB')[0]
        intro: introText.conversation.step29
      },{
        element: document.querySelectorAll('.customer-card-optout__2sITB')[1]
        intro: introText.conversation.step30
      },{
        element: document.querySelectorAll('.customer-card-optout__2sITB')[2]
        intro: introText.conversation.stepappbl1
      },{
        element: '.comments_section'
        intro: introText.conversation.step34
      },{
        element: '.button-button__tF-Eo'
        intro: introText.conversation.step32
      },{
        element: '.button-button__tF-Eo'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.crm(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  scheduled: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.conversation-header-scheduled_message_button__M9mXt'
        intro: introText.conversation.step23
      },{
        element: document.querySelectorAll('.search-input-input__3h3Zk')[0]
        intro: introText.conversation.step23x1
      },{
        element: document.querySelectorAll('.search-input-input__3h3Zk')[1]
        intro: introText.conversation.step23x2
      },{
        element: '.conversation-header-scheduled_message_button__M9mXt'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.search(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.style.display = ''
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  reminderSnoozeButton: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.scheduled(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.style.display = 'block'
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="reminder-container"]'
            intro: introText.reminders.step2
          },{
            element: '[class^="reminder-container"] [class^="reminder-select_time"]'
            intro: ''
          }])
          .start()
      300
    )

  reminderSnooze: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '[class^="reminder-container"]'
        intro: introText.reminders.step1
      },{
        element: '[class^="reminder-container"] [class^="reminder-select_time"]'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.reminderSnoozeButton(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  reminders: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.modal-modal__3nVDR'
        intro: introText.conversation.step22
        highlightClass: 'introjs-highlightClass'
      },{
        element: '.modal-cross__24fpP'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep 
          CONVERSATION_AFTER_ACTIONS.reminderSnooze(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  tagsGroup: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.hash-tags-container__3UJva'
        intro: introText.conversation.step16
      },{
        element: '.tags_section'
        intro: introText.conversation.step16a1
      },{
        element: '.emoji-picker-container__3OALB'
        intro: introText.conversation.step17
      },{
        element: '.message-form-actions_left_container__3Ly39 .download-all-button-container__2peiK'
        intro: introText.conversation.step17a1
      },{
        element: '.assigned-agents-container__36Qqv'
        intro: introText.conversation.step18
      },{
        element: '.channels-container__3gVLW'
        intro: introText.conversation.step19
      },{
        element: '.conversation-header-select_state__Pfs0C'
        intro: introText.conversation.step20
      },{
        element: '.conversation-header-reminder__1gsDn'
        intro: introText.conversation.step21
      },{
        element: '.conversation-header-reminder__1gsDn'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep 
          CONVERSATION_AFTER_ACTIONS.reminders(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        targetElement.click()
      300
    )
    setTimeout(
      ->
        document.querySelectorAll('.hash-tag-list-item-data__3YmoQ')[0].click()
      1000
    )
    setTimeout(
      ->
        newIntro.start()
      3000
    )

  tags: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.hash-tags-container__3UJva'
        intro: introText.conversation.step15
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.tagsGroup(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
        document.querySelectorAll('[class^="image-preview-data_file__"]')[0].style.display=''
      100
    )
    setTimeout(
      ->
        newIntro.start()
      1000
    )

  replyTemplates: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.reply-templates-manager-container_message__21vPg'
        intro: introText.conversation.step12
      },{
        element: '[class^="message-form-status_autocomplete__"]'
        intro: introText.conversation.steprt1
      },{
        element: '.message-form-text_area__1Fq5k'
        intro: introText.conversation.step13
      },{
        element: '[class^="message-form-container_shortcuts__"]'
        intro: introText.conversation.steprt2
      },{
        element: '[class^="message-form-status_shortcuts__"]'
        intro: introText.conversation.steprt3
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: introText.conversation.step14
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_currentStep == 1
          targetElement.value = 'Selected reply template message'
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.tags(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
        newIntro.start()
      100
    )

  conversation2: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((te) ->
        if @_introItems.length - 1 == @_currentStep
          targetElement.click()
          CONVERSATION_AFTER_ACTIONS.replyTemplates(@, te)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="image-preview-container__"]'
            intro: introText.conversation.stepgg12
          },{
            element: '.reply-template-selector-open_button__3UnqA'
            intro: introText.conversation.step11
          },{
            element: '.reply-template-selector-open_button__3UnqA'
            intro: ''
          }]).start()
      1000
    )

  conversation: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_currentStep == 8
          elements = document.querySelectorAll(
            '[class^="image-preview-data_file__"]'
          )
          elements[elements.length - 1].style.display = 'block'
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.conversation2(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.conversation-container__FvHPv'
            intro: introText.conversation.step1
          },{
            element: document.querySelectorAll('.message-outbox__2ApNn')[0]
            intro: introText.conversation.step2
          },{
            element: document.querySelectorAll('.message-inbox__tSyJ6')[0]
            intro: introText.conversation.step3
          },{
            element: document.querySelectorAll(
                '.message-outbox__2ApNn .message-small__1Imei'
              )[0]
            intro: introText.conversation.step4
          },{
            element: document.querySelectorAll('.log-message-root__1IQpi')[0]
            intro: introText.conversation.step5
          },{
            element: document.querySelectorAll(
              '.conversation-scroll__2OAmT div'
            )[0]
            intro: introText.conversation.step6
          },{
            element: '.conversation-form__1xP97'
            intro: introText.conversation.step7
          },{
            element: '.conversation-resizable_border___W0zm'
            intro: introText.conversation.step8
          },{
            element: '.message-form-submit__2gbat'
            intro: introText.conversation.step9
          },{
            element: '.file-uploader-upload_button__3KWuW'
            intro: introText.conversation.step10
          },{
            element: '[class^="image-preview-preview__"]'
            intro: introText.conversation.stepgg10
          },{
            element: '[class^="image-preview-download__"]'
            intro: introText.conversation.stepgg11
          },{
            element: '[class^="image-preview-checkbox__"] [class^="checkbox-checkbox__"]'
            intro: ''
          }]).start()
      3000
    )

module.exports =
  initMyConversations: () ->
    if config.introEnabled
      setTimeout(
        ->
          CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
            .addSteps([{
              element: '.navigation'
              intro: introText.myConversations.menu
              highlightClass: 'introjs-highlightClass'
            },{
              element: document.querySelectorAll('.menu-svg__3xnUk')[0]
              intro: introText.myConversations.myMenu
            },{
              element: '.take-new-button-button__Vce1I'
              intro: introText.myConversations.takeNew
            },{
              element: '.take-new-conversation-stats__Z4M6X'
              intro: introText.myConversations.statistic
            },{
              element: '.conversation-list-item-container__3hfNB'
              intro: introText.myConversations.shortcut
            },{
              element: document.querySelectorAll(
                '.conversation-list-item-container__3hfNB'
              )[0]
              intro: ''
            }])
            .onbeforechange((targetElement) ->
              if @_introItems.length - 1 == @_currentStep
                CONVERSATION_AFTER_ACTIONS.conversation(@, targetElement)
                return false
              true
            )
            .start()
        2500
      )
