config = require '../config'

module.exports =
  getAnalytic: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getAnalytic
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getAllConversations: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getAllConversations
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getWaitingConversations: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getWaitingConversations
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getResponseTime: (data, success, error) ->
    $.ajax {
      url: config.urls.dashboard.getResponseTime
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }

  getWaitingTime: (data, success, error) ->
    $.ajax {
      url: config.urls.dashboard.getWaitingTime
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }

  getLastDaysByShift: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getLastDaysByShift
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getPercentOfGoodRTWT: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getPercentOfGoodRTWT
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getUniqueClientsByAgents: (success, error)  ->
    $.ajax {
      url: config.urls.dashboard.getUniqueClientsByAgents
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getAgentChatsStatistic: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getAgentChatsStatistic
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getConversationsEachHour: (success, error) ->
    $.ajax {
      url: config.urls.dashboard.getConversationsEachHour
      contentType: 'application/json'
      type: 'GET'
      success
      error
    }

  getMessageErrors: (data, success, error) ->
    $.ajax {
      url: config.urls.dashboard.getMessageErrors
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }

  getGlossaryMessageErrors: (data, success, error) ->
    $.ajax {
      url: config.urls.dashboard.getGlossaryMessageErrors
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }  

  getSLATrackingStatistic: (data, success, error) ->
    $.ajax {
      url: config.urls.dashboard.getSLATrackingStatistic
      contentType: 'application/json'
      type: 'GET'
      data
      success
      error
    }
