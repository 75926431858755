FOLDERS = [
  'unassigned'
  'my_conversations'
  'all_conversations'
]

GROUP_TYPES =
  empty:
    label: 'Choose type'
    value: 'empty'
  limitless:
    label: 'Limitless'
    value: 'limitless'
  limited:
    label: 'Partly limited'
    value: 'limited'
  strict_limited:
    label: 'Strictly limited'
    value: 'strict_limited'

TEXT_FIND_1 = 'dashboard'
TEXT_FIND_2 = 'online'
TAG_NO_NEED = 'no tag needed'
TAG_DASHBOARD = 'dashboard_submit'
TAGS_FOR_DASHBOARD = [
  {
    id: TAG_NO_NEED
    name: TAG_NO_NEED
    checked: false
  },
]

module.exports = {
  FOLDERS
  GROUP_TYPES
  TEXT_FIND_1
  TEXT_FIND_2
  TAG_DASHBOARD
  TAG_NO_NEED
  TAGS_FOR_DASHBOARD
}
