moment = require 'moment'
momentTZ = require 'moment-timezone'
{ apiUrls } = require('@verdaccio/crminfo')
crmUrls = apiUrls

{ROLES} = require './constants/Agents'
{ ORDER_FIELDS } = require './constants/orderFields'

# TODO: need to move to config js after refactoring crminfo

module.exports = (options = {}) ->
  domain = options.domain
  protocol = options.protocol
  fileServerUrl = options.fileServerUrl
  GMTOffset = moment.tz(options.timezoneName).format('Z')
  GMTOffset = GMTOffset.replace ':', ''
  authBackApiHost = options.authBackApiHost

  GMTOffset:  GMTOffset || '+0000'
  domain: domain
  protocol: protocol || "http"
  bugsnag: options.bugsnag || false
  updateNotification: options.updateNotification || false
  reconnectUrl: options.reconnectUrl
  crmPluginEnabled: options.crmPluginEnabled || false
  oauthEnabled: options.oauthEnabled || false
  hotjarId: options.hotjarId || null
  hotjarSv: options.hotjarSv || null
  introEnabled: options.introEnabled || false
  authApiHost: options.authApiHost
  authBackApiHost: authBackApiHost
  mqttUrl: options.mqttUrl
  mqttSound: options.mqttSound || false
  dashboardTags: options.dashboardTags || false
  mqttNotification: options.mqttNotification || false
  crminfo: options.crminfo || {}

  menus: [
    {
      key: 'unassigned'
      name: 'Unassigned'
      path: '/conversations/unassigned'
      accessToPage: [
        ROLES.skilled_agent.value
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.system_agent.value
        ROLES.teamlead.value
        ROLES.fire_fighter.value
        ROLES.side_project.value
        ROLES.ntff.value
      ]
    }
    {
      key: 'my_conversations'
      name: 'My Conversations'
      path: '/conversations/my_conversations'
      accessToSend: [
        ROLES.skilled_agent.value
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.system_agent.value
        ROLES.teamlead.value
      ]
    }
    {
      key: 'all_conversations'
      name: 'All Conversations'
      path: '/conversations/all_conversations'
      accessToPage: [
        ROLES.skilled_agent.value
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.system_agent.value
        ROLES.teamlead.value
        ROLES.fire_fighter.value
        ROLES.side_project.value
        ROLES.ntff.value
      ]  
    }
    {key: 'customers',    name: 'Customers'}
    {
      key: 'channels'
      name: 'Channels'
      path: '/channels'
      accessToPage: [
        ROLES.manager.value
      ]
      systemEditor: true
    }
    {
      key: 'landings'
      name: 'Landings'
      path: '/landings'
      accessToPage: [
        ROLES.manager.value
      ]
      systemEditor: true
    }
    {
      key: 'rotations'
      name: 'Rotations'
      path: '/rotations'
      accessToPage: [
        ROLES.manager.value
      ]
      systemEditor: true
    }
    {
      key: 'agents'
      name: 'Agents'
      accessToEdit: [
        ROLES.manager.value
        ROLES.teamlead.value
      ]
      accessToEditShiftData: [
        ROLES.manager.value
        ROLES.qa_teamlead.value
      ]
      accessToEditTLShiftData: [
        ROLES.manager.value
      ]
      accessToEditRate: [
        ROLES.manager.value
      ]
      anotherAccess: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.teamlead.value
      ]
      reconnectAccess: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.teamlead.value
        ROLES.ntff.value
      ]
      ipBlockAccess: [
        ROLES.agent.value,
        ROLES.newbie_agent.value,
        ROLES.sales.value
        ROLES.ntff.value
      ]
    }
    
    {key: 'dashboard',    name: 'Dashboard'}
    
    {
      key: 'qa-dashboard'
      name: 'QA Dashboard'
      accessToPage: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
      ]
    }
    
    {
      key: 'ranking'
      name: 'Ranking'
      accessToPage: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.system_agent.value
        ROLES.teamlead.value
        ROLES.skilled_agent.value
        ROLES.agent.value
        ROLES.newbie_agent.value
        ROLES.fire_fighter.value
        ROLES.side_project.value
        ROLES.sales.value
      ]
      path: '/ranking?range_type=weekly&begin_date=2019-04-01&end_date=2019-04-07&page=0&per_page=20'

      accessToEdit: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
      ]
      anotherAccess: [
        ROLES.teamlead.value
      ]
      ipBlockAccess: [
        ROLES.agent.value,
        ROLES.newbie_agent.value,
        ROLES.sales.value
      ]
    }
    {
      key: 'messages_ranking'
      name: 'Message Ranking'
      accessToPage: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.system_agent.value
        ROLES.teamlead.value
        ROLES.skilled_agent.value
        ROLES.agent.value
        ROLES.newbie_agent.value
        ROLES.fire_fighter.value
        ROLES.side_project.value
        ROLES.sales.value
      ]

      accessToEdit: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
      ]
      anotherAccess: [
        ROLES.teamlead.value
      ]
      ipBlockAccess: [
        ROLES.agent.value,
        ROLES.newbie_agent.value,
        ROLES.sales.value
      ]
      messagesRanker: true
    }
    
    {
      key: 'csv-export'
      name: 'CSV Export'
      accessToPage: [
        ROLES.manager.value
      ]
    }

    {
      key: 'groups'
      name: 'Groups'
      accessToPage: [
        ROLES.manager.value
      ]
    }

    {
      key: 'tags'
      name: 'Tags editor'
      accessToPage: [
        ROLES.manager.value
      ]
    }

    {
      key: 'settings'
      name: 'Settings'
      accessToPage: [
        ROLES.qa_teamlead.value
        ROLES.manager.value
        ROLES.teamlead.value
      ]
    }

    {
      key: 'answer-template'
      name: 'Answer templates'
      path: '/reply_templates'
      accessToPage: [
        ROLES.manager.value
      ]
    }

    {
      key: 'admin'
      name: 'Admin panel'
      path: '/admin/reminders'
      accessToPage: [
        ROLES.manager.value
        ROLES.teamlead.value
        ROLES.qa_teamlead.value
      ]
    }
  ]

  customerModules: 
    'client-info': true
    'crm-info': true

  urls:
    fileServer:
      link: (link) -> "#{fileServerUrl}#{link}"
      downloadAll: "#{fileServerUrl}/api/v1/files/download_all"
      file: (url, name) -> "#{fileServerUrl}/api/v1/files?url=#{url}&is_link=true&file_name=#{name}"
    admin:
      reminders:
        getList: "#{protocol}://#{domain}/api/admin/reminders"
      scheduledMessages:
        getList: "#{protocol}://#{domain}/api/admin/scheduled_messages"
      customerioEvents:
        getList: "#{protocol}://#{domain}/api/admin/customerio_events"
        pause:   "#{protocol}://#{domain}/api/admin/customerio_events/pause"
        destroy: "#{protocol}://#{domain}/api/admin/customerio_events/delete"
    anomalySend:      "#{protocol}://#{domain}/api/anomaly"
    auth:             "#{protocol}://#{domain}/api" # j-toker builds urls on its own
    oauth:            "#{protocol}://#{domain}/api/auth/auth/oauthapp"
    current:          "#{protocol}://#{domain}/api/current"
    self:             "#{options.authBackApiHost}/self"

    signOut: () ->
      if options.oauthEnabled
        "#{protocol}://#{domain}/api/auth/sign_out?next=true"
      else
        "#{protocol}://#{domain}/api/auth/sign_out"

    utc:
      getOffset:      "#{protocol}://#{domain}/api/get_utc_offset"

    agents:
      getList: "#{protocol}://#{domain}/api/agents"
      create: "#{protocol}://#{domain}/api/agents"
      get: (id) -> "#{protocol}://#{domain}/api/agents/#{id}"
      update: (id) -> "#{protocol}://#{domain}/api/agents/#{id}"
      updateAvatar: (id) -> "#{protocol}://#{domain}/api/agents/#{id}/avatar"
      batch_destroy: "#{protocol}://#{domain}/api/agents/batch_destroy"
      pages_count: "#{protocol}://#{domain}/api/agents/pages_count"
      ping: "#{protocol}://#{domain}/api/agents/ping"
      usersForGroup: "#{protocol}://#{domain}/api/agents/find_by_group"
      closeNotification: (id) ->
        "#{protocol}://#{domain}/api/agents/#{id}/close_notification"

    analytics:
      getUCPOPS: "#{protocol}://#{domain}/api/analytic_conversations/uniq_clients_per_operator_per_shift"
      
    channels:
      getList: "#{protocol}://#{domain}/api/channels"
      create: "#{protocol}://#{domain}/api/channels"
      get: (id) -> "#{protocol}://#{domain}/api/channels/#{id}"
      update: (id) -> "#{protocol}://#{domain}/api/channels/#{id}"
      batch_destroy: "#{protocol}://#{domain}/api/channels/batch_destroy"
      pages_count: "#{protocol}://#{domain}/api/channels/pages_count"
      channelsForGroup: "#{protocol}://#{domain}/api/channels/find_by_group"
      channelsForCustomer: (customerId) ->
        "#{protocol}://#{domain}/api/customers/#{customerId}/channels"
    landings:
      getList: "#{protocol}://#{domain}/api/v1/landings"
      get: (id) -> "#{protocol}://#{domain}/api/v1/landings/#{id}"
      create: "#{protocol}://#{domain}/api/v1/landings"
      update: (id) -> "#{protocol}://#{domain}/api/v1/landings/#{id}"
    rotations:
      getList: "#{protocol}://#{domain}/api/v1/rotations"
      get: (id) -> "#{protocol}://#{domain}/api/v1/rotations/#{id}"
      create: "#{protocol}://#{domain}/api/v1/rotations"
      update: (id) -> "#{protocol}://#{domain}/api/v1/rotations/#{id}"

    customers:
      crmInfo: (id) -> "#{protocol}://#{domain}/api/customers/#{id}/crm_info_encoded"
      orders: (id, page) -> "#{options.crmUrlA5}/users/client/#{id}/orders?page=#{page || 1}&get_main=true&fields="+ORDER_FIELDS.join('+')
      ordersSearch: (id, search) -> "#{options.crmUrlA5}/users/client/#{id}/orders?no_drafts=true&get_main=true&search=#{search || ''}&page=0&fields="+ORDER_FIELDS.join('+')
      ordersWithTags: (id, tag) -> "#{options.crmUrlA5}/users/client/#{id}/orders?page=0&get_main=true&crminfo_tags=#{tag}&fields="+ORDER_FIELDS.join('+')
      checkSameCustomers: (clientId) -> crmUrls.clientSame(clientId)
      getCustomersIds: "#{protocol}://#{domain}/api/customers/customer_ids"
      getList: "#{protocol}://#{domain}/api/customers"
      create: "#{protocol}://#{domain}/api/customers"
      get: (id) -> "#{protocol}://#{domain}/api/customers/#{id}"
      update: (id) -> "#{protocol}://#{domain}/api/customers/#{id}"
      batchDestroy: "#{protocol}://#{domain}/api/customers/batch_destroy"
      pages_count: "#{protocol}://#{domain}/api/customers/pages_count"
      startConversation: (id) -> "#{protocol}://#{domain}/api/customers/#{id}/start_conversation"
      mergeCustomers: (id) -> "#{protocol}://#{domain}/api/customers/#{id}/merge_customers"
      unmergeCustomers: (id) -> "#{protocol}://#{domain}/api/customers/#{id}/unmerge_customers"
      crm: options.crmUrl
      getScheduledMessages: (id) -> "#{protocol}://#{domain}/api/customers/#{id}/scheduled_messages"

    conversations:
      flag:               "#{protocol}://#{domain}/api/conversations/flag"
      getList:            "#{protocol}://#{domain}/api/conversations"
      create:             "#{protocol}://#{domain}/api/conversations"
      get: (id) ->        "#{protocol}://#{domain}/api/conversations/#{id}"
      inboundUrls: (id) -> "#{protocol}://#{domain}/api/conversations/#{id}/inbound_urls"
      urls: (id) -> "#{protocol}://#{domain}/api/conversations/#{id}/urls"
      getMostAwaiting:    "#{protocol}://#{domain}/api/conversations/most_awaiting"
      update: (id) ->     "#{protocol}://#{domain}/api/conversations/#{id}"
      batch_destroy:      "#{protocol}://#{domain}/api/conversations/batch_destroy"
      assignMostWaiting:  "#{protocol}://#{domain}/api/conversations/assign_most_waiting"
      assign:             "#{protocol}://#{domain}/api/conversations/assign"
      unassign:           "#{protocol}://#{domain}/api/conversations/unassign"
      closed: (id) -> "#{protocol}://#{domain}/api/conversations/#{id}/closed"
      rangeClosed: (id) -> "#{protocol}://#{domain}/api/conversations/#{id}/range_closed"
      rejectReservation: "#{protocol}://#{domain}/api/conversations/reject_reservation"
      deferDormantStatus: (id) -> "#{protocol}://#{domain}/api/conversations/#{id}/remember"

    messages:
      getList:        "#{protocol}://#{domain}/api/messages"
      send:           "#{protocol}://#{domain}/api/messages"

    profile:
      shifts: (id) -> "#{protocol}://#{domain}/api/ranking/users/#{id}/shifts"
      weekly: (id) -> "#{protocol}://#{domain}/api/ranking/users/#{id}/weekly"
      monthly: (id) -> "#{protocol}://#{domain}/api/ranking/users/#{id}/monthly"
      shiftsCSV: (id) ->
        "#{protocol}://#{domain}/api/ranking/users/#{id}/shifts.csv"
      weeklyCSV: (id) ->
        "#{protocol}://#{domain}/api/ranking/users/#{id}/weekly.csv"
      monthlyCSV: (id) ->
        "#{protocol}://#{domain}/api/ranking/users/#{id}/monthly.csv"
      updateShift: (userId, shiftId) -> "#{protocol}://#{domain}/api/ranking/users/#{userId}/shifts/#{shiftId}"
    prompts:
      update: (id) -> "#{protocol}://#{domain}/api/prompts/#{id}"

    tags:
      getList: (tagType) ->
        if tagType
          "#{protocol}://#{domain}/api/tags?tag_type=#{tagType}"
        else
          "#{protocol}://#{domain}/api/tags"
      create: "#{protocol}://#{domain}/api/tags"
      update: (id) -> "#{protocol}://#{domain}/api/tags/#{id}"
      destroy: (id) -> "#{protocol}://#{domain}/api/tags/#{id}"
      markAsDeleted: (id) -> "#{protocol}://#{domain}/api/tags/#{id}/mark_as_deleted"
      getAllWithIgnoreDeletedFlag: "#{protocol}://#{domain}/api/tags/get_all"
      generalList: "#{protocol}://#{domain}/api/tags_groups/general_list"
      tagsGroups: "#{protocol}://#{domain}/api/tags_groups"
      tagsGroup: (id) -> "#{protocol}://#{domain}/api/tags_groups/#{id}"
      tagsByGroup: (id) -> "#{protocol}://#{domain}/api/tags_groups/#{id}/tags"
      orderTags: "#{protocol}://#{domain}/api/tags_groups/order_tags"
      conversationTags: "#{protocol}://#{domain}/api/tags?is_popup=true&tag_type=Conversation"

    conversationItems:
      show: (id) -> "#{protocol}://#{domain}/api/conversation_items/#{id}"

    reminders:
      index: "#{protocol}://#{domain}/api/reminders"
      create: "#{protocol}://#{domain}/api/reminders"
      update: (id) -> "#{protocol}://#{domain}/api/reminders/#{id}"
      destroy: (id) -> "#{protocol}://#{domain}/api/reminders/#{id}"

    replyTemplates:
      index: "#{protocol}://#{domain}/api/reply_templates"
      create: "#{protocol}://#{domain}/api/reply_templates"
      get: (id) -> "#{protocol}://#{domain}/api/reply_templates/#{id}"
      update: (id) -> "#{protocol}://#{domain}/api/reply_templates/#{id}"
      batchDestroy: "#{protocol}://#{domain}/api/reply_templates/batch_destroy"

    replyAnswers:
      groups: "#{protocol}://#{domain}/api/reply_groups"
      groupsWithId: (groupId) -> "#{protocol}://#{domain}/api/reply_groups/#{groupId}"
      answers: "#{protocol}://#{domain}/api/reply_templates"
      answersWithId: (id) -> "#{protocol}://#{domain}/api/reply_templates/#{id}"
      answersByGroup: (groupId) -> "#{protocol}://#{domain}/api/reply_groups/#{groupId}/reply_templates"
      favoriteAnswer: (id) -> "#{protocol}://#{domain}/api/reply_templates/#{id}/favorite"
      favoriteAnswers: "#{protocol}://#{domain}/api/reply_templates/favorites"
      updateStatus: (id) => "#{protocol}://#{domain}/api/reply_templates/#{id}/update_status"

    credentials:
      index: "#{protocol}://#{domain}/api/credentials"
      create: "#{protocol}://#{domain}/api/credentials"
      get: (id) -> "#{protocol}://#{domain}/api/credentials/#{id}"
      update: (id) -> "#{protocol}://#{domain}/api/credentials/#{id}"
      batchDestroy: "#{protocol}://#{domain}/api/credentials/batch_destroy"

    tagTargets:
      create: "#{protocol}://#{domain}/api/tag_targets"
      destroy: "#{protocol}://#{domain}/api/tag_targets"
      createOrder: "#{protocol}://#{domain}/api/tag_targets/create_order"
      destroyOrder: "#{protocol}://#{domain}/api/tag_targets/destroy_order"

    settings:
      getList: () -> "#{protocol}://#{domain}/api/settings"
      update: () -> "#{protocol}://#{domain}/api/settings"
      getByType: "#{protocol}://#{domain}/api/settings/find_by_type"

    ranking:
      shifts:
        createTeamleadShift: "#{protocol}://#{domain}/api/ranking/shifts/create_teamlead_shift"
      postConversationEstimate: "#{protocol}://#{domain}/api/ranking/conversations"
      getClosedConversations: "#{protocol}://#{domain}/api/ranking/conversations"

    rankingMessage:
      getMessages: "#{protocol}://#{domain}/api/message_tags/take_new"
      getMessagesGroups: "#{protocol}://#{domain}/api/message_tags/groups"
      getConversation: (id) -> "#{protocol}://#{domain}/api/message_tags/#{id}"
      rank: "#{protocol}://#{domain}/api/message_tags/rank"

    qaDashboard:
      getList: "#{protocol}://#{domain}/api/ranking/users"
      getCSVList: "#{protocol}://#{domain}/api/ranking/users.csv"
      updateUser: "#{protocol}://#{domain}/api/weeks/update_user"

    dashboard:
      getAnalytic: "#{protocol}://#{domain}/api/analytic_conversations"
      getAllConversations: "#{protocol}://#{domain}/api/conversations/all"
      getWaitingConversations: "#{protocol}://#{domain}/api/conversations/waiting"
      getResponseTime: "#{protocol}://#{domain}/api/analytic_conversations/response_time"
      getWaitingTime: "#{protocol}://#{domain}/api/analytic_conversations/waiting_time"
      getLastDaysByShift: "#{protocol}://#{domain}/api/analytic_conversations/last_days_by_shift"
      getPercentOfGoodRTWT: "#{protocol}://#{domain}/api/analytic_conversations/percent_of_good_rt_and_wt"
      getUniqueClientsByAgents: "#{protocol}://#{domain}/api/analytic_conversations/unique_clients_by_agents"
      getAgentChatsStatistic: "#{protocol}://#{domain}/api/analytic_conversations/agent_chats_statistic"
      getConversationsEachHour: "#{protocol}://#{domain}/api/analytic_conversations/conversations_each_hour"
      getSLATrackingStatistic: "#{protocol}://#{domain}/api/shift_statistic"
      getMessageErrors: "#{protocol}://#{domain}/api/failed_messages"
      getGlossaryMessageErrors: "#{protocol}://#{domain}/api/codes"

    csvExport: 
      get: "#{protocol}://#{domain}/api/csv-export"
      download: (path) -> "#{protocol}://#{domain}/static/csv/#{path}.csv"

    attach_file: "#{protocol}://#{domain}/api/attach_files"

    groups:
      getGroups: "#{protocol}://#{domain}/api/groups"
      getGroupPages: "#{protocol}://#{domain}/api/groups/get_page_count"
      createGroup: "#{protocol}://#{domain}/api/groups"
      deleteGroup: (id) -> "#{protocol}://#{domain}/api/groups/#{id}"
      getAllOptions: "#{protocol}://#{domain}/api/groups/find_all_options"
      getGroupComposition: (id) -> "#{protocol}://#{domain}/api/groups/#{id}"
      updateGroup: (id) -> "#{protocol}://#{domain}/api/groups/#{id}"

    getCustomerTickets: () ->
      crmUrls.tickets()

    getApps: () ->
      crmUrls.profile()

    scheduledMessages:
      create: "#{protocol}://#{domain}/api/scheduled_messages"
      update: (id) -> "#{protocol}://#{domain}/api/scheduled_messages/#{id}"
      destroy: (id) -> "#{protocol}://#{domain}/api/scheduled_messages/#{id}"
