BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.CUSTOMER_CRM_REQUESTING}": ({ customer }) ->
    @loading = true
    @error = null
    @comments = null
    @payments = null
    @communication = null
    @externalClientId = null
    @profileLink = null
    @currentCustomer = customer
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_SUCCESS}": ({ data, customer }) ->
    return if customer.id != @currentCustomer.id
    @loading = false
    @comments = data.accesses
    @payments = data.crmInfo
    link = data.crmInfo['OrderLink']
    if link && customer.upload_id
      try
        url = new URL(link)
        @payments['CustomerSharingLink'] = "#{url.origin}/files/#{customer.upload_id}"
      catch
    @crmInfo = data.crmInfo
    @communication = data.communications
    @profileLink = (data.crmInfo || {}).clientProfileLink
    @externalClientId = (data.crmInfo || {}).clientId
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class CRMInfoModuleStore extends BaseStore
  constructor: ->
    super()
    @comments = null
    @payments = null
    @communication = null
    @loading = false
    @error = null
    @externalClientId = null
    @currentCustomer = null
    @profileLink = null
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new CRMInfoModuleStore()
