moment = require 'moment'
classnames = require 'classnames'
BaseComponent = require 'components/BaseComponent'
RankingActionCreator = require 'actions/RankingActionCreator'
AuthStore = require 'stores/AuthStore'
Select = require 'components/shared/Select'
RemindersStore = require 'components/reminders/RemindersComponent/RemindersStore'
RemindersActionCreator = require 'components/reminders/RemindersComponent/RemindersActionCreator'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
{ crmPluginEnabled } = require 'root/config'

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs

styles = require './reminder'
propTypes = require 'prop-types'

TIME_REMAINDER_MINS =
  TIME_1: 30
  TIME_2: 60
  TIME_3: 120
  TIME_4: 240
  TIME_5: 720

TIME_REMAINDER_MINS._ALL = [
  TIME_REMAINDER_MINS.TIME_1,
  TIME_REMAINDER_MINS.TIME_2,
  TIME_REMAINDER_MINS.TIME_3,
  TIME_REMAINDER_MINS.TIME_4,
  TIME_REMAINDER_MINS.TIME_5
]

# Dumb component
# class ReminderComponent extends React.Component
class ReminderComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      remind: null
      conversationId: @getConversationId()
    @initComponent()

  dependsOnStores: [AuthStore]

  @propTypes:
    reminder: propTypes.object.isRequired
    selected: propTypes.bool

  timeToLabel:
    "#{TIME_REMAINDER_MINS.TIME_1}": '30m'
    "#{TIME_REMAINDER_MINS.TIME_2}": '1h'
    "#{TIME_REMAINDER_MINS.TIME_3}": '2h'
    "#{TIME_REMAINDER_MINS.TIME_4}": '4h'
    "#{TIME_REMAINDER_MINS.TIME_5}": '12h'

  componentDidMount: =>
    super()
    linksSelection = document.getElementsByClassName('thread_link');
    for i in [0...linksSelection.length]
      linksSelection[i].addEventListener('click', @openThreads)

  componentWillUnmount: =>
    linksSelection = document.getElementsByClassName('thread_link');
    for i in [0...linksSelection.length]
      linksSelection[i].removeEventListener('click', @openThreads)


  openThreads: (e) =>
    e.preventDefault()
    e.stopPropagation()

    { reminder } = @props
    entityKey = "conv-#{reminder.conversation_id}"
    orderId = e.target.getAttribute 'data-order'
    idMessage = e.target.getAttribute 'data-message'

    if orderId && idMessage && crmPluginEnabled
      config = pluginConfigs.OrderCompactPlugin(
        orderId: orderId,
        options:
          isResizable: true
          entityKey: entityKey
          openThreads: true
          idMessage: idMessage
      )
      CRMPluginActionCreator.setConfig(
        config, entityKey, "#{entityKey}-order-#{orderId}", true
      )

  getPersonalStoreClass: -> RemindersStore

  getConversationId: => @props.reminder?.conversation_id
  getReminderText: => @props.reminder?.text
  getReminderUserId: => @props.reminder?.user_id

  getBlankReminder: =>
    text: @getReminderText()
    time: @getInitialTime()
    conversationId: @getConversationId()
    userId: @getReminderUserId()
    conversationId: @getConversationId()

  getInitialTime: ->
    time = new Date()
    time.setSeconds(0)
    time

  onSetTime: (time) =>
    return null if !time
    @state.newReminder.time.setHours(time.getHours())
    @state.newReminder.time.setMinutes(time.getMinutes())

  messageForRanking: =>
    RankingActionCreator.selectRankedMessage @props.reminder.id, 'reminder'

  onComponentChange: (e) =>
    value = Number.parseInt e.target.value
    @setState remind: value
    data = @getBlankReminder();
    date = new Date().setMinutes(new Date().getMinutes() + value)
    data.user_id = AuthStore.user.id
    data.time = new Date(date).toISOString()
    @actionCreator.create(data)

  buildTextBlock: (text) ->
    {
      isRankSelectedMessage,
      rankedBy,
      isClickableForRank
    } = @props.rankingOptions

    timeOptions = TIME_REMAINDER_MINS._ALL.map (t) =>
      label: @timeToLabel[t] || t
      value: t
    timeValue = @state.remind

    React.createElement("div", { \
      "className": (classnames(styles.container,
        "#{styles.container_estimate}": isRankSelectedMessage
        "#{styles.selected}": @props.selected
      )),  \
      "onClick": (@messageForRanking if @props.rankingOptions.isClickableForRank)
    },
      React.createElement("div", {"className": (styles.select_time)},
        React.createElement("b", null, "Snooze for"),
        (TIME_REMAINDER_MINS._ALL.map (t) =>
          React.createElement("button", {"className": (styles.time_item), "key": (t), "value": (t), "onClick": (@onComponentChange)}, (@timeToLabel[t]))
        )
      ),
      (if isClickableForRank
        React.createElement("span", {"className": (styles['container__ranking-hover'])})
      ),
      (if rankedBy
        React.createElement("div", {"className": (styles.rank_blame)}, """
          Ranked by """, React.createElement("b", null, (rankedBy))
        )
      ),
      React.createElement("div", {"className": (styles.text)},
        (text)
      )
    )


  render: ->
    text = React.createElement("div", null,
      React.createElement("span", {"className": (styles.secondary_text)}, """
        @""", (@props.reminder.user?.last_name)
      ),
      React.createElement("div", {"className": (styles.secondary_text)},
        (moment(@props.reminder.time).format('lll'))
      ),
      React.createElement("span", { \
        "className": (styles.text),  \
        "dangerouslySetInnerHTML": ( __html: @props.reminder.text?.split('\n').join('</br>') )})
    )
    @buildTextBlock(text)

module.exports = ReminderComponent
