Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
DEFAULT_MODAL_TYPE = 'general'
DEFAULT_POPUP_TYPE = 'general'

module.exports =
  closeBurgerMenu: ->
    Dispatcher.dispatch
      type: ActionTypes.CLOSE_BURGER_MENU

  openModal: (component, data) ->
    if !data
      data = {}
    if !data.type
      data.type = DEFAULT_MODAL_TYPE
    setTimeout(
      ->
        Dispatcher.dispatch
          type: ActionTypes.INTERFACE_OPEN_MODAL
          payload: {
            component
            data
          }
      0
    )


  closeModal: (type = DEFAULT_MODAL_TYPE) ->
    setTimeout(
      ->
        Dispatcher.dispatch
          type: ActionTypes.INTERFACE_CLOSE_MODAL
          payload: {
            type
          }
      0
    )


  openLockedModal: (component, data) ->
    Dispatcher.dispatch
      type: ActionTypes.INTERFACE_OPEN_LOCKED_MODAL
      payload: {
        component
        data
      }

  closeLockedModal: -> Dispatcher.dispatch type: ActionTypes.INTERFACE_CLOSE_LOCKED_MODAL

  openPopup: (component, data) ->
    if !data
      data = {}
    if !data.type
      data.type = DEFAULT_POPUP_TYPE
    Dispatcher.dispatch
      type: ActionTypes.INTERFACE_OPEN_POPUP
      payload: {
        component
        data
      }

  closePopup: (type = DEFAULT_POPUP_TYPE) ->
    Dispatcher.dispatch
      type: ActionTypes.INTERFACE_CLOSE_POPUP
      payload: {
        type
      }
