moment = require 'moment'
classnames = require 'classnames'
deepcopy = require 'deepcopy'
emojione = require 'emojione'
browserHistory = require './../../../history'
{ NavLink } = require 'react-router-dom'

styles = require './conversation-list-item'
Isvg = require 'react-inlinesvg'
SearchUtils = require '../../../lib/SearchUtils'
BaseComponent = require '../../BaseComponent'
CONVERSATION_STATUS = require '../../../constants/ConversationStatuses'
AwaitingTimeComponent = require '../../shared/AwaitingTimeComponent/AwaitingTimeComponent'
constants = require '../../../constants/base'
{ DEFAULT_FLAG_COLOR, NEXT_FLAG_COLOR } = require 'constants/base'
{ buildParagraphWithEmoji } = require '../../../lib/ConversationItemUtils'
AllocateComponent = require '../../shared/AllocateComponent/AllocateComponent'
ConversationsActionCreator = require '../../../actions/ConversationsActionCreator'
FlagsActionCreator = require 'actions/FlagsActionCreator'

ConversationsActionCreator = new ConversationsActionCreator()
UNSELECTED_SVG = require './unselected.svg'

propTypes = require 'prop-types'

moment.defineLocale('en-conv-items', {
  parentLocale: 'en',
  calendar: {
    sameDay: 'HH:mm',
    nextDay:  'D MMM, HH:mm',
    nextWeek: 'D MMM, HH:mm',
    lastDay: '[Yest.] HH:mm',
    lastWeek: 'D MMM, HH:mm',
    sameElse: 'D MMM, HH:mm',
  }
})
moment.locale('en-conv-items')

sortByCreatedAt = (c) -> Date.parse(c?.created_at)

choosePreviewItem = (conversation) ->
  { message_preview, last_message, last_reminder, last_prompt, last_scheduled_message } = conversation
  return message_preview if message_preview?

  last_reminder = deepcopy last_reminder
  last_message = deepcopy last_message
  last_prompt = deepcopy last_prompt
  last_scheduled_message = deepcopy last_scheduled_message

  last_message.type = 'message' if last_message
  last_prompt.type = 'prompt' if last_prompt

  if last_reminder
    last_reminder.created_at = last_reminder.time
    last_reminder.type = 'reminder'

  if last_scheduled_message
    last_scheduled_message.created_at = last_scheduled_message.time
    last_scheduled_message.type = 'scheduled_message'

  previewsByCreatedAt = R.pipe(
    R.sortBy(sortByCreatedAt), R.reject(R.isNil), R.reverse
  )([last_message, last_reminder, last_prompt, last_scheduled_message])[0]
  return previewsByCreatedAt

class ConversationListItemComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    conversation: propTypes.object.isRequired
    multiselectEnabled: propTypes.bool
    multiselected: propTypes.bool
    folder: propTypes.string.isRequired
    reservedHighlighting: propTypes.bool
    fastAllocate: propTypes.bool

  @previewTypes:
    message: (previewItem, previewText) ->
      previewWithEmoji = previewText
      if typeof previewText == 'string'
        previewWithEmoji = buildParagraphWithEmoji(
          previewText, 1, styles.message
        )

      React.createElement("div", {"className": (styles.last_message)},
        (
          if !previewItem.inbound
            React.createElement("span", {"className": (styles.bluespan)}, "@")
        ),
        (previewWithEmoji)
      )
    prompt: (previewItem, previewText) ->
      React.createElement("div", {"className": (styles.last_message)},
        (previewText)
      )
    reminder: (previewItem, previewText) ->
      React.createElement("div", {"className": (styles.last_message)},
        React.createElement("div", {"className": (styles.reminder_icon)}),
        React.createElement("span", {"className": (styles.message)}, (previewText))
      )
    scheduled_message: (previewItem, previewText) ->
      React.createElement("div", {"className": (styles.last_message)},
        React.createElement("div", {"className": (styles.scheduled_message_icon)}),
        React.createElement("span", {"className": (styles.message)}, (previewText))
      )

  onClick: (e) =>
    e.preventDefault()
    e.stopPropagation()
    browserHistory.default.push(@link())

  currentFlagColor: =>
    { flags, conversation } = @props
    (flags[conversation.id] || {}).color || DEFAULT_FLAG_COLOR

  onFlagClick: (e) =>
    e.preventDefault()
    e.stopPropagation()
    color = @currentFlagColor()
    FlagsActionCreator.change(@props.conversation.id, NEXT_FLAG_COLOR[color])

  getStatusClassName: (status) ->
    switch status
      when CONVERSATION_STATUS.NEW_MESSAGE then styles.marker_status_new
      when CONVERSATION_STATUS.OPENED then styles.marker_status_opened
      when CONVERSATION_STATUS.CLOSED then styles.marker_status_closed
      when CONVERSATION_STATUS.DORMANT then styles.marker_status_dormant

  formatTags: (tags = [], className=styles.tag) =>
    tags.map (tag, index) ->
      if tag
        React.createElement("span", {"className": ( className ), "key": ( index )}, ( "##{tag.name}" ))

  formatConversationTags: (tags = [], className=styles.tag) =>
    tags.map (tag, index) ->
      if tag
        React.createElement("span", {"className": ( className ), "key": ( index )}, ( tag.name ))

  formatAgents: (agents = []) =>
    agents.map (agent, index) ->
      React.createElement("span", {"className": ( styles.agent ), "key": ( index )}, ( agent.last_name ))

  processText: (text) ->
    text = text || ""
    #processPictures
    newText = text
      .replace(constants.ANY_PICTURE_URL_REGEXP, "[Picture]")
    text = newText.replace("<br/>", '') if newText.length < text.length
    #truncate
    text = _str(text).truncate(140).value()
    text = emojione.toImage text
    #highlight
    SearchUtils.decodeElasticsearchHighlighting(text)

  assignConversationToAgent: (agentIds) =>
    conversationId = @props.conversation.id
    ConversationsActionCreator.assignToAgent([conversationId], agentIds)

  link: ->
    "/conversations/#{@props.folder}/#{@props.conversation.id}"

  renderPreview: (previewItem, previewText) ->
    return null unless previewItem
    renderFunction = ConversationListItemComponent.previewTypes[previewItem.type]
    return renderFunction(previewItem, previewText) if renderFunction
    null

  getGroupType: =>
    { conversation } = @props
    conversation.groups[0]['group_type'] if conversation.customer?.lead && conversation.groups?[0]

  render: ->
    flag_color = @currentFlagColor()

    groupTypeClassname = "group_background_#{@getGroupType()}" if @getGroupType()
    className = classnames(
      styles.container,
      styles[groupTypeClassname]
      {
        "#{styles.multiselected}": @props.multiselected
        "#{styles.container_reserved}": @props.reservedHighlighting && @props.conversation.reserve
      }
    )
    statusMarkerClassName = classnames(
      styles.marker, styles.status_marker,
      @getStatusClassName(@props.conversation.status)
    )

    { last_message, last_reminder, message_preview } = @props.conversation
    previewItem = choosePreviewItem(@props.conversation)
    previewText = @processText(previewItem?.text)
    canAllocate = @props.fastAllocate && @props.conversation.agents?.length == 0

    React.createElement(NavLink, {"to": (@link()), "className": (className), "activeClassName": (styles.selected)},
      React.createElement("div", null,
        React.createElement("div", {"className": (styles.marker_container)},
          React.createElement("div", {"className": (statusMarkerClassName)}),
          React.createElement("div", {"className": (classnames(styles.marker, styles.selection_marker))})
        ),
        React.createElement("div", {"className": (styles.content)},
          React.createElement("div", {"className": (styles.title_block)},
            React.createElement("div", {"className": (styles.credential_value)},
              (@props.conversation.customer?.name)
            ),
            (if @props.conversation.last_message?.channel?
              React.createElement("div", {"className": (styles.channel)}, """
                (""", (@props.conversation.last_message?.channel?.name), """)
""")
            ),
            React.createElement("div", {"className": (styles.right_info_container)},
              (
                last_item_time = last_message?.created_at || last_reminder?.time
                if last_item_time?
                  React.createElement("div", {"className": (styles.date)},
                    (moment(last_item_time).calendar())
                  )
              ),
              (
                if (@props.conversation.status == CONVERSATION_STATUS.NEW_MESSAGE) && @props.conversation.awaiting_from
                  React.createElement("div", {"className": (styles.awaiting_time), "title": 'Waiting time'},
                    React.createElement(AwaitingTimeComponent, { \
                      "awaitingFrom": (@props.conversation.awaiting_from)
                    })
                  )
              )
            ),
            React.createElement("div", {"className": "clr"})
          ),

          React.createElement("div", {"className": (styles.tags_client)},
            (@formatTags(@props.conversation?.customer?.tags, styles.tag_client))
          ),
          (@renderPreview(previewItem, previewText)),
          React.createElement("div", {"className": (styles.tags)},
            (@formatConversationTags(@props.conversation?.tags))
          ),
          React.createElement("div", {"className": (styles.agents_container)},
            React.createElement("div", { \
              "className": (styles.agents)
            },
              (@formatAgents(@props.conversation.agents))
            ),
            (if @props.conversation.status != CONVERSATION_STATUS.CLOSED
              React.createElement("div", {"onClick": (@onFlagClick.bind(@)), "className": (styles.flag)},
                React.createElement("span", {"className": (styles["flag_color_#{flag_color}"])},
                  React.createElement(Isvg.default, { \
                    "src": (UNSELECTED_SVG),  \
                    "preProcessor": ((code) -> code.replace(/fill=".*?"/g, 'fill="red"'))
                  })
                )
              )
            ),
            (
              if canAllocate
                React.createElement(AllocateComponent, { \
                  "allocateClass": (styles.allocate),  \
                  "reserveUserId": (@props.conversation.reserve),  \
                  "conversationId": (@props.conversation.id)
                })
            )
          )
        )
      )
    )

module.exports = ConversationListItemComponent
