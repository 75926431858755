BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.CUSTOMER_ORDERS_REQUESTING}": ({ data, customer }) ->
    @loading = true
    @orders = []
    @error = null
    @ordersPage = 1
    @ordersCount = 0
    @ordersLimit = 0
    @cntPage = 0
    @total = 0
    @currentCustomer = customer
    @emitChange()
    @currentPage = 1
  "#{ActionTypes.CUSTOMER_ORDERS_SUCCESS}": ({ data, customer }) ->
    return if customer.id != @currentCustomer.id
    @loading = false
    @orders = data?.results.map (order) =>
      order.id = order._id
      order
    @currentCustomer = customer.id
    @ordersLimit = data?.limit
    @ordersPage = data?.page || 1
    @ordersCount = data?.total || 0
    @total = data?.grand_total || 0
    @cntPage = data?.results.length || 0
    @emitChange()
  "#{ActionTypes.ORDERS_SET_PAGE}": (data) ->
    @currentPage = data.page
  "#{ActionTypes.CUSTOMER_ORDERS_SOCKET_SUCCESS}": (data) ->
    order = data.message.order_info
    isHaveOrder = @orders.find((o) -> o.id == order._id)
    if !!isHaveOrder then return
    
    @ordersCount = @ordersCount + 1
    @total = @total + 1


    if @currentPage == 1
      totalOrders = [Object.assign(order, { id: order._id })].concat @orders
      @orders = totalOrders.slice 0, @ordersLimit
  "#{ActionTypes.CUSTOMER_ORDERS_CLEAR}": () ->
    @loading = false
    @currentCustomer = null
    @orders = []
    @ordersLimit = 0
    @ordersPage = 0
    @ordersCount = 0
    @total = 0
    @cntPage = 0
    @emitChange()
  "#{ActionTypes.CUSTOMER_ORDERS_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class CustomerOrdersStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @ordersPage = 1
    @ordersCount = 0
    @ordersLimit = 0
    @cntPage = 0
    @total = 0
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new CustomerOrdersStore()
