{ Link } = require 'react-router-dom'

BaseComponent = require 'components/BaseComponent'
ChannelListComponent = require '../ChannelListComponent/ChannelListComponent'
dashboardStyles = require 'components/DashboardComponent/dashboard'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'
ChannelsStore = require 'stores/ChannelsStore'

styles = require './channels.styl'

class ChannelsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      limit: 30
      page: 0
      dataReady: false
    @initComponent()

  dependsOnStores: [ChannelsStore]

  componentDidMount: ->
    super()
    setTimeout(
      => ChannelsActionCreator.getList { page: @state.page, per_page: @state.limit }
      10
    )

  onPaginateClick: ({ selected }) =>
    page = selected
    ChannelsActionCreator.getList { page: page, per_page: @state.limit }
    @setState { page }

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.header)},
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement(Link, {"to": '/channels/new', "className": (dashboardStyles.button_add)},
            React.createElement("i", {"className": ("#{dashboardStyles.button_icon} fa fa-plus-circle")}),
            React.createElement("span", {"className": (dashboardStyles.button_label)}, " Add Channel ")
          )
        )
      ),

      React.createElement(ChannelListComponent, { \
        "error": (ChannelsStore.error),  \
        "loading": (ChannelsStore.loading),  \
        "channels": (ChannelsStore.channels),  \
        "pagesCount": (Math.ceil(ChannelsStore.total / @state.limit)),  \
        "paginateClickHandler": (@onPaginateClick)
      })
    )

module.exports = ChannelsComponent
