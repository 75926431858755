clipboard = require 'clipboard'
classnames = require 'classnames'

Button = require 'components/shared/Button'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
TabOptionsComponent = require '../TabOptionsComponent'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
InfoModal = require 'components/shared/InfoModal'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
styles = require './customer-payments'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
tagsConstants = require 'constants/Tags'
sitesConstants = require 'constants/Sites'

propTypes = require 'prop-types'

linksMap =
  referralLink: 'Referral link'
  OrderlistLink: 'Client profile link'
  OrderLink: 'Place new order link'
  CustomerSharingLink: 'File sharing link'
  CabinetLink: 'Referral cabinet'
  SubscriptionLink: 'Subscription details link'

class CustomerPaymentsComponent extends React.Component
  constructor: (props) ->
    super(props)
    state = {}
    @linkKeys = Object.keys(linksMap)
    @linkKeys.forEach (key) ->
      state[key] = 'Copy'
      new clipboard ".#{key}"
    @state = state
    @withContext = props.conversation?.status != CONVERSATION_STATUS.CLOSED

  componentWillReceiveProps: (nextProps) =>
    @withContext = nextProps.conversation?.status != CONVERSATION_STATUS.CLOSED

  buttonsToDefault: =>
    newState = {}
    @linkKeys.map (key) -> newState[key] = 'Copy'
    @setState newState

  onCopyClick: (e) =>
    { name } = e.currentTarget.dataset
    { buttonsToDefault } = @
    buttonsToDefault()
    clearTimeout @timeoutId
    @setState "#{name}": 'Copied'
    @timeoutId = setTimeout(
      buttonsToDefault
      2000
    )

  onAllPaymentsClick: =>
    config = [{
      plugins:
        ClientTransactionsPlugin:
          data: clientId: @props.externalClientId
    }]

    CRMPluginActionCreator.setConfig(
      config, @props.entityKey, "#{@props.entityKey}-allPayments", @withContext
    )

  showRefusingModal: (key) ->
    text = {
      'OrderLink': 'Client refused placing orders using account.',
      'OrderlistLink': 'Client refused placing orders using account.',
      'CustomerSharingLink': 'File sharing link.',
      'CabinetLink': 'Client refused placing orders using account.',
      'SubscriptionLink': 'Client refused placing orders using account.',
      'referralLink': 'Client has NOreferral tag'
    }[key]
    return () ->
      InterfaceActionCreator.openModal InfoModal, {
        text: text
      }

  isCopiedBlock: (key) =>
    (key == 'OrderLink' && @props.data?.site_acronym == sitesConstants.TEXT_NERDY_UK) ||
    (key == 'OrderlistLink' && @props.data?.site_acronym == sitesConstants.TEXT_NERDY_UK) ||
    (key == 'CabinetLink' && @props.data?.site_acronym == sitesConstants.TEXT_NERDY_UK) ||
    (key == 'SubscriptionLink' && @props.data?.site_acronym == sitesConstants.TEXT_NERDY_UK) ||
    (key == 'OrderLink' && @props.customer?.tags?.some( (tag) => tag.name.toLowerCase() == tagsConstants.CUSTOMER_TAGS_NO_ACCOUNT)) ||
    (key == 'referralLink' && @props.customer?.tags?.some( (tag) => tag.name.toLowerCase() == tagsConstants.CUSTOMER_TAGS_NO_REFERRAL))

  renderLinks: =>
    { isCopiedBlock, showRefusingModal, onCopyClick, state } = @
    { data } = @props

    @linkKeys.map (key) =>
      React.createElement("div", {"key": (key), "className": (styles.links_container)},
        React.createElement("div", null,
          React.createElement("span", {"className": (styles.link_label)}, (linksMap[key])),
          React.createElement("span", { \
            "ref": (key),  \
            "data-clipboard-target": ("##{key}"),  \
            "data-name": (key),  \
            "onClick": (if isCopiedBlock key then showRefusingModal(key) else onCopyClick),  \
            "className": (classnames(styles.link_button, key,
              "#{styles.link_button_active}": state[key] == 'Copied'
            ))
          },
            (state[key])
          )
        ),
        React.createElement("input", { \
          "id": (key),  \
          "className": (styles.link_input),  \
          "value": (data[key]),  \
          "readOnly": true
        })
      )

  render: ->
    data = null
    if @props.error
      data = React.createElement("p", {"className": (styles.error)}, (@props.error))
    else
      unless @props.data
        data = React.createElement("p", {"className": (styles.no_data)}, "No data")
      else
        data = React.createElement("div", {"className": (styles.container)},

          React.createElement("div", {"className": (styles.balance_container)},
            React.createElement("span", {"className": (styles.balance_label)}, "Balance:"),
            React.createElement("span", {"className": (styles.balance_value)},
              (@props.data.balance)
            )
          ),

          (@renderLinks())
        )

    React.createElement(LoadableWrapperComponent, {"loading": (@props.loading)},
      (if !@props.error
        React.createElement(TabOptionsComponent, { \
          "all": (
            text: 'All payments'
            onClick: @onAllPaymentsClick
          )
        })
      ),
      (data)
    )

CustomerPaymentsComponent.propTypes =
  data: propTypes.shape(
    referralLink: propTypes.string.isRequired
    pricingLink: propTypes.string.isRequired
    clientProfileLink: propTypes.string.isRequired
    placeNewOrderLink: propTypes.string.isRequired
  )
  loading: propTypes.bool
  error: propTypes.string

module.exports = CustomerPaymentsComponent
