{ Link } = require 'react-router-dom'
classnames = require 'classnames'

styles = require './customer-card.styl'
BaseComponent = require 'components/BaseComponent'
InlineTextInput = require 'components/shared/InlineTextInput/InlineTextInput'
InlineTimezoneInput = require 'components/shared/InlineTimezoneInput/InlineTimezoneInput'
CustomersActionCreator = require 'actions/CustomersActionCreator'
CRMInfoModuleActionCreator = require 'actions/CRMInfoModuleActionCreator'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerCardCredentialsFormByCustomerComponent = require './CustomerCardCredentialsFormComponent/CustomerCardCredentialsFormByCustomerComponent'
crminfo = require '@verdaccio/crminfo'
emptyAvatarImage = require("components/shared/images/unknown.png")
BlacklistedWarningModal = require 'components/shared/BlacklistedWarningModal'
SwitchComponent = require 'components/shared/SwitchComponent'
AuthStore = require 'stores/AuthStore'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
{ BLACKLIST_EDIT_ACCESS } = require 'constants/Agents'

propTypes = require 'prop-types'

getAvatarSrc = (customer) -> emptyAvatarImage

class CustomerCardComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    customer: propTypes.object.isRequired
    onReadyChange: propTypes.func

  dependsOnStores: [CRMInfoModuleStore]

  getState: ->
    customerFeedbacks: CRMInfoModuleStore.payments?.profile_client_feedbacks
    clientId: CRMInfoModuleStore.crmInfo?.clientId
    sameCustomersLoading: false

  # todo move to props
  updateCustomerAttribute: (attribute, value) =>
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, {"#{attribute}": value})
      10
    )

  toggleBlacklist: (value) ->
    data = optin: value
    data.optout = true if value
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, data)
      10
    )

  onOptoutToggle: (value) =>
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, { optout: value })
      10
    )

  onAppblockToggle: (value) =>
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, { appblock: value })
      10
    )

  onBlacklistToggle: (value) =>
    @setState sameCustomersLoading: true
    CRMInfoModuleActionCreator.getSameCustomers(
      @state.clientId
      (sameCustomers) =>
        if sameCustomers.length > 0
          @setState sameCustomersLoading: false
          InterfaceActionCreator.openModal BlacklistedWarningModal, {
            onProceed: => @toggleBlacklist value
            sameCustomers: sameCustomers
          }
        else
          @setState sameCustomersLoading: false
          @toggleBlacklist value
      (error) =>
        @setState sameCustomersLoading: false
        console.error(error)
    )

  render: ->
    { customer } = @props
    { avg_mark, count } = @state.customerFeedbacks || {}
    { Tooltip, StarRating } = crminfo.controls
    React.createElement("div", {"className": ( styles.container )},
      React.createElement("div", {"className": styles.primary_info},
        React.createElement(Link, {"to": "/customers/#{customer.id}", "className": styles.link},
          React.createElement("img", {"className": (styles.image), "src": (getAvatarSrc(customer))})
        ),

        React.createElement("div", {"className": styles.info},
          React.createElement(InlineTextInput, { \
            "isTitle": true,  \
            "value": customer.name,  \
            "onSubmit": ( R.partial(@updateCustomerAttribute, ['name']) ),  \
            "placeholder": ( 'Client\'s name' ),  \
            "className": (styles.name)
          }),
          React.createElement(InlineTimezoneInput, { \
            "className": styles.time,  \
            "value": customer.timezone,  \
            "auto_timezone": customer.auto_timezone,  \
            "onSubmit": ( R.partial(@updateCustomerAttribute, ['timezone']) )
          })
        )
      ),
      (if @props.customerLink
        React.createElement("div", {"className": styles.optout},
          React.createElement(Link, { \
            "to": "/conversations/all_conversations/#{@props.customerLink}"}, """
            Conversation
""")
        )
      ),
      React.createElement("div", {"className": (styles.credentials)},
        React.createElement(CustomerCardCredentialsFormByCustomerComponent, { \
          "customer": customer,  \
          "onReadyChange": @props.onReadyChange
        })
      ),
      React.createElement("div", {"className": (styles.rating)},
        React.createElement("span", {"className": (styles.rating_label)}, "Average order rating"),
        React.createElement(Tooltip, {"content": ("Average rating based on #{count || 0} ratings"), "positionRight": true},
          React.createElement(StarRating, {"rate": (avg_mark || 0), "width": (90)})
        )
      ),
      React.createElement("div", {"className": 'clr'}),
      React.createElement("div", {"className": (styles.optout)},
        React.createElement(SwitchComponent, { \
          "value": (!!customer.optout),  \
          "onToggle": (@onOptoutToggle),  \
          "auto": (!!customer.options.auto_optout),  \
          "disabled": (!@state.clientId || customer.options.auto_optout)
        }),
        React.createElement("span", {"className": (styles.optout__text)}, "Optout")
      ),
      React.createElement("div", {"className": (styles.optout)},
        React.createElement(SwitchComponent, { \
          "value": (!!customer.optin),  \
          "onToggle": (@onBlacklistToggle),  \
          "className": (classnames({ "#{styles.loading_cursor}": @state.sameCustomersLoading })),  \
          "disabled": (!BLACKLIST_EDIT_ACCESS[AuthStore.user.role] || @state.sameCustomersLoading || !@state.clientId)
        }),
        React.createElement("span", {"className": (styles.optout__text)}, "Blacklist")
      ),
      React.createElement("div", {"className": (styles.optout)},
        React.createElement(SwitchComponent, { \
          "value": (!!customer.appblock),  \
          "onToggle": (@onAppblockToggle),  \
          "disabled": (!@state.clientId)
        }),
        React.createElement("span", {"className": (styles.optout__text)}, "Appblock")
      )
    )

module.exports = CustomerCardComponent
