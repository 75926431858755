BaseComponent = require 'components/BaseComponent'
ConversationItemsListView = require '../ConversationItemsListView'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ConversationItemsActionCreator = require 'actions/ConversationItemsActionCreator'
styles = require './conversation-items-list-types'

class ConversationItemsListNonSyncComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @state =
      itemsAmount: 0
      ready: true
    @itemsCount
    @isScrolled = false
    @initComponent()

  componentDidMount: ->
    super()
    unless @props.items
      conversationId = @props.conversation.id
      setTimeout(
        ->
          ConversationItemsActionCreator.getList(
            conversationId
            {
              page: 0,
              perPage: 300
            }
          )
        10
      )

  componentDidUpdate: ->
    super()
    conversationItems = @props.items

    @props.onReadyChange?(true) if conversationItems
    onlyShowed = R.filter (c) =>
      !(c.service_name == 'Facebook' && (c.status in [0, 1, 10]))

    if conversationItems
      conversationItemsAmount = onlyShowed(conversationItems)?.length || 0
    else
      conversationItemsAmount = 0

    @props.onNewItem?()

    if @state.itemsAmount != conversationItemsAmount
      @state.itemsAmount = conversationItemsAmount

    if !@props.loading && @props.scrollTop && !@isScrolled
      @isScrolled = true
      @props.scrollTop() 

  getItems: ->
    conversationItems = @props.items || []
    conversationItems.map (item) =>
      highlightedItem = @props.itemsHighLightingIndex[item.id]
      highlightedItem = @props.itemsHighLightingIndex[item.type + item.id] unless highlightedItem
      if highlightedItem? && highlightedItem.conversation_id == @props.conversation.id
        R.merge(item, R.pick(['text'], highlightedItem))
      else
        item

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement(LoadableWrapperComponent, {"loading": (@props.loading|| false)},
        React.createElement(ConversationItemsListView, { \
          "conversation": (@props.conversation),  \
          "items": (@getItems()),  \
          "selectedItem": (@props.selectedItem),  \
          "onItemSelect": (@props.onItemSelect),  \
          "onUpdateConversation": (@props.onUpdateConversation)
        })
      )
    )

module.exports = ConversationItemsListNonSyncComponent
