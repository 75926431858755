Dispatcher = require '../dispatcher/Dispatcher'
{getCRMInfo, checkSameCustomers, getCustomersIds,getCustomerOrders} = require '../api/CustomersAPI'
ActionTypes = require '../constants/ActionTypes'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'

module.exports =
  get: (customer, url, options = {}) ->
    if (customer.id != (CRMInfoModuleStore.currentCustomer || {}).id) || options.forceUpdate
      errMsg = 'An error occurred while getting CRM data'
      Dispatcher.dispatch
        type: ActionTypes.CUSTOMER_CRM_REQUESTING
        payload:
          customer: customer
      getCRMInfo(
        url
        {}
        (json) ->
          if json.success
            Dispatcher.dispatch
              type: ActionTypes.CUSTOMER_CRM_SUCCESS
              payload:
                data: json.data
                customer: customer
          else
            Dispatcher.dispatch
              type: ActionTypes.CUSTOMER_CRM_FAILURE
              payload: json.error || errMsg
        (error) ->
          Dispatcher.dispatch
            type: ActionTypes.CUSTOMER_CRM_FAILURE
            payload: errMsg
      )

  clearOrders: () -> 
    Dispatcher.dispatch
    type: ActionTypes.CUSTOMER_ORDERS_CLEAR

  getOrders: (customer, url, options = {}) ->
    if (customer.id != (CustomerOrdersStore.currentCustomer || {}).id) || options.forceUpdate
      errMsg = 'An error occurred while getting customer orders'
      Dispatcher.dispatch
        type: ActionTypes.CUSTOMER_ORDERS_REQUESTING
        payload:
          customer: customer
      getCustomerOrders(
        url
        {}
        (json) ->
          if json
            Dispatcher.dispatch
              type: ActionTypes.CUSTOMER_ORDERS_SUCCESS
              payload:
                data: json
                customer: customer
          else
            Dispatcher.dispatch
              type: ActionTypes.CUSTOMER_ORDERS_FAILURE
              payload: json.error || errMsg
        (error) ->
          Dispatcher.dispatch
            type: ActionTypes.CUSTOMER_ORDERS_FAILURE
            payload: errMsg
      )

  getWithTags: (data, url, search) ->
    errMsg = 'An error occurred while getting CRM data'
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_REQUESTING
    getCustomerOrders(
      url
      {}
      (json) ->
        if json
          Dispatcher.dispatch
            type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_SUCCESS
            payload: json
        else
          Dispatcher.dispatch
            type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
            payload: json.error || errMsg
      (error) ->
        Dispatcher.dispatch
          type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
          payload: errMsg
    )
#   getWithTags: (url, data) ->
#     errMsg = 'An error occurred while getting CRM data'
#     Dispatcher.dispatch type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_REQUESTING
#     getCRMInfo(
#       url
#       data
#       (json) ->
#         if json.success
#           Dispatcher.dispatch
#             type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_SUCCESS
#             payload: json.data
#         else
#           Dispatcher.dispatch
#             type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
#             payload: json.error || errMsg
#       (error) ->
#         Dispatcher.dispatch
#           type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
#           payload: errMsg
#     )
  
  getSameCustomers: (clientId, success, fail) ->
    errMsg = 'An error occurred while getting CRM data'
    checkSameCustomers(
      clientId
      (json) ->
        if json.results
          success(json.results) if success
        else
          fail(errMsg) if fail
      (error) ->
        fail(error) if fail
    )
  getCustomersIdsFromClientsIds: (clientIds, success, fail) -> # get SMSTool customers ids from crm clients ids
    errMsg = 'An error occurred while getting customers ids'
    getCustomersIds(
      clientIds
      (json) ->
        if json
          success(json) if success
        else
          fail(errMsg) if fail
      (error) ->
        fail(error) if fail
    )
