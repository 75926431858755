BaseStore       = require './BaseStore'
ActionTypes     = require '../constants/ActionTypes'
{formatNumberToPercent} = require '../lib/utils'

actionsMap =
  "#{ActionTypes.QA_GET_STATISTIC_REQUESTING}": ->
    @error = ''
    @qa_teamleads = []
    @loading = true
    @emitChange()
  "#{ActionTypes.QA_GET_STATISTIC_SUCCESS}": ({qa_teamleads, data, total}) ->
    @statistic = data
    @qa_teamleads = qa_teamleads
    @total = [
      'Total'
      null
      null
      null
      total.ranked
      formatNumberToPercent total.ranked_percent
      total.to_rank
      total.striked
      formatNumberToPercent total.striked_percent
    ]
    @agentTotal = [
      'Total by Agents'
      null
      null
      null
      total.agent_ranked
      null
      null
      total.agent_striked
      formatNumberToPercent total.agent_striked_percent
    ]
    @newbieAgentTotal = [
      'Total by Newbie Agents'
      null
      null
      null
      total.newbie_agent_ranked
      null
      null
      total.newbie_agent_striked
      formatNumberToPercent total.newbie_agent_striked_percent
    ]
    @error = ''
    @loading = false
    @emitChange()
  "#{ActionTypes.QA_GET_STATISTIC_FAIL}": (error) ->
    @error = error
    @loading = false
    @emitChange()

  "#{ActionTypes.QA_CHANGE_ORDER}": (statistic) ->
    @statistic = statistic
    @emitChange()

  "#{ActionTypes.QA_UPDATE_TEAMLEAD_SUCCESS}": ({agent, id, name}) ->
    agent.teamlead =
      id: id
      name: name
    @emitChange()

class QADashboardStore extends BaseStore
  constructor: ->
    super()
    @qa_teamleads = []
    @statistic = []
    @total = []
    @loading = false
    @error = ''
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new QADashboardStore()
