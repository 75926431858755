onClickOutside = require('react-onclickoutside')
Isvg = require 'react-inlinesvg'

BaseComponent = require 'components/BaseComponent'
styles = require './reply-template-selector'
ReplyTemplatesManager = require 'components/replyTemplates/ReplyTemplatesManager'

repliesIcon = require '!file-loader!../../../assets/images/icons/replies.svg'

propTypes = require 'prop-types'

# Smart
class ReplyTemplateSelectorComponent extends BaseComponent
  @propTypes:
    onPick: propTypes.func

  constructor: (props) ->
    super(props)
    @state =
      opened: false
      isOpenTemplateList: @props.isOpenTemplateList
      iconClick: false
    @initComponent()

  componentDidUpdate: (prevProps) ->
    if !prevProps.isOpenTemplateList && @props.isOpenTemplateList
      @open()
    if prevProps.isOpenTemplateList && !@props.isOpenTemplateList
      @close()

  onOpenButtonClick: (e) =>
    if @state.opened then @close() else @open()
    @setState iconClick: true
    

  open: => @setState opened: true

  close: => @setState(opened: false) if @state.opened

  onReplyTemplatePick: (replyTemplate) =>
    @setState opened: false
    @props.onPick?(replyTemplate)
    @setState iconClick: false

  handleClickOutside: (e) =>
    return if e.target.dataset.event == 'saveOpen'
    @close() if @state.opened
    @setState iconClick: false

  render: ->
    React.createElement("div", {"className": (styles.root), "ref": "root"},
      React.createElement("div", {"className": (styles.open_button), "onClick": (@onOpenButtonClick)},
        React.createElement(Isvg.default, {"src": (repliesIcon)})
      ),
      (if @state.opened
        React.createElement(ReplyTemplatesManager, { \
          "onReplyTemplatePick": (@onReplyTemplatePick),  \
          "searchQuery": (@props.searchQuery),  \
          "templateType": (@props.templateType),  \
          "isOpenTemplateList": (if @state.iconClick then false else @props.isOpenTemplateList)
        })
      )
    )

module.exports = onClickOutside.default(ReplyTemplateSelectorComponent)
