uuid = require 'uuid'
BaseFormComponent = require 'components/shared/BaseFormComponent/BaseFormComponent'
LandingStore = require 'stores/LandingStore'
LandingsActionCreator = require 'actions/LandingsActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
SaveButtonComponent = require 'components/shared/SaveButtonComponent/SaveButtonComponent'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'
ChannelsStore = require 'stores/ChannelsStore'

styles = require 'components/shared/BaseFormComponent/base-form'

#TODO: need to refactoring main/second channels logic
class LandingsFormComponent extends BaseFormComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [LandingStore, ChannelsStore]

  attributes: ['id', 'name', 'landing_guid', 'channels', 'disabled']

  defaults: ->
    name: ''
    landing_guid: ''
    mainChannel: {
      id: ''
      type: 'main'
      status: 'active'
    }
    secondChannel: {
      id: ''
      type: 'second'
      status: 'active'
    }
    channels: []
    disable: false

  getState: ->
    attrs = _.extend({}, @defaults(), LandingStore.landing)
    mainChannel = attrs.channels.find (channel) ->
      channel.type == 'main'
    secondChannel = attrs.channels.find (channel) ->
      channel.type == 'second'
    attrs.mainChannel = mainChannel if mainChannel
    attrs.secondChannel = secondChannel if secondChannel
    attrs.landing_guid ||= uuid.v1()
    attributes: attrs

  getDataKey: =>
    @props.match.params.id

  requestData: =>
    _.defer ChannelsActionCreator.getList, page: 0, per_page: 100, channel_type: 'phone'
    id = @props.match.params.id
    if id
      _.defer LandingsActionCreator.get, id
    else
      _.defer LandingsActionCreator.new

  handlerValues: (e, attr) =>
    attributes = @state.attributes
    attributes[attr] = e.target.value

    @setState attributes: attributes

  getterValues: (attr) =>
    @state.attributes[attr]

  onSubmitForm: (e) =>
    e.preventDefault()
    attributes = @state.attributes
    channels = []
    channels.push(attributes.mainChannel) if attributes.mainChannel.id
    channels.push(attributes.secondChannel) if attributes.secondChannel.id
    attributes.channels = channels
    delete attributes.mainChannel
    delete attributes.secondChannel
    if @state.attributes.id
      LandingsActionCreator.update(attributes)
    else
      LandingsActionCreator.create(attributes)

  selectHandler: (field) =>
    (e) =>
      attributes = @state.attributes
      attributes[field].id = e.target.value
      @setState attributes: attributes

  selectValue: (attr) =>
    =>
      @state.attributes[attr].id

  disabledHandler: (attr) =>
    (e) =>
      attributes = @state.attributes
      attributes[attr].status = if e.target.checked then 'disabled' else 'active'
      @setState attributes: attributes

  disabledValue: (attr) =>
    =>
      @state.attributes[attr].status != 'active'

  onSelectChange: (handler, e) =>
    handler(e)

  render: ->
    { channels } = ChannelsStore
    selectOptions = channels.map (channel) ->
      { value: channel.id, label: channel.name }
    { loading } = LandingStore
    { attributes } = @state
    React.createElement("div", {"className": ( styles.container )},
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        React.createElement("form", {"onSubmit": ( @onSubmitForm )},
          ( @renderInput('name', 'Name',
              handler: _(@handlerValues).partial(_, 'name')
              value: _(@getterValues).partial('name')
              key: 'name'
          ) ),
          ( @renderInput('landing_guid', 'Landing',
              handler: _(@handlerValues).partial(_, 'landing_guid')
              value: _(@getterValues).partial('landing_guid')
              key: 'landing_guid'
          ) ),
          ( @renderSelect('mainChannel', 'Main Channel', selectOptions,
              handler: @selectHandler('mainChannel')
              value: @selectValue('mainChannel')
              withBlank: true
          ) ),
          ( @renderCheckbox('mainChannelDisabled', 'Main Channel Disabled'
            handler: @disabledHandler('mainChannel')
            value: @disabledValue('mainChannel')
          ) ),
          ( @renderSelect('secondChannel', 'Second Channel', selectOptions,
              handler: @selectHandler('secondChannel')
              value: @selectValue('secondChannel')
              withBlank: true
          ) ),
          ( @renderCheckbox('secondChannelDisabled', 'Second Channel Disabled'
            handler: @disabledHandler('secondChannel')
            value: @disabledValue('secondChannel')
          ) ),
          ( @renderCheckbox('disable', 'Landing Disabled') ),
          React.createElement("div", {"className": ( styles.form_group )},
            React.createElement(SaveButtonComponent, { \
              "type": ('normal'),  \
              "process": (LandingStore.loading)
            })
          )
        )
      )
    )

module.exports = LandingsFormComponent
