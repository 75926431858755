config = require 'root/config'
Scroll = require 'components/shared/Scroll/Scroll'
BaseComponent = require 'components/BaseComponent'
MenuItemComponent = require '../MenuItemComponent/MenuItemComponent'
ExtraInfoComponent = require '../ExtraInfoComponent/ExtraInfoComponent'
AuthStore = require 'stores/AuthStore'
CountersStore = require 'stores/CountersStore'
CountersActionCreator = require 'actions/CountersActionCreator'
{ ROLES } = require 'constants/Agents'
styles = require './menu'

badgeMap =
  unassigned: 'unassignedWaitingConversationsCount'
  my_conversations: 'myWaitingConversationsCount'

class MenuComponent extends BaseComponent

  constructor: ->
    super()
    @titleElement = document.getElementsByTagName('title')[0]
    @titleText = @titleElement.innerHTML
    @initComponent()

  dependsOnStores: [CountersStore]

  getState: ->
    R.merge(
      super(),
      myWaitingConversationsCount: CountersStore.counters.myWaitingConversationsCount,
      unassignedWaitingConversationsCount: CountersStore.counters.unassignedWaitingConversationsCount
    )

  createNotification: =>
    role = (@props.user || {}).role 
    if role == ROLES.teamlead.value
      if @state.unassignedWaitingConversationsCount
        @titleElement.innerHTML = "#{@state.unassignedWaitingConversationsCount} unassigned"
      else
        @titleElement.innerHTML = @titleText
    else if role == ROLES.newbie_agent.value || role == ROLES.agent.value || role == ROLES.skilled_agent.value || role == ROLES.sales.value
      if @state.myWaitingConversationsCount
        @titleElement.innerHTML = "#{@state.myWaitingConversationsCount} my conversations"
      else
        @titleElement.innerHTML = @titleText

  componentDidMount: ->
    super()
    setTimeout(
      ->
        CountersActionCreator.subscribe(AuthStore.user.id)
      0
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ->
        CountersActionCreator.unSubscribe(AuthStore.user.id)
      0
    )
    @titleElement.innerHTML = @titleText

  render: ->
    @createNotification()
    React.createElement("div", {"className": "#{styles.container} #{styles.container_opened}"},
      React.createElement(Scroll, {"style": ( top: '0px', bottom: '92px', paddingTop: '10px' )},
        React.createElement("nav", {"className": "navigation"},
          (config.menus.map (menu) =>
            userRole = ROLES[@props.user.role].value
            firstCondition =
              !config.reconnectUrl &&
              menu.accessToPage &&
              !(userRole in menu.accessToPage)
            secondCondition =
              config.reconnectUrl &&
              !menu.reconnectAccess
            thirdCondition =
              config.reconnectUrl &&
              menu.reconnectAccess &&
              !(userRole in menu.reconnectAccess)
            return null if firstCondition || secondCondition || thirdCondition
            return null if menu.messagesRanker && !@props.user.messages_ranker
            return null if menu.systemEditor && !@props.user.system_editor
            if @props.user.is_ip_block
              return null if !menu.ipBlockAccess
              return null if !(userRole in menu.ipBlockAccess)
            React.createElement(MenuItemComponent, { \
              "menu": (menu),  \
              "key": (menu.key),  \
              "badge": (@state[badgeMap[menu.key]])
            })
          )
        )
      ),
      React.createElement(ExtraInfoComponent, null)
    )

module.exports = MenuComponent
