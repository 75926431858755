classnames = require 'classnames'
Isvg = require 'react-inlinesvg'

styles = require './info-block'

propTypes = require 'prop-types'

class InfoBlockComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      isOpen: props.openByDefault

  onTitleClick: =>
    @props.onToggleBlock(@props.dataKey)
    @setState isOpen: !@state.isOpen

  createNewWindow: (e) =>
    e.stopPropagation()
    @props.onNewWindow(@props.dataKey)

  # React16 CHECK FIX THIS @props.icon
  render: ->
    React.createElement("div", {"className": (classnames(styles.container, "#{@props.title.replace(/\s+/g, '_').toLowerCase()}_crm_info_module"))},
      React.createElement("div", { \
        "className": (classnames(styles.title_container,
          "#{styles.title_container_active}": @state.isOpen
        )),  \
        "onClick": (@onTitleClick)
      },
        React.createElement("div", {"className": (styles.title_group)},
          React.createElement("span", {"className": (styles.title_icon)},
            (if @props.icon
              React.createElement(Isvg.default, {"src": (@props.icon)})
            )
          ),
          React.createElement("span", {"className": (styles.text)}, (@props.title)),
          (if @props.additional
            React.createElement("span", { \
              "className": (classnames(styles.text,
                styles.text_additional,
                "#{styles[@props.additional_style]}": @props.additional_style
              ))
            }, """
              (""", (@props.additional), """)
""")
          )
        )
      ),
      (if @state.isOpen
        React.createElement("div", {"className": (styles.children_container)},
          (@props.children)
        )
      )
    )

InfoBlockComponent.propTypes =
  title: propTypes.string.isRequired
  dataKey: propTypes.string.isRequired
  onToggleBlock: propTypes.func.isRequired
  openByDefault: propTypes.bool
  additional: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ])
  icon: propTypes.string

module.exports = InfoBlockComponent
