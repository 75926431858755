config               = require '../config'
{ underscoreParams } = require '../lib/utils'

ConversationsAPI =
  getList: (scope, success, error) ->
    $.ajax {
      url: config.urls.conversations.getList
      type: 'GET'
      data: underscoreParams(scope)
      success
      error
    }

  inboundUrls: (id, success, error) ->
    $.ajax {
      url: config.urls.conversations.inboundUrls(id)
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  urls: (id, success, error) ->
    $.ajax {
      url: config.urls.conversations.urls(id)
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  downloadFile: (url, name, success, error) ->
     $.ajax {
      url: config.urls.fileServer.file(url, name),
      type: 'GET',
      contentType: 'application/json'
      success,
      error
    }

  downloadAll: (data, success, error) ->
    data = {
      urls: data.urls,
      file_name: data.title
    }

    $.ajax {
      url: config.urls.fileServer.downloadAll,
      type: 'POST',
      contentType: 'application/json'
      data: JSON.stringify data
      success,
      error
    }

  flag: (data, success, error) ->
    $.ajax {
      url: config.urls.conversations.flag
      contentType: 'application/json'
      type: 'POST'
      data: JSON.stringify data
      success
      error
    }

  get: (id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.get(id)
      type: 'GET'
      success: successCallback
      error: errorCallback
      timeout: 10000

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.create
      contentType: 'application/json'
      data: JSON.stringify attributes
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: (id, updateAttributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.update(id)
      contentType: 'application/json'
      data: JSON.stringify updateAttributes
      type: 'PUT'
      success: successCallback
      error: errorCallback

  batchDestroy: ({ ids, menu }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.batch_destroy
      contentType: 'application/json'
      data: JSON.stringify({ ids: ids })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

  assignMostWaiting: (agent_id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.assignMostWaiting
      data: JSON.stringify { agent_id }
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  assignToAgent: ({ conversation_ids, agent_ids }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.assign
      data: JSON.stringify { conversation_ids, agent_ids }
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  unassignAgent: ({ conversation_id, agent_id }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.conversations.unassign
      data: JSON.stringify { conversation_id, agent_id }
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  getClosedConversations: (conversationId, success, error) ->
    $.ajax {
      url: config.urls.conversations.closed conversationId
      contentType: 'application/json'
      success
      error
      timeout: 5000
    }

  getClosedConversationsForPreview: (conversationId, data, success, error) ->
    $.ajax {
      url: config.urls.conversations.rangeClosed conversationId
      contentType: 'application/json'
      data
      success
      error
      timeout: 5000
    }

  rejectReservation: (data, success, error) ->
    $.ajax {
      url: config.urls.conversations.rejectReservation
      data
      type: 'POST'
      contentType: 'application/json'
      success
      error
      timeout: 5000
    }

  deferDormantStatus: (conversationId, success, error) ->
    $.ajax {
      url: config.urls.conversations.deferDormantStatus conversationId
      type: 'POST'
      contentType: 'application/json'
      success
      error
      timeout: 5000
    }

module.exports = ConversationsAPI
