moment = require 'moment'
{ Link } = require 'react-router-dom'

AbstractStatisticComponent = require '../AbstractStatisticComponent'
{ formatFromSecondsToTime,
 formatNumberToPercent,
 formatNumberToMoney} = require 'lib/utils'
{ SHIFT_ROLES, BONUS_STATES } = require 'constants/Agents'
styles = require 'components/shared/BaseAgentStatisticComponent/base-agent-statistic'
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
AuthStore = require 'stores/AuthStore'
AgentStatisticActionCreator = require 'actions/AgentStatisticActionCreator'

rolesWithoutDuplications = {}
Object.keys SHIFT_ROLES
  .forEach (key) ->
    unless (rolesWithoutDuplications[SHIFT_ROLES[key].value] || {}).active
      rolesWithoutDuplications[SHIFT_ROLES[key].value] = SHIFT_ROLES[key]

class ShiftsStatisticComponent extends AbstractStatisticComponent

  constructor: (props) ->
    super(props)
    that = @
    { role } = AuthStore.user
    @tableFields =
      date:
        label: 'Shift Date'
        presenter: (value, shift) =>
          React.createElement(Link, { \
            "className": (styles.link),  \
            "to": ("/ranking?end_date=#{moment(shift.date).format 'YYYY-MM-DD'}&range_type=daily&agent_id=#{shift.user_id}")
          },
            (moment(value, "YYYY-MM-DD").format 'DD MMM YYYY')
          )
        handlers: {}
        isSortable: true
      shift_type:
        label: 'Shift Type'
        presenter: (value, shift) =>
          React.createElement(Link, { \
            "className": (styles.link),  \
            "to": ("/ranking?end_date=#{moment(shift.date, "YYYY-MM-DD").format 'YYYY-MM-DD'}&range_type=daily&shift_type=#{value?.toLowerCase()}&agent_id=#{shift.user_id}")
          },
            (value)
          )
        handlers: {}
        isSortable: true
      created_at:
        label: 'Login Time'
        presenter: (value) -> moment(value, "YYYY-MM-DDTHH:mm").format 'HH:mm'
        handlers: {}
        isSortable: true
      updated_at:
        label: 'Logout Time'
        presenter: (value) -> moment(value, "YYYY-MM-DDTHH:mm").format 'HH:mm'
        handlers: {}
        isSortable: true
      unique_clients_count:
        label: '# Unique Clients'
        presenter: (value) -> value
        handlers: {}
        isSortable: true
      average_response_time:
        label: 'Average RT'
        presenter: (value) -> formatFromSecondsToTime value
        handlers: {}
        isSortable: true
      good_rt:
        label: 'Good RT %'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
        isSortable: true
      bonus:
        label: 'Bonus'
        presenter: (value) -> formatNumberToMoney value
        handlers: {}
        isSortable: false
    if props.agent.role != 'sales' then @tableFields.bonus_state =
      label: 'Bonus State'
      presenter: (value, shift) =>
        indexOfRole = @accessConfig.accessToEditShiftData
          .indexOf((SHIFT_ROLES[role] || {}).value)
        if indexOfRole != -1
          React.createElement(CustomSelectComponent, { \
            "key": (shift.id),  \
            "defaultValue": ((BONUS_STATES[value] || {}).value),  \
            "options": (BONUS_STATES),  \
            "onOptionSelect": (that.onChangeHandler.bind(
              that
              shift
              'bonus_state'
            )),  \
            "customSelectStyle": (styles.select),  \
            "customOptionsStyle": (styles.select__options)
          })
        else
          BONUS_STATES[value]?.label
      addBodyCss: (value) ->
        styles["color-#{value}"]
      handlers: {}
      isSortable: true
    @tableFields.shift_role =
      label: 'Shift Role'
      presenter: (value, shift) =>
        indexOfRole = @accessConfig.accessToEditShiftData
          .indexOf((SHIFT_ROLES[role] || {}).value)
        if indexOfRole != -1
          React.createElement(CustomSelectComponent, { \
            "key": (shift.id),  \
            "defaultValue": ((SHIFT_ROLES[value] || {}).value),  \
            "options": (rolesWithoutDuplications),  \
            "onOptionSelect": (that.onChangeHandler.bind(
              that
              shift
              'shift_role'
            )),  \
            "customSelectStyle": (styles.select),  \
            "customOptionsStyle": (styles.select__options)
          })
        else
          SHIFT_ROLES[value]?.label
      handlers: {}
      isSortable: true

    @type = 'shifts'
    @initComponent()

  onChangeHandler: (shift, field, value) ->
    unless shift[field] == value
      AgentStatisticActionCreator.changeShiftStatistic(
        shift.user_id, shift.id, field, value, field
      )

module.exports = ShiftsStatisticComponent
